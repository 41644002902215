/* eslint-disable */
import { Const } from '../lib/const.cls'
// import * as types from '@/types'
// import * as RxFactory from '@/store/api/rx-factory'
import * as Enpoints from '@/api/endpoints'
import * as COMMON from '@/store/commomFunction'
// import moment from 'moment'
/*eslint no-unused-vars: "error"*/
export const actions = {
    [Const.RUN_CONTACT_US]({ commit, state, getters, dispatch }, credentials) {
        return new Promise((resolve,reject) =>{
            COMMON._executeApi(Enpoints.EP_CONTACT_US, credentials, { 'Authorization': 'Bearer ' + getters.GET_TOKEN }, commit, (error, result) => {
                if (!error) {
                    credentials.localParams.parameter.errorMsg = 'Thank you for contacting us, we will get back to you soon.'
                    credentials.localParams.parameter.msgType = 'success'
                    resolve(true)
                } else {
                    credentials.localParams.parameter.errorMsg = result.res_data
                    credentials.localParams.parameter.msgType = 'error'
                    //COMMON._messageDisplayFunction(credentials.localParams.parameter, result.res_data, 'error')
                    reject(false)
                }
            })
        })
    },
     [Const.RUN_SUBSCRIBE_NEWSLETTER]({ commit, state, getters, dispatch }, credentials) {
        COMMON._executeApi(Enpoints.EP_SUBSCRIBE_NEWSLETTER, credentials, { 'Authorization': 'Bearer ' + getters.GET_TOKEN }, commit, (error, result) => {
            if (!error) {
                credentials.localParams.parameter.errorMsg = 'Thank you for contacting us, we will get back to you soon.'
                credentials.localParams.parameter.msgType = 'success'
                credentials.localParams.parameter.from.email = ""
            } else {
                credentials.localParams.parameter.errorMsg = result.res_data
                credentials.localParams.parameter.msgType = 'error'
               // COMMON._messageDisplayFunction(credentials.localParams.parameter, result.res_data, 'error')
            }
        })
    },
}
