<template>
    <!-- main Banner -->
    <section class="blockElement">
        <div class="container">
            <div class="row justify-content-center text-center">
                <div class="col-lg-9 col-12">
                    <h1 class="mb-4">Awards & Recognition</h1>
                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                </div>
            </div>
        </div>
    </section>
    <section class="blockElement awardPage">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-9 mainTitle space text-center">
                    <ul class="awardList-menu d-flex align-items-center justify-content-between">
                        <li><a @click.prevent="yearTab = 'all';" :class="(yearTab == 'all')?'active':''" href="javascript:void(0)">All</a></li>
                        <li><a @click.prevent="yearTab = '2021';" :class="(yearTab == '2021')?'active':''" href="javascript:void(0)">2021</a></li>
                        <li><a @click.prevent="yearTab = '2020';" :class="(yearTab == '2020')?'active':''" href="javascript:void(0)">2020</a></li>
                        <li><a @click.prevent="yearTab = '2019';" :class="(yearTab == '2019')?'active':''" href="javascript:void(0)">2019</a></li>
                        <li><a @click.prevent="yearTab = '2017';" :class="(yearTab == '2017')?'active':''" href="javascript:void(0)">2017</a></li>
                        <li><a @click.prevent="yearTab = '2016';" :class="(yearTab == '2016')?'active':''" href="javascript:void(0)">2016</a></li>
                    </ul>
                </div>
            </div>
            <div class="row beforeShadow justify-content-center position-relative">
                <div class="col-lg-2 col-6">
                    <div class="winPrize">
                        <div class="expoImg">
                            <img src="assets/images/award/award15.png" alt="Icon">
                        </div>
                        <p class="mb-0">Forex Expo Dubai 2021</p>
                        <a href="javascript:void(0)" class="f-16 link underline">Read more</a>
                    </div>
                </div>
                <div class="col-lg-2 col-6">
                    <div class="winPrize">
                        <div class="expoImg">
                            <img src="assets/images/award/award1.png" alt="Icon">
                        </div>
                        <p class="mb-0">Crotchet 2K1</p>
                        <a href="javascript:void(0)" class="f-16 link underline">Read more</a>
                    </div>
                </div>
                <div class="col-lg-2 col-6">
                    <div class="winPrize">
                        <div class="expoImg">
                            <img src="assets/images/award/award2.png" alt="Icon">
                        </div>
                        <p class="mb-0">D’mans</p>
                        <a href="javascript:void(0)" class="f-16 link underline">Read more</a>
                    </div>
                </div>
                <div class="col-lg-2 col-6">
                    <div class="winPrize">
                        <div class="expoImg">
                            <img src="assets/images/award/award9.png" alt="Icon">
                        </div>
                        <p class="mb-0">G.U.D.I.Y.A - 6th Edition 2020</p>
                        <a href="javascript:void(0)" class="f-16 link underline">Read more</a>
                    </div>
                </div>
                <div class="col-lg-2 col-6">
                    <div class="winPrize">
                        <div class="expoImg">
                            <img src="assets/images/award/award12.png" alt="Icon">
                        </div>
                        <p class="mb-0">Anmi - Certificate of Membership 2019-20</p>
                        <a href="javascript:void(0)" class="f-16 link underline">Read more</a>
                    </div>
                </div>
            </div>
            <div class="row beforeShadow justify-content-center position-relative topSpace">
                <div class="col-lg-2 col-6">
                    <div class="winPrize">
                        <div class="expoImg">
                            <img src="assets/images/award/award14.png" alt="Icon">
                        </div>
                        <p class="mb-0">Business Startup Summit 2019</p>
                        <a href="javascript:void(0)" class="f-16 link underline">Read more</a>
                    </div>
                </div>
                <div class="col-lg-2 col-6">
                    <div class="winPrize">
                        <div class="expoImg">
                            <img src="assets/images/award/award8.png" alt="Icon">
                        </div>
                        <p class="mb-0">Finbridge the financial and technology expo</p>
                        <a href="javascript:void(0)" class="f-16 link underline">Read more</a>
                    </div>
                </div>
                <div class="col-lg-2 col-6">
                    <div class="winPrize">
                        <div class="expoImg">
                            <img src="assets/images/award/award6.png" alt="Icon">
                        </div>
                        <p class="mb-0">Benzinga Fintech Awards 2017</p>
                        <a href="javascript:void(0)" class="f-16 link underline">Read more</a>
                    </div>
                </div>
                <div class="col-lg-2 col-6">
                    <div class="winPrize">
                        <div class="expoImg">
                            <img src="assets/images/award/award10.png" alt="Icon">
                        </div>
                        <p class="mb-0">12 Wing Airforce Chandigarh</p>
                        <a href="javascript:void(0)" class="f-16 link underline">Read more</a>
                    </div>
                </div>
                <div class="col-lg-2 col-6">
                    <div class="winPrize">
                        <div class="expoImg">
                            <img src="assets/images/award/award3.png" alt="Icon">
                        </div>
                        <p class="mb-0">NSE Silver Jubilee</p>
                        <a href="javascript:void(0)" class="f-16 link underline">Read more</a>
                    </div>
                </div>
            </div>
            <div class="row beforeShadow justify-content-center position-relative topSpace">
                <div class="col-lg-2 col-6">
                    <div class="winPrize">
                        <div class="expoImg">
                            <img src="assets/images/award/award4.png" alt="Icon">
                        </div>
                        <p class="mb-0">Appreciation Awards Music Club</p>
                        <a href="javascript:void(0)" class="f-16 link underline">Read more</a>
                    </div>
                </div>
                <div class="col-lg-2 col-6">
                    <div class="winPrize">
                        <div class="expoImg">
                            <img src="assets/images/award/award5.png" alt="Icon">
                        </div>
                        <p class="mb-0">Michal Negrin</p>
                        <a href="javascript:void(0)" class="f-16 link underline">Read more</a>
                    </div>
                </div>
                <div class="col-lg-2 col-6">
                    <div class="winPrize">
                        <div class="expoImg">
                            <img src="assets/images/award/award7.png" alt="Icon">
                        </div>
                        <p class="mb-0">Directorate General Resettlement</p>
                        <a href="javascript:void(0)" class="f-16 link underline">Read more</a>
                    </div>
                </div>
                <div class="col-lg-2 col-6">
                    <div class="winPrize">
                        <div class="expoImg">
                            <img src="assets/images/award/award11.png" alt="Icon">
                        </div>
                        <p class="mb-0">Fincare Small Finance Bank Limited</p>
                        <a href="javascript:void(0)" class="f-16 link underline">Read more</a>
                    </div>
                </div>
                <div class="col-lg-2 col-6">
                    <div class="winPrize">
                        <div class="expoImg">
                            <img src="assets/images/award/award13.png" alt="Icon">
                        </div>
                        <p class="mb-0">Confederation of Indian Industry</p>
                        <a href="javascript:void(0)" class="f-16 link underline">Read more</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            yearTab:'all',
            
        }
    }, 
}
</script>
