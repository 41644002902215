<template>
  <!-- Banner section start -->
  <section class="banner-sec">
    <div class="container cstm">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="text-se">
            <h1 class="fs68 fw-bold mb-3 pb-1 lh-125" v-html="static_vars.home_page.banner_title"></h1>
            <!-- <p class="fs20 fw-medium mb-4 pb-3 lh-190">{{static_vars.home_page.description}}</p> -->
            <p class="fs20 fw-medium mb-4 pb-3 lh-190">Finvasia is a global enterprise that uses engineering and technology to innovate across various sectors such as financial services, technology, healthcare, blockchain, and real estate. We have served over 5 million clients in more than 190 countries and have transacted trillions of USD worth in value through our 10+ brands combined.</p>
            <router-link class="themeBtn btn fs20 rounded-pill h60" to="/about-us">{{static_vars.know_more}}</router-link>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="img-wrap ps-0 ps-lg-5 ps-xxl-4">
            <img class="img-fluid" width="776" height="693" src="assets/images/bannerimg.svg" title="Finvasia" alt="finvasia">
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Banner section end -->
  <!-- our brands section start -->
  <section class="brands-sec sec-padding overflow-hidden position-relative">
    <div class="container cstm">
      <div class="head-sec d-flex align-items-end justify-content-between pb-3 pb-lg-5 mb-3">
        <div class="left-sec mb-2 mb-md-0 mb-lg-2">
          <h2 class="fs68 mb-3 lh-150 dark-color fw-semibold" v-html="static_vars.home_page.sub_title1"></h2>
          <!-- <p class="lh-190 fw-medium" v-html="static_vars.home_page.sub_title1_desc"></p> -->
          <p class="lh-190 fw-medium">Spanning several countries and industries, our brands are engineering-driven, conflict-free and ethical businesses that shape the industry they operate in</p>
        </div>
        <router-link class="outlinebtn btn fs18 rounded-pill h60 px-lg-5 mb-3 alignRightBtn" to="/our-brands">View More</router-link>
      </div>
      <div class="slider-sec">
        <carousel :settings="settings" :breakpoints="breakpoints" :mouseDrag="true" :touchDrag="true" :transition="500" >
          <slide v-for="(list,key) in getBrands(static_vars.our_brands)" :key="key">
            <div class="brand-box br15 grayBg text-start" v-if="list.abstract !=''">
              <div class="logo-sec">
                <img class="img-fluid" width="127" height="28" alt="list.title" title="list.title" :src='"assets/images/our-brands/"+list.image'>
              </div>
              <p class="lh-190 mb-3 pb-3 ps-1" v-html="list.abstract"></p>
              <!-- <a class="outlinebtn btn fs16 rounded-pill h51 px-4" target="_blank" v-if="list.href !=''" :href="list.href">{{static_vars.know_more}}</a>
              <a class="outlinebtn btn fs16 rounded-pill h51 px-4 pointer-none" target="_blank" v-else href="#">{{static_vars.know_more}}</a> -->
              <router-link class="outlinebtn btn fs16 rounded-pill h51 px-4" :to="{name: 'our_brands', hash: '#'+list.id}">{{static_vars.know_more}}</router-link>
            </div>
          </slide>
          <template #addons>
            <navigation />
            <pagination /> 
          </template>
        </carousel>
      </div>
    </div>
  </section>
  <!-- our brands section end -->
  <!-- Mission section start -->
  <div class="mission-sec">
    <div class="container cstm">
      <div class="row align-items-center">
        <div class="col-md-6 col-lg-6">
          <div class="left-sec">
            <h2 class="fs68 mb-0 lh-125 dark-color fw-semibold" v-html="static_vars.home_page.sub_title2"> </h2>
            <p class="lh-190 fw-medium y-4 my-lg-5 pt-4 pb-3">{{static_vars.home_page.sub_title2_desc}}</p>
            <!-- <div class="announce-sec d-flex align-items-center">
              <div class="icon-wrap flex-shrink-0"><img class="img-fluid" height="87" width="87" alt="Announce image" title="announce image" :src='"assets/images/"+static_vars.home_page.sub_title2_image'></div>
              <p class="theme-color lh-150 fs18 fw-medium fst-italic ps-3 flex-fill mb-0">{{static_vars.home_page.sub_title2_image_desc}}</p>
            </div> -->
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="right-sec">
            <!-- item -->
            <div class="mission-item">
              <div class="d-flex align-items-center mb-3">
                <div class="icon-wrap flex-shrink-0 me-1"><img class="img-fluid" width="49" height="49" alt="Mission Icon" title="mission icon" src="assets/images/mission.svg"></div>
                <h3 class="fs30 lh-150 text-uppercase fw-bold ps-3 mb-0">Mission</h3>
              </div>
              <p class="fs16 lh-190 pt-1 mb-0">We're committed to developing a simplified, affordable, and integrated ecosystem across finance, healthcare, and technology sectors. Our mission is to create ethical, sustainable products that benefit stakeholders, leveraging economies of scale and scope to drive positive change in every industry we engage with.</p>
            </div>   
             <!-- item -->
             <div class="mission-item">
              <div class="d-flex align-items-center mb-3">
                <div class="icon-wrap flex-shrink-0 me-1"><img class="img-fluid" width="48" height="48" alt="Vision Icon" title="vision icon" src="assets/images/vision.svg"></div>
                <h3 class="fs30 lh-150 text-uppercase fw-bold ps-3 mb-0">Vision</h3>
              </div>
              <p class="fs16 lh-190 pt-1 mb-0">We aim to leverage engineering and technology to redefine the status quo, and create solutions that solve real-life problems in all the industries we operate in, changing the way businesses have traditionally operated.</p>
            </div>
                    
            <!-- item -->
            <div class="mission-item">
              <div class="d-flex align-items-center mb-3">
                <div class="icon-wrap flex-shrink-0 me-1"><img class="img-fluid" width="48" height="48" alt="Value Icon" title="value icon" src="assets/images/value.svg"></div>
                <h3 class="fs30 lh-150 text-uppercase fw-bold ps-3 mb-0">Value</h3>
              </div>
              <p class="fs16 lh-190 pt-1 mb-0">We invest in cutting-edge research and development, harnessing the power of engineering and technology to build solutions that drive progress, disrupt industries, and set new benchmarks for excellence.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Mission section end -->
  <!-- News section start -->
  <section class="news-sec sec-padding pb-5 mb-3">
    <div class="container cstm">
      <div class="head-sec d-flex align-items-end justify-content-between pb-lg-5 pb-3 mb-3">
        <div class="left-sec mb-2">
          <h2 class="fs68 mb-0 lh-150 dark-color fw-semibold">News & <span class="theme-color d-inline-block headline position-relative">Articles</span></h2>
        </div>
        <router-link class="outlinebtn btn fs18 rounded-pill h60 px-lg-5 mb-2" to="/news">View More</router-link>
      </div>
      <div class="row g-5">
        <!-- item -->
        <div class="col-md-6 col-lg-4" v-for="(list,key,index) in newslist" :index="index" :key="key">
            <div class="news-box grayBg br40 h-100">
                <div class="time-row d-flex align-items-center justify-content-between mb-sm-4 mb-md-3 mb-lg-4 mb-2">
                  <p class="lh-150 mb-1">{{list.published_date}}</p>
                  <p class="lh-150 mb-1 theme-color fw-semibold">{{list.platform}}</p>
                </div>
                <div class="img-wrap" v-if="list.image && list.image != ''">
                    <img class="img-fluid" :src='"assets/images/finvasia_news_images/"+list.image' alt="">
                </div>
                <h3 class="lh-150 fs20 fw-medium pb-2 news-title" v-html="list.heading"></h3>
                <p class="lh-190 mb-lg-4 mb-3" v-html="list.description.substring(0,250)+'...'"></p>
                <a :href="list.link" class="outlinebtn btn fs16 rounded-pill h51 mt-1" target="_blank">Read More</a>
            </div>
        </div>
     </div>
   </div>
  </section>
  <!-- News section end -->
  <!-- <div class="welcome_msg_wrapper" v-show="homepopup">
    <div class="welcome_iner_row">
        <div class="close_msg_popup" @click="closepopup()">&#10006;</div>
        <div class="welCome_msg">
          <h2 class="mb-1">Continue on <a href="javascript:void(0)" @click="homepopup = false">Finvasia Group website</a></h2>
        
          <span>Or Go To</span>
          <div class="box">
           <h3><a href="http://www.Shoonya.com" target="_blank">Shoonya.com</a></h3> 
           <h3> for trading in Indian capital markets</h3>
          </div>
          
        </div>
    </div>
  // </div>  -->
   <!-- <div class="welcome_msg_wrapper" v-show="homepopup">
    <div class="welcome_iner_row">
        <div class="close_msg_popup" @click="closepopup()">&#10006;</div>
        <div class="welCome_msg NewCardWM">
          <h2 class="mb-4">Keep browsing <span><a href="javascript:void(0)" @click="homepopup = false">Finvasia Group website</a></span></h2>
          <p>or <a href="https://shoonya.com/">click here</a> to be redirected to <br/><span>Shoonya</span> for trading in the capital markets</p>
        </div>
    </div>
  </div>   -->
  <div class="welcome_msg_wrapper" v-show="homepopup">
    <div class="welcome_iner_row">
        <div class="close_msg_popup" @click="closepopup()">&#10006;</div>
        <div class="welCome_msg NewCardWM">
         <h2 class="mb-3">Welcome to Finvasia Group!</h2>
           <!-- <h2 class="mb-4">You're visiting the <span><a href="javascript:void(0)" @click="homepopup = false">Finvasia Group</a></span> website</h2>-->
          <!-- <h2 class="mb-4">To visit Shoonya, please click here</h2> -->
           <p class="mb-4 btmH2">To visit Shoonya, please click here</p> 
          <p class="msg_popup_btns"><a target="_blank" class="popCardBtn pcb_dark" href="https://shoonya.com/">Access Shoonya</a>
          <a class="popCardBtn underlineBtn"  @click="closepopup()">Stay on Site</a></p>
        </div>
    </div>
  </div> 
</template>
<script>
  // import commonAllmixins from "@/plugins/commonAll";
  // import { Form, Field, ErrorMessage } from "vee-validate";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation, } from "vue3-carousel";
import {useMainStore} from "../../store/index.ts"
import { gsap } from "gsap";
export default {
  // mixins: [commonAllmixins],
  setup(){
    const store= useMainStore();
    return {store};
  },
  data() {
    return {
      current_countries: {"IN": "India"},
      homepopup:false,
      Display_items:9,
      brandsTab: "All",
      brandsinTab: "Shoonya",
      form: {
        fname: "",
        lname: "",
        email: "",
        msg: "",
      },
      submitted: false,
      pageLoader: false,
      errorMsg: "",
      msgType: "",
      settings: {
        itemsToShow:1,
        snapAlign: "center",
        itemsToScroll: 1,
      },
      newslist: {},
      breakpoints: {
        575: {
          itemsToShow: 2,
          snapAlign: "center",
          itemsToScroll: 1,
        },
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
          itemsToScroll: 2,
        },
        1199: {
          itemsToShow: 3.3,
          snapAlign: "start",
          itemsToScroll: 3,
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Navigation,
    // is_cyprus:false,
    // Pagination,
    // Form,
    // Field,
    // ErrorMessage,
  },
  created(){   
    this.store.getcountrycode().then(res=>{
      if(res== true){
        this.getBrands(this.static_vars.our_brands)
        this.getdatalist()
      }     
    })    
    this.newslistfun()
  },
  methods: {
    getBrands(content){
      let not_eu_content= ['Capital Wallet','AAAFX']
      if(this.store.not_cyprus_brand == true){
        content = content.filter((obj) => {
          return !not_eu_content.includes(obj.title.trim(' '))
        })
        return content;
      }else{
        return content
      }
    },
    newslistfun(){
        let list= this.static_vars.news_page
        if(list.length > 0){  
            this.norecord = false 
            this.newslist = list.slice(0, 3)
        }else{
            this.norecord = true  
            return; 
        }        
    },
    getdatalist(){  
        if (this.store.current_location_IN == true) {
          let popupstatus= window.localStorage.getItem('homepopup')
          if(popupstatus == null){
            this.homepopup= true
            window.localStorage.setItem('homepopup',true)
          }else if(popupstatus == 'true'){
            this.homepopup= true
          }else{
            this.homepopup= false
          }
        }
    },
    closepopup(){
      this.homepopup = false
      window.localStorage.setItem('homepopup',false)
    },
    popupmessage(){
      const _that = this;
      _that.homepopup= true
      setTimeout(function(){
        _that.homepopup= false
      }, 5000)
    },
    submit(values, { resetForm }) {
      const _that = this;
      let input_json = {
        globalParams: {
          subject: "",
          email: _that.form.email,
          msg: _that.form.msg,
          name : `${_that.form.fname} ${ _that.form.lname}`
        },
        localParams: { parameter: _that },
      };
      _that
        ._runContactUs(input_json)
        .then(() => {
          resetForm();
          setTimeout(() =>{
            _that.errorMsg = ''
            _that.msgType = ''
          }, 5000)
        })
        .catch((e) => {
          console.log("e", e);
          setTimeout(() => {
            _that.errorMsg = ''
            _that.msgType = ''
          }, 5000)
        });
    },
  },
  mounted() {
    var tl = gsap.timeline()
      tl.to("#grayLine", {strokeDashoffset : 0, duration: 6,  yoyo: true, ease: 'none'})
        .to("#logoText path", {stroke: '#BD5840', })
        .to("#logoText path", {strokeDashoffset : 85, duration: 5, ease: 'none'})
        .to("#logoText path", {fill: '#BD5840', duration: 1, ease: 'none'})
        // .to("#themeLine", {strokeDashoffset : 85, duration: 6, ease: 'none', repeat: -1})      
  },
    
};
</script>
