import Vue from 'vue'
import { Const } from './provider/lib/const.cls'
// import * as Types from '../../types'
import { mapActions } from 'vuex'
import { commonAll } from './lib/commonAll.cls'
import * as commonAllStoreExtensions from './provider/'
import moment from 'moment'
/* eslint-disable */
const state = {
  ...commonAllStoreExtensions.stateExt 
}
const actions = {
  ...commonAllStoreExtensions.actionsExt
}
const mutations = {
  ...commonAllStoreExtensions.mutationsExt
}
const getters = {
  ...commonAllStoreExtensions.gettersExt
}
// new store module definition
const commonAllStore = {
	state,
	actions,
	mutations,
	getters
}
// main mixin to load the new store module
export const commonAllmixins = {
	created() {
		const _store = this.$store
		if (!(_store && _store.state && _store.state[commonAll.STORE_MODULE_NAME])) {
			try {
				this.$store.registerModule(commonAll.STORE_MODULE_NAME, commonAllStore)
			} catch (error) {}
		}
	},
	methods: {
		...mapActions({
			
            _runContactUs 			    : 			Const.RUN_CONTACT_US,
            _runsubscribeNewsletter     : 			Const.RUN_SUBSCRIBE_NEWSLETTER,
		}),
		pageName(val){
			this.$parent.pageTxt = val
		},
		computedCommonFunc(getFuncName){
			try{
				return this.$store.getters[getFuncName];
			}
			catch(ex){
				return []
			}
		},
		
        
	},
	computed: {
		
	}
}

export default commonAllmixins;

