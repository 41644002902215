/* eslint-disable */
import * as RxFactory from '../api/rx-factory'
import * as Enpoints from '../api/endpoints'
export function _executeApi(apiLink, credentials, getToken, commit, callback) {
    try {
        let response_array = []
        if (credentials.localParams.type == 'form') { credentials.globalParams = credentials.globalParams.form }
        /*if (getToken == '') {
            return
            // getToken = '' 
        }else if(getToken.Authorization && getToken.Authorization.includes('undefined')){
            return
        }*/
        if(credentials.localParams.page && credentials.localParams.page > 1){
            credentials.localParams.parameter.pageLoader1 = true
        }else{
            credentials.localParams.parameter.pageLoader = true
        }
        // let link = (credentials.localParams.link)?apiLink:Enpoints.GLOBAL_BASE_URL + apiLink
        let link = apiLink
        if (credentials.localParams.link) {
            RxFactory.get(link, credentials.globalParams, getToken)
                .then(({ data }) => {
                    // console.log('api-data',data,'url',apiLink)
                    // if(Object.keys(data).length == 0){
                    //     callback(false, {"info":"NOK","msg":{"code":"TECH_ERROR","msg":"Some Technical issue"},"data":{},"errors":error})
                    // }
                    if(credentials.localParams.page && credentials.localParams.page > 1){
                        credentials.localParams.parameter.pageLoader1 = false
                    }else{
                        credentials.localParams.parameter.pageLoader = false
                    }
                    if ('info' in data) {
                        if (data.info === 'OK') {
                            response_array.res_data = data.data
                            callback(false, response_array)
                        } else {
                            if (typeof data.msg == 'object') {
                                response_array.res_data = data.msg.msg
                            } else {
                                response_array.res_data = data.msg
                            }
                            callback(true, response_array)
                        }
                    }
                }).catch(error => {
                    _handleErrorResponse(error, commit)
                })
        } else {
            RxFactory.post(link, credentials.globalParams, getToken)
                .then(({ data }) => {
                    // console.log('api-data',data,'url',apiLink)
                    // if(Object.keys(data).length == 0){
                    //     callback(false, {"info":"NOK","msg":{"code":"TECH_ERROR","msg":"Some Technical issue"},"data":{},"errors":error})
                    // }
                     if(credentials.localParams.page && credentials.localParams.page > 1){
                        credentials.localParams.parameter.pageLoader1 = false
                    }else{
                        credentials.localParams.parameter.pageLoader = false
                    }
                    if ('info' in data) {
                        if (data.info === 'OK') {
                            response_array.res_data = data.data
                            callback(false, response_array)
                        } else {
                            if (typeof data.msg == 'object') {
                                response_array.res_data = data.msg.msg
                            } else {
                                response_array.res_data = data.msg
                            }
                            callback(true, response_array)
                        }
                    }
                }).catch((error) => {
                    _handleErrorResponse(error, commit)
                })
        }
    } catch (ex) {
        console.log('ss', ex)
        _handleErrorResponse(ex, commit)
    }
}

export function _messageDisplayFunction(getParameter, getMessage, msgType) {
    //getParameter.response_msg = getMessage
    //getParameter.msg_type = msgType
    getParameter.$notify({
        group: 'foo',
        type: msgType,
        text: getMessage,
        duration: 10000,
        closeOnClick: true
    });
}

export function _cleanUp(commit, msg,) {// status, loaderId
    // let loaderArr = (typeof loaderId !== 'undefined') ? [loaderId] : []
    // commit("SET_INFO", msg)
}

export function _handleErrorResponse(e, commit) {
    let response = { info: 'NOK', msg: 'Service not available' }
    if (typeof e.response === 'undefined') {
        try {
            const statusCheck = e.toString().toLowerCase()
            if (statusCheck.indexOf('network') > -1) {
                response = { info: 'NOK', msg: 'Network Error' }
            }
        } catch (e) { console.log(e) }
    } else {
        response = e.response.data
    }
    _cleanUp(commit, response, response.status)
    if (typeof e.response !== 'undefined') {
        if (e.response.status == 401) {
            let data = {}
            let options = {}
            options.forward = true
            _handleLogout(data, commit, options)
        }
    }
}

export function _handleLogout(data, commit, options) {
    // const router = options.router
    // let user = {}
    localStorage.clear();
    if ('msg' in data && 'info' in data) {
        _cleanUp(commit, data.msg, data.info)
    }
    if (options.forward === true) {
        window.location.replace("/")
    }
}