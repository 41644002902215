import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import Toast from "vue-toastification";
import staticVars from './staticVars'
import vSelect from "vue-select";
import { defineRule } from 'vee-validate';
import * as AllRules from '@vee-validate/rules';
import VPagination from "@hennge/vue3-pagination";
// import Datepicker from 'vue3-date-time-picker';
import VueFeather from 'vue-feather';
// import store from './store/index'

// import { createPopper } from '@popperjs/core';


//Css for npm packages
// import "vue-select/dist/vue-select.css";
// import "vue-toastification/dist/index.css";
// import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import 'vue3-marquee/dist/style.css'
import 'aos/dist/aos.css'
import '/public/assets/css/carousel.css';

// JS
import '../node_modules/@popperjs/core/dist/esm/popper.js'
import '../node_modules/bootstrap/dist/js/bootstrap.min.js'
import VLazyImage from "v-lazy-image";
// import '/public/assets/js/carousel.js';
//recaptcha
import VueClientRecaptcha from 'vue-client-recaptcha/dist/vue-client-recaptcha.es'

// <!--Optional style.css-->
import 'vue-client-recaptcha/dist/style.css';
import '/public/assets/css/font-awesome.min.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '/public/assets/css/style.css';
import '/public/assets/css/responsive.css';

Object.keys(AllRules).forEach(rule => {
    defineRule(rule, AllRules[rule]);
});


const app = createApp(App)

//Create global variables
app.config.globalProperties.static_vars = staticVars


//Pinia store with persisted state
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

//Components for global use

app.use(pinia)

app.use(router)
app.use(Toast, {
    position: 'top-right',
    timeout: 3000,
    hideProgressBar: true,
    type: 'success'
});
//recaptcha
app.component("VueClientRecaptcha", VueClientRecaptcha);  

app.component('v-lazy-image', VLazyImage)
app.component('v-select', vSelect)
app.component('v-paginate', VPagination)
// app.component('date-picker', Datepicker);
app.component(VueFeather.name, VueFeather);
//App mounted
// app.use(store).use(router).mount('#app')
app.use(pinia)
app.use(router)
app.mount('#app')
