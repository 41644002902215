import { createRouter, createWebHistory } from 'vue-router'
import Index from '@/views/index.vue'


import index from '@/views/home/index'
import our_brands from '@/views/our-brands/our_brands'
import contact_us from '@/views/contact/contact_us'
import about_us from '@/views/about/about_us'
import management from '@/views/management/management'
import news from '@/views/news/news'
import awards from '@/views/awards/awards'
import news_details from '@/views/news/news_details'
import privacy from '@/views/terms/privacy_policy'
import terms from '@/views/terms/terms_and_conditions'
import test from '@/views/home/test'



//Tools
// const Tools = () => import('@/views/tools/index.vue')

//Others

const routes = [
  // { path: '/:pathMatch(.*)*', component: Index },
 
  
  {
    path: '/',
    component: Index,
    meta: {
      requiresAuth: true
    },
    children: [
      {path: '/', component: index, name: 'index', meta: {requiresAuth: true}},
      {path: '/our-brands', component: our_brands, name: 'our_brands', meta: {requiresAuth: true}},
      {path: '/contact-us', component: contact_us, name: 'contact_us', meta: {requiresAuth: true}},
      {path: '/about-us', component: about_us, name: 'about_us', meta: {requiresAuth: true}},
      {path: '/management', component: management, name: 'management', meta: {requiresAuth: true}},
      {path: '/news', component: news, name: 'news', meta: {requiresAuth: true}},
      {path: '/awards', component: awards, name: 'awards', meta: {requiresAuth: true}},
      {path: '/news-details', component: news_details, name: 'news_details', meta: {requiresAuth: true}},
      {path: '/terms-and-conditions', component: terms, name: 'terms_and_conditions', meta: {requiresAuth: true}},
      {path: '/privacy-policy', component: privacy, name: 'privacy_policy', meta: {requiresAuth: true}},
      {path: '/test', component: test, name: 'test', meta: {requiresAuth: true}},

    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    if (to.hash) {
      // return new Promise((resolve) => {
      //   setTimeout(() => {
      //     resolve({ top: 100,  el: to.hash, behavior: 'smooth' })
      //   },0)
      // })
      return { 
        top: 70,
        el: to.hash,
        behavior: 'smooth',
      }
    }
  }

})

// router.beforeEach((to) => {
//   if (to.path == '/') return { name: 'index' }
// })
export default router
