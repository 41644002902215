<template>
  <div class="contactus-page">
    <section class="locations-sec pt-0">
        <div class="container cstm">
            <div class="page-heading text-center pb-5 pt-4 mt-3">
                <h1 class="fs70 lh-150 mb-0 fw-bold">Contact <span class="theme-color">Us</span></h1>
            </div>

            <div class="location-content mt-2">
                <div class="row border-bottom border-top mx-0">
                    <div class="col-md-12 col-lg-4">
                        <div class="location-item">
                            <div class="d-flex align-items-center city-sec mb-1" @click.prevent="changeTab(1)" :class="[{ 'arrowUp': Tab == 1 }]">
                                <div class="flag-wrap flex-shrink-0 me-3">
                                    <img src="assets/images/country_images/India.svg" />
                                </div>
                                <h3 class="fs18 theme-color mb-0 fw-semibold ps-1 flex-fill">India</h3>
                            </div>
                            <p class="d-flex align-items-start mb-0 lh-190 hidesm" :class="[{ 'activeData': Tab == 1 }]">
                                <span class="fw-semibold fs18">Address</span><span class="px-4">:</span>
                                <span class="">
                                    <!-- D-179 Finvasia Centre Phase 8b<br/>
                                    Industrial Area G F Sector 74 -->
                                    Finvasia Centre, D 179, Industrial Area,<br/> Sector 74, SAS Nagar, Punjab 160055
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-4">
                    <div class="location-item">
                        <div class="d-flex align-items-center city-sec mb-1" @click.prevent="changeTab(2)" :class="[{ 'arrowUp': Tab == 2 }]">
                            <div class="flag-wrap flex-shrink-0 me-3">
                                <img src="assets/images/country_images/India.svg" />
                            </div>
                            <h3 class="fs18 theme-color mb-0 fw-semibold ps-1 flex-fill">India</h3>
                        </div>
                        <p class="d-flex align-items-start mb-0 lh-190 hidesm" :class="[{ 'activeData': Tab == 2 }]">
                            <span class="fw-semibold fs18">Address</span><span class="px-4">:</span>
                            <span class="">
                                Corporate Office: 1505, 15th Floor, B Wing, One BKC, G Block, Bandra Kurla Complex, Mumbai

                            </span>
                        </p>
                    </div>
                    </div>
                    <div class="col-md-12 col-lg-4">
                        <div class="location-item">
                            <div class="d-flex align-items-center city-sec mb-1" @click.prevent="changeTab(3)" :class="[{ 'arrowUp': Tab == 3 }]">
                                <div class="flag-wrap flex-shrink-0 me-3">
                                    <img src="assets/images/country_images/Greece.svg" />
                                </div>
                                <h3 class="fs18 theme-color mb-0 fw-semibold ps-1 flex-fill">Greece</h3>
                            </div>
                            <p class="d-flex align-items-start mb-0 lh-190 hidesm" :class="[{ 'activeData': Tab == 3 }]">
                                <span class="fw-semibold fs18">Address</span><span class="px-4">:</span>
                                <span class="">
                                Akti Kondili 14, <br />
                                Piraeus 185 45, Greece
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row border-bottom mx-0">
                    <div class="col-md-12 col-lg-4">
                        <div class="location-item">
                            <div class="d-flex align-items-center city-sec mb-1" @click.prevent="changeTab(4)" :class="[{ 'arrowUp': Tab == 4 }]">
                                <div class="flag-wrap flex-shrink-0 me-3">
                                    <img src="assets/images/country_images/Cyprus.svg" />
                                </div>
                                <h3 class="fs18 theme-color mb-0 fw-semibold ps-1 flex-fill">Cyprus</h3>
                            </div>
                            <p class="d-flex align-items-start mb-0 lh-190 hidesm" :class="[{ 'activeData': Tab == 4 }]">
                                <span class="fw-semibold fs18">Address</span><span class="px-4">:</span>
                                <span class="">
                                  319, 28th October Street,
                                  Kanika Business Center,
                                  Office 201B, 3105, Limassol, Cyprus.
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-4">
                      <div class="location-item">
                          <div class="d-flex align-items-center city-sec mb-1" @click.prevent="changeTab(5)" :class="[{ 'arrowUp': Tab == 5 }]">
                              <div class="flag-wrap flex-shrink-0 me-3">
                                  <img src="assets/images/country_images/Canada.svg" />
                              </div>
                              <h3 class="fs18 theme-color mb-0 fw-semibold ps-1 flex-fill">Canada</h3>
                          </div>
                          <p class="d-flex align-items-start mb-0 lh-190 hidesm" :class="[{ 'activeData': Tab == 5 }]">
                              <span class="fw-semibold fs18">Address</span><span class="px-4">:</span>
                              <span class="">
                                  6740 Davand Drive, Unit #10 <br />
                                  Mississauga, ON,Canada L5T 2K9
                              </span>
                          </p>
                      </div>
                  </div>
                  <div class="col-md-12 col-lg-4">
                      <div class="location-item">
                          <div class="d-flex align-items-center city-sec mb-1" @click.prevent="changeTab(6)" :class="[{ 'arrowUp': Tab == 6 }]">
                              <div class="flag-wrap flex-shrink-0 me-3">
                                  <img src="assets/images/country_images/Australia.svg" />
                              </div>
                              <h3 class="fs18 theme-color mb-0 fw-semibold ps-1 flex-fill">Australia</h3>
                          </div>
                          <p class="d-flex align-items-start mb-0 lh-190 hidesm" :class="[{ 'activeData': Tab == 6 }]">
                              <span class="fw-semibold fs18">Address</span><span class="px-4">:</span>
                              <span class="">5,118 Hawker St, Ridleyton,<br/>SA 5008</span>
                          </p>
                      </div>
                  </div>
                </div>
                <div class="row border-bottom mx-0">
                    <div class="col-md-12 col-lg-4">
                      <div class="location-item">
                          <div class="d-flex align-items-center city-sec mb-1" @click.prevent="changeTab(7)" :class="[{ 'arrowUp': Tab == 7 }]">
                              <div class="flag-wrap flex-shrink-0 me-3">
                                  <img src="assets/images/country_images/US.svg" />
                              </div>
                              <h3 class="fs18 theme-color mb-0 fw-semibold ps-1 flex-fill">United States</h3>
                          </div>
                          <p class="d-flex align-items-start mb-0 lh-190 hidesm" :class="[{ 'activeData': Tab == 7 }]">
                              <span class="fw-semibold fs18">Address</span><span class="px-4">:</span>
                              <span class="">
                                  30 Wall St., 8th Floor,<br />
                                  New York, NY 10005
                              </span>
                          </p>
                      </div>
                  </div>
                    <div class="col-md-12 col-lg-4">
                        <div class="location-item">
                            <div class="d-flex align-items-center city-sec mb-1" @click.prevent="changeTab(8)" :class="[{ 'arrowUp': Tab == 8 }]">
                                <div class="flag-wrap flex-shrink-0 me-3">
                                    <img src="assets/images/country_images/UK.svg" />
                                </div>
                                <h3 class="fs18 theme-color mb-0 fw-semibold ps-1 flex-fill">United Kingdom</h3>
                            </div>
                            <p class="d-flex align-items-start mb-0 lh-190 hidesm" :class="[{ 'activeData': Tab == 8 }]">
                                <span class="fw-semibold fs18">Address</span><span class="px-4">:</span>
                                <span class="">
                                  Unit 129 The Light Box, 111 Power <br/>
                                  Road, London, England, W4 5PY
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-4">
                      <div class="location-item">
                          <div class="d-flex align-items-center city-sec mb-1" @click.prevent="changeTab(9)" :class="[{ 'arrowUp': Tab == 9 }]">
                              <div class="flag-wrap flex-shrink-0 me-3">
                                  <img src="assets/images/country_images/mauritius.svg" />
                              </div>
                              <h3 class="fs18 theme-color mb-0 fw-semibold ps-1 flex-fill">Mauritius</h3>
                          </div>
                          <p class="d-flex align-items-start mb-0 lh-190 hidesm" :class="[{ 'activeData': Tab == 9 }]">
                              <span class="fw-semibold fs18">Address</span><span class="px-4">:</span>
                              <span class="">
                                   5th Floor, The CORE, No. 62, ICT Avenue, <br/>
                                   Cybercity 72201, Ebene, Republic of Mauritius.
                              </span>
                          </p>
                      </div>
                  </div>
                </div>
                <div class="row border-bottom mx-0">
                    <div class="col-md-12 col-lg-4">
                        <div class="location-item">
                            <div class="d-flex align-items-center city-sec mb-1" @click.prevent="changeTab(10)" :class="[{ 'arrowUp': Tab == 10 }]">
                                <div class="flag-wrap flex-shrink-0 me-3">
                                    <img src="assets/images/country_images/SA.svg" />
                                </div>
                                <h3 class="fs18 theme-color mb-0 fw-semibold ps-1 flex-fill">South Africa</h3>
                            </div>
                            <p class="d-flex align-items-start mb-0 lh-190 hidesm" :class="[{ 'activeData': Tab == 10 }]">
                                <span class="fw-semibold fs18">Address</span><span class="px-4">:</span>
                                <span class="">
                                  44 Melrose Boulevard, Melrose Arch,<br/>
                                  Gauteng 2076, South Africa
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-4">
                        <div class="location-item">
                            <div class="d-flex align-items-center city-sec mb-1" @click.prevent="changeTab(11)" :class="[{ 'arrowUp': Tab == 11 }]">
                                <div class="flag-wrap flex-shrink-0 me-3">
                                    <img src="assets/images/country_images/Japan.svg" />
                                </div>
                                <h3 class="fs18 theme-color mb-0 fw-semibold ps-1 flex-fill">Japan</h3>
                            </div>
                            <p class="d-flex align-items-start mb-0 lh-190 hidesm" :class="[{ 'activeData': Tab == 11 }]">
                                <span class="fw-semibold fs18">Address</span><span class="px-4">:</span>
                                <span class="">
                                  1-11-12, Meiekiminami, Nakamura-Ku, <br />
                                  Nagoya-Shi, Aichi-Ken
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-4">
                        <div class="location-item">
                            <div class="d-flex align-items-center city-sec mb-1" @click.prevent="changeTab(12)" :class="[{ 'arrowUp': Tab == 12 }]">
                                <div class="flag-wrap flex-shrink-0 me-3">
                                    <img src="assets/images/country_images/dubai.svg" />
                                </div>
                                <h3 class="fs18 theme-color mb-0 fw-semibold ps-1 flex-fill">Dubai</h3>
                            </div>
                            <p class="d-flex align-items-start mb-0 lh-190 hidesm" :class="[{ 'activeData': Tab == 12 }]">
                                <span class="fw-semibold fs18">Address</span><span class="px-4">:</span>
                                <span class="">
                                    Unit No: 2306, DMCC Business Centre, Level No 1, 
                                    Jewellery & Gemplex 3,<br />
                                    Dubai - United Arab Emirates
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
      <section class="form-sec">
          <div class="container cstm">
              <div class="heading-sec text-center">
                  <h2 class="fs60 lh-150 mb-0 mb-lg-2 fw-semibold">Get in <span class="theme-color">Touch!</span></h2>
                  <!-- <p class="lh-190 mb-0 mb-lg-5 pb-4">All our news in one place. From Finvasia news, articles, and Press Releases, read about it here.</p> -->
              </div>
              <div class="form-wrap mt-2 pt-lg-3 mx-auto">
                <!-- <form class="row">
                    <div class="col-md-6 mb-4 form-floating">
                        <input type="text" id="firstname" placeholder="First name" class="form-control cmn-input h65" />
                        <label for="firstname">First Name</label>
                    </div>
                    <div class="col-md-6 mb-4 form-floating">
                        <input type="text" class="form-control cmn-input h65" placeholder="Last Name" />
                        <label for="">Last Name</label>
                    </div>
                    <div class="col-md-6 mb-4 form-floating">
                        <input type="email" class="form-control cmn-input h65" placeholder="Email Address" />
                        <label for="">Email Address</label>
                    </div>
                    <div class="col-md-6 mb-4 form-floating">
                        <input type="tel" class="form-control cmn-input h65" placeholder="Phone No." />
                        <label for="">Phone No.</label>
                    </div>
                    <div class="col-12 mb-4 pb-2 pb-lg-3 form-floating">
                        <textarea class="form-control cmn-input" rows="5" placeholder="Message"></textarea>
                        <label for="">Message</label>
                    </div>
                    <div class="col-12 text-center mb-3 mb-lg-0">
                        <button type="submit" class="outlinebtn btn rounded-pill h60 px-lg-5 mb-lg-5 mb-4 mx-auto">Contact Finvasia</button>
                    </div>
                </form> -->
                  <Form  class="row" @submit="contactus()">
                      <div class="col-md-6 mb-4 form-floating">
                          <Field type="text" id="firstname" v-model="form.firstname" :rules="RequiredName" name="firstname" placeholder="First name" class="form-control cmn-input h65" />
                          <ErrorMessage class="errormesg" name="firstname" />
                          <label for="firstname">First Name</label>
                      </div>
                      <div class="col-md-6 mb-4 form-floating">
                          <Field type="text" class="form-control cmn-input h65" v-model="form.lastname"  name="lastname"  placeholder="Last Name" />
                          <label for="">Last Name</label>
                      </div>
                      <div class="col-md-6 mb-4 form-floating">
                          <Field type="email" class="form-control cmn-input h65" name="email"  v-model="form.email"  :rules="validateEmail"  placeholder="Email Address" />
                          <ErrorMessage class="errormesg" name="email" />
                          <label for="">Email Address</label>
                      </div>
                      <div class="col-md-6 mb-4 form-floating">
                          <Field type="tel" class="form-control cmn-input h65" name="phone" v-model="form.phone" :rules="validphone"  placeholder="Phone No." />
                          <ErrorMessage class="errormesg" name="phone" />
                          <label for="">Phone No.</label>
                      </div>
                      <div class="col-12 mb-4 pb-2 pb-lg-3 form-floating">
                          <!-- <textarea class="form-control cmn-input" rows="5" placeholder="Message"></textarea> -->
                          <Field class="form-control cmn-input" as="textarea" placeholder="Message" name="message" v-model="form.message" :rules="isRequired" id="message" cols="30" rows="10"/>
                          <ErrorMessage class="errormesg" name="message" />
                          <label for="">Message</label>
                      </div>
                      <div class="col-12 text-center mb-4" v-if="(form.firstname && form.email && form.message && form.phone) !=''">
                            <div class="captcahRow">
                                <div class="captcahCol_iner">
                                <Field type="text" class="form-control cmn-input h65" name="captcha"  v-model="inputValue"  :rules="requiredCaptcha"  placeholder="Captcha" />
                                <VueClientRecaptcha
                                :value="inputValue"
                                @getCode="getCaptchaCode"
                                @isValid="checkValidCaptcha"
                            />
                        </div>
                                <ErrorMessage class="errormesg" name="captcha" />
                                <p class="errormesg" v-if="captchainvalid && captchainvalid !=''">{{captchainvalid}}</p>
                            </div>
                            <div class="col-4">
                            
                            </div>                        
                      </div>
                      <div class="col-12 text-center mb-3 mb-lg-0">
                          <button type="submit" class="outlinebtn btn rounded-pill h60 px-lg-5 mb-lg-5 mb-4 mx-auto" :disabled="!validatecaptcha">Contact Finvasia</button>
                      </div>
                  </Form>
                 
                  <div class="d-flex justify-content-center success-box" v-if="EnquirySuccess">
                    <p class="mb-0">Thank you for your message. Our team will reach out to you shortly.</p>
                  </div>
              </div>
          </div>
      </section>
  </div>
</template>
<script>
//   import { ref } from "vue";
  import "vue-client-recaptcha/dist/style.css";   
//   import commonAllmixins from "@/plugins/commonAll";
  import { Field, Form, ErrorMessage } from 'vee-validate';
  import { useMainStore } from "../../store/index.ts";
  export default {
    //   mixins: [commonAllmixins],
      name: "Contact-us",
        setup(){
            const store= useMainStore();
            // const inputValue = ref(null);

            // const getCaptchaCode = (value) => {
            //     console.log("captcah =========>",value);
            // };
            // const checkValidCaptcha = (value) => {
            //     console.log(value);
            //     if(value != true){
            //         return 'Please provide the valid captcha';
            //     }else{
            //         let validatecaptcha=true
            //        console.log("captcah submited=============",validatecaptcha)
            //     }
                
            // };
            // return { store, inputValue, getCaptchaCode, checkValidCaptcha,};
            return {store};
        },
      data() {
            return {
                captchainvalid:'',
                validatecaptcha:false,
                inputValue:'',
                EnquirySuccess: false,
                form:{
                    firstname:'',
                    lastname:'',
                    phone:'',
                    email:'',
                    message:''
                },                
                ErrorMessage:'',
                activeUl: false,
                Tab: 1,
                submitted:false
            };
        },
      components: {
        Form,
        Field,
        ErrorMessage
      },
    methods: {
        //recaptcha
        //start
        getCaptchaCode(value){
            /* you can access captcha code */
            this.checkValidCaptcha(value)
        },
        checkValidCaptcha(value){
        /* expected return boolean if your value and captcha code are same return True otherwise return False */
            if(value == true){
                this.validatecaptcha= true
                return this.validatecaptcha;
            }else{
                this.validatecaptcha= false
                return this.validatecaptcha;
            }            
        },
        //end
        requiredCaptcha(value) {
            return value ? true : 'Please provide the captcha';
        },
        validphone(value) {
            if (!value) {
                return 'Please provide your Contact Number';
            }            
            const regex= /^[0-9]\d{2}[0-9]\d{2}\d{4}$/ ;
            if (!regex.test(value)) {
                return 'Please provide valid Phone Number';
            }
            return true;
        },
        RequiredName(value) {
            return value ? true : 'Please provide your name';
        },
        isRequired(value) {
            return value ? true : 'This field is required';
        },
        validateEmail(value) {
            if (!value) {
                return 'Please provide your email address';
            }
            const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (!regex.test(value)) {
                return 'Please provide valid email address';
            }
            return true;
        },        
        changeTab(val) {
            this.Tab = val;
        },
        contactus(){
            const _that = this
            if(_that.validatecaptcha == true){      
                _that.validatecaptcha == false
                _that.submitted= true
                _that.EnquirySuccess= true  
                let input_json = {
                    globalParams: {
                        email: _that.form.email,
                        subject:  "",
                        text: "email :"+_that.form.email+", subject : '', name :"+_that.form.firstname+_that.form.lastname+", msg :"+_that.form.message+",phone :"+_that.form.phone
                    },
                    localParams: { parameter: _that },
                };
                this.store.contactus(input_json).then(res=>{
                    if(res){
                        setTimeout(function(){
                            _that.submitted= false
                            _that.EnquirySuccess= false
                            _that.ResetForm()
                        }, 5000)  
                    }
                })
            }else{
                return ;
            }
                
        },
        ResetForm(){
            this.ErrorMessage='',
            this.form.firstname='',
            this.form.lastname='',
            this.form.phone='',
            this.form.email='',
            this.form.message=''
        }
    },
  };
</script>