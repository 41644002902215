export default {
    limitPerPage: 10,
    BlogsArray : [
        {
            "id": 3,
            "count": 87,
            "description": "",
            "link": "https://blog.finvasia.com/wp-admin/",
            "name": "Knowledge Crunch",
            "slug": "knowledge-crunch",
            "taxonomy": "category",
            "parent": 0,
            "meta": []
        },
        {
            "id": 3,
            "count": 87,
            "description": "",
            "link": "https://blog.finvasia.com/wp-admin/",
            "name": "All",
            "slug": "all",
            "taxonomy": "category",
            "parent": 0,
            "meta": []
        },
    ],
    registrationType: [
        { label: "Individual", value: "0" },
        { label: "Corporate", value: "1" }
    ],
    leverages: [
        { label: "100X", value: "100" },
        { label: "200X", value: "200" },
        { label: "300X", value: "300" },
        { label: "400X", value: "400" },
        { label: "500X", value: "500" }
    ],
    accountTypes: [
        { label: 'ECN', value: 'ECN' },
        { label: 'ECN PRO', value: 'ECNP' },
        { label: 'Classic', value: 'CLASSIC' },
        { label: 'Profit Sharing', value: 'PROFIT_SHARING' }
    ],
    platforms: [
        { label: 'MetaTrader 4', value: 'MT4' },
        { label: 'MetaTrader 5', value: 'MT5' },
        { label: 'Zulu Trading Platform', value: 'ZTP' }
    ],
    currency: {
        'USD': '$',
        'GBP': '£',
        'EUR': '€',
    },
    //METHODS FROM FXVIEW
    // depositMethod: { '1': '"Bank"', '2': '"Skrill"', '3': '"Neteller"', '4': '"Trading Account"', '5': '"Debit/Credit card"', '6': '"Giropay"', '7': '"Przelewy"', '8': '"Rapid Transfer"', '9': '"Nordea"', '10': '"Klarna"', '11': '"NBP"', '12': '"TR"', '13': '"CR"', '15': '"Loyalty Transfer"', '18': '"Bitcoin"', '17': '"Praxis"', '19': '"LBT-Gatech"', '20': '"LBT-Payguru"', '21': '"LBT-PayRetailer"', '22': '"LBT-Rapid OZOW"' }
    depositMethods: {
        keys: ['All', 'Bank Cards', 'Bank Wire', 'E-Wallet', 'Cryptos'],
        methods: [
            {
                type: 'Bank Cards',
                minimumDeposit: 100,
                methodID: 5,
                name: 'Credit Cards',
                image: 'dep1.png',
                region: 'World Wide',
                currencies: 'EUR, USD, CHF, GBP',
                processingTime: 'Instant',
                action: ''
            },
            {
                type: 'Bank Wire',
                minimumDeposit: 100,
                methodID: 2,
                name: 'Eurobank',
                image: 'dep2.png',
                region: 'World Wide',
                currencies: 'USD',
                processingTime: '2-3 business days',
                action: ''
            },
            {
                type: 'Bank Wire',
                minimumDeposit: 100,
                methodID: 3,
                name: 'Dixipay',
                image: 'dep3.png',
                region: 'Europe',
                currencies: 'EUR (Sepa Only)',
                processingTime: '2-3 business days',
                action: ''
            },
            {
                type: 'Bank Wire',
                minimumDeposit: 100,
                methodID: 4,
                name: 'Help 2 Pay',
                image: 'dep4.png',
                region: 'South East Asia',
                currencies: 'MY, TH, ID, PH, VN',
                processingTime: 'Instant',
                action: ''
            },
            {
                type: 'Bank Wire',
                minimumDeposit: 100,
                methodID: 5,
                name: 'Pay Retailers',
                image: 'dep5.png',
                region: 'Brazil, Mexico, Chile',
                currencies: 'BRL, MXN, CLP',
                processingTime: 'Instant',
                action: ''
            },
            {
                type: 'E-Wallet',
                minimumDeposit: 100,
                methodID: 6,
                name: 'Virtual Pay',
                image: 'dep6.png',
                region: 'East Africa',
                currencies: 'TAS, KES, MTN',
                processingTime: '2-3 business days',
                action: ''
            },
            {
                type: 'E-Wallet',
                minimumDeposit: 100,
                methodID: 2,
                name: 'Skrill',
                image: 'dep7.png',
                region: 'World Wide',
                currencies: 'EUR, USD, JPY, CHF, GBP, AUD',
                processingTime: '2-3 business days',
                action: ''
            },
            {
                type: 'E-Wallet',
                minimumDeposit: 100,
                methodID: 3,
                name: 'Neteller',
                image: 'dep8.png',
                region: 'World Wide',
                currencies: 'MY, TH, ID, PH, VN',
                processingTime: 'Instant',
                action: ''
            },
            {
                type: 'Cryptos',
                minimumDeposit: 100,
                methodID: 9,
                name: 'Bitcoin',
                image: 'bitcoin-ico.png',
                region: 'World Wide',
                currencies: 'BTC',
                processingTime: '30 mins',
                action: ''
            },
        ]
    },
    timezones: [
        { value: "-12:00", label: '(GMT -12:00) Eniwetok, Kwajalein' },
        { value: "-11:00", label: '(GMT -11:00) Midway Island, Samoa' },
        { value: "-10:00", label: '(GMT -10:00) Hawaii' },
        { value: "-09:50", label: '(GMT -9:30) Taiohae' },
        { value: "-09:00", label: '(GMT -9:00) Alaska' },
        { value: "-08:00", label: '(GMT -8:00) Pacific Time (US &amp; Canada)' },
        { value: "-07:00", label: '(GMT -7:00) Mountain Time (US &amp; Canada)' },
        { value: "-06:00", label: '(GMT -6:00) Central Time (US &amp; Canada), Mexico City' },
        { value: "-05:00", label: '(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima' },
        { value: "-04:50", label: '(GMT -4:30) Caracas' },
        { value: "-04:00", label: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz' },
        { value: "-03:50", label: '(GMT -3:30) Newfoundland' },
        { value: "-03:00", label: '(GMT -3:00) Brazil, Buenos Aires, Georgetown' },
        { value: "-02:00", label: '(GMT -2:00) Mid-Atlantic' },
        { value: "-01:00", label: '(GMT -1:00) Azores, Cape Verde Islands' },
        { value: "+00:00", label: '(GMT) Western Europe Time, London, Lisbon, Casablanca' },
        { value: "+01:00", label: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris' },
        { value: "+02:00", label: '(GMT +2:00) Kaliningrad, South Africa' },
        { value: "+03:00", label: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg' },
        { value: "+03:50", label: '(GMT +3:30) Tehran' },
        { value: "+04:00", label: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi' },
        { value: "+04:50", label: '(GMT +4:30) Kabul' },
        { value: "+05:00", label: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent' },
        { value: "+05:50", label: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi' },
        { value: "+05:75", label: '(GMT +5:45) Kathmandu, Pokhara' },
        { value: "+06:00", label: '(GMT +6:00) Almaty, Dhaka, Colombo' },
        { value: "+06:50", label: '(GMT +6:30) Yangon, Mandalay' },
        { value: "+07:00", label: '(GMT +7:00) Bangkok, Hanoi, Jakarta' },
        { value: "+08:00", label: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong' },
        { value: "+08:75", label: '(GMT +8:45) Eucla' },
        { value: "+09:00", label: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk' },
        { value: "+09:50", label: '(GMT +9:30) Adelaide, Darwin' },
        { value: "+10:00", label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok' },
        { value: "+10:50", label: '(GMT +10:30) Lord Howe Island' },
        { value: "+11:00", label: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia' },
        { value: "+11:50", label: '(GMT +11:30) Norfolk Island' },
        { value: "+12:00", label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka' },
        { value: "+12:75", label: '(GMT +12:45) Chatham Islands' },
        { value: "+13:00", label: '(GMT +13:00) Apia, Nukualofa' },
        { value: "+14:00", label: '(GMT +14:00) Line Islands, Tokelau' },

    ],
    know_more: "Know More",
    address: "Address",
    our_brands: [
        {
            heading:"Shoonya",
            abstract:` Shoonya stands as a trailblazing financial intermediation solution in India, redefining industry standards with its innovative approach. As India's sole "Zero Cost" financial ...`,
            description: `<p class='lh-190'>Shoonya stands as a trailblazing financial intermediation solution in India, redefining industry standards with its innovative approach. As India's sole <span class='fw-semibold theme-color'> "Zero Cost" </span> financial ecosystem, we enable investors and traders to engage in transactions involving any financial product on our platform, completely free from commissions, clearing fees, technology fees, or hidden charges of any kind.</p><p class='lh-190'>Furthermore, Shoonya pioneered the <span class='fw-semibold theme-color'> "electronic KYC" </span> process in India's financial markets, addressing the unique challenges posed by the nation's fragmented population and the lack of a single, comprehensive identification source, unlike in developed countries. Our innovative solutions continue to reshape the financial landscape in India, setting new benchmarks for the industry.</p>` ,
            href:'https://shoonya.com/',
            id: 'shoonya',
            image:'shoonya-logo.svg',
            title:'Shoonya'
        },
        {
            heading:"ZuluTrade",
            abstract:"ZuluTrade is the world's largest broker agnostic social trading platform. We have hosted over 2 million trading accounts from users in over 192 countries. Since 2007, we have been a...",
            description: "<p class='lh-190'>ZuluTrade is the world's largest broker agnostic social trading platform. We have hosted over 2 million trading accounts from users in over 192 countries. Since 2007, we have been a pioneer in social trading, allowing both professional and amateur traders to share their real-time trades with the community.</p> <p class='lh-190'>We pride ourselves on being a truly open social trading network, with traders sharing their trades irrespective of the brokerage or trading platform that they use. To date, we have facilitated over 2 trillion USD in value traded on our platform. As a result of our focus on community and innovation, we have received several prestigious awards and have led the industry as a leader since inception.</p>",
            href:'https://www.zulutrade.com/',
            id: 'zulutrade',
            image:'zulutrade-logo.svg',
            title:'Zulutrade'
        },        
        {
            heading:"Fxview",
            abstract:"FXview is a globally recognized forex and OTC broker, licensed and regulated by over two dozen regulatory bodies. With physical offices in Cyprus, Greece, Dubai, South Africa, and Australia...",
            description: "<p class='lh-190'>Fxview is a globally recognized forex and OTC broker, licensed and regulated by over two dozen regulatory bodies. With physical offices in Cyprus, Greece, Dubai, South Africa, and Australia, Fxview provides traders with access to over 2000 financial instruments across multiple markets from a single platform. The award-winning broker is a leader in providing institutional-grade trading conditions and advanced trading tools to retail investors.</p><p class='lh-190'>Fxview is among the lowest cost OTC brokers in the industry, making it an ideal choice for traders looking for cost-effective and high-quality trading services. With a focus on transparency and customer satisfaction, Fxview offers its clients competitive spreads, zero-commission trading, and lightning-fast execution speeds.</p>",
            href:'https://fxview.com/global',
            id: 'fxview',
            image:'fxview-logo.svg',
            title:'Fxview '
        },
        {
            heading:"AAAFx",
            abstract:"AAAFx is a regulated forex and CFD broker that has been providing trading services since 2008. It was the first OTC broker to receive a license in Greece and has served over 200,000...",
            description: "<p class='lh-190'>AAAFx is a regulated forex and CFD broker that has been providing trading services since 2008. It was the first OTC broker to receive a license in Greece and has served over 200,000 clients in more than 170 countries. AAAFx offers a variety of trading instruments, including forex, indices, stocks, and cryptocurrencies. The broker is registered with multiple regulatory bodies, including the Hellenic Capital Markets Commission and the Federal Financial Supervisory Authority. </p><p class='lh-190'>AAAFx provides traders with advanced trading platforms, such as ZuluTrade and MetaTrader 4, along with excellent customer support. The company has received multiple awards over the years, including the Best Forex Broker Award in Greece and Most Transparent Broker Award. AAAFx is known for its competitive spreads and low trading fees, making it a cost-effective option for traders.</p>",
            href:'https://aaafx.com/global/',
            id: 'aaafxinternational',
            image:'aaafx-logo.png',
            title:'AAAFX'
        },
        {
            heading:"Finvasia Health",
            abstract:"Finvasia Health is revolutionising the fight against diabetes with its state-of-the-art facilities and cutting-edge approach. Its diabetes reversal program combines medical science with...",
            description: "<p class='lh-190'>Finvasia Health is revolutionising the fight against diabetes with its state-of-the-art facilities and cutting-edge approach. Its diabetes reversal program combines medical science with technology and utilises a continuous care model led by Senior Doctors who constantly improve medical protocols built around diabetes remission.</p><p class='lh-190'>Since launching less than a year ago, Finvasia Health has already helped over 6000 patients in India achieve significant reductions in HbA1C levels in as little as three months. But their commitment to patient care doesn't end there. Finvasia Health's personalised approach ensures that every patient receives the attention they need to put diabetes in medical remission.</p>",
            href:'',
            id: 'ginihealth',
            image:'finvasia-health.svg',
            title:'Finvasia Health'
        },
        {
            heading:"Capital Wallet",
            abstract:"Capital Wallet is an award-winning crypto exchange platform that caters to the needs of merchants seeking to accept cryptocurrency payments online. With a wide range of B2B services...",
            description: "<p class='lh-190'>Capital Wallet is an award-winning crypto exchange platform that caters to the needs of merchants seeking to accept cryptocurrency payments online. With a wide range of B2B services, Capital Wallet offers seamless integration with over 1000 different currencies, ready-to-use plugins for popular eCommerce platforms, and a cutting-edge API for quick and easy merchant integration. </p><p class='lh-190'>Capital Wallet's reliable crypto-to-fiat conversion capabilities enable efficient fund management, while its intuitive interface provides complete control and transparency over transactions. Join the award-winning Capital Wallet and experience the power of streamlined cryptocurrency payments processing.</p>",
            href:'https://capitalwallet.com/',
            id: 'capitalwallet',
            image:'capitalwallet-logo.svg',
            title:'Capital Wallet'
        },
        {
            heading:"ACT Trader",
            abstract:"ACT Trader has been a leading fintech platform for over 20 years, providing traders of all levels with access to a range of financial instruments through a user-friendly interface...",
            description: "<p class='lh-190'>ACT Trader has been a leading fintech platform for over 20 years, providing traders of all levels with access to a range of financial instruments through a user-friendly interface. The platform has been utilized by hundreds of financial services companies, both publically listed and private, and is a trusted partner in the industry.</p><p class='lh-190'>The platform built its first mobile trading application in 2004, well before the internet was popular on mobile. By 2007, ACT Trader had already crossed $50 billion in monthly trading volume, showcasing its processing capabilities. Over the years, ACT Trader has received numerous awards and accolades for its innovative technology and commitment to excellence.</p>",
            href:'https://acttrader.com/',
            id: 'acttrader',
            image:'acttrader-logo.svg',
            title:'Act Trader'
        },
        {
            heading:"Portfolios",
            abstract:" Portfolios.com is a community-driven website offering a suite of trading and investment tools for investors of all levels. With real-time news and trade ideas, advanced tools for...",
            description: "<p class='lh-190'>Portfolios.com is a community-driven website offering a suite of trading and investment tools for investors of all levels. With real-time news and trade ideas, advanced tools for fundamental and economic analysis, a real-time economic calendar, real-time prices for over 10,000 instruments worldwide, and charts with over 100 indicators and studies, Portfolios.com empowers traders to make informed decisions.</p><p class='lh-190'> The platform's cutting-edge market sentiment analysis tools and expert insights and analysis provide the latest information on market trends, helping traders stay ahead of the curve.</p>",
            href:'https://portfolios.com/',
            id: 'portfolios',
            image:'portfolios-logo.svg',
            title:'Portfolios'
        },
        {
            heading:"Finvasia Estates",
            abstract:"Finvasia Estates boasts an extraordinary portfolio of commercial real estate assets spanning thousands of square metres. The company aims to develop top-tier residential and commercial projects ",
            description: "<p class='lh-190'>Finvasia Estates boasts an extraordinary portfolio of commercial real estate assets spanning thousands of square metres. The company aims to develop top-tier residential and commercial projects pan India, ensuring better products to the end user while also giving healthy growth opportunities to the quality players in the industry.</p>",
            href:'',
            id: 'finvasiaestate',
            image:'finvasiaestate-logo.png',
            title:'Finvasia Estates'
        },  
        
        {
            heading:"OneVault",
            abstract:"OneVault is set to launch as the ultimate money superApp, providing a comprehensive suite of financial services to users in Europe and select regions worldwide...",
            description: "<p class='lh-190'>OneVault is set to launch as the ultimate money superApp, providing a comprehensive suite of financial services to users in Europe and select regions worldwide.</p><p class='lh-190'>OneVault's cutting-edge technology and state-of-the-art security measures will ensure a seamless and secure user experience. Whether users want to send money to friends and family, pay bills, manage their budget, or make investments, OneVault will have them covered. Stay tuned for the launch of OneVault and experience the future of finance with the ultimate money superApp.</p>",
            href:'',
            id: 'onevault',
            image:'onevault-logo.svg',
            title:'OneVault'
        },
        // {
        //     heading:"OneVault",
        //     abstract:"OneVault is set to launch as the ultimate money superApp, providing a comprehensive suite of financial services to users in Europe and select regions worldwide...",
        //     description: "<p class='lh-190'>OneVault is set to launch as the ultimate money superApp, providing a comprehensive suite of financial services to users in Europe and select regions worldwide.</p><p class='lh-190'>OneVault's cutting-edge technology and state-of-the-art security measures will ensure a seamless and secure user experience. Whether users want to send money to friends and family, pay bills, manage their budget, make investments or purchase digital assets, OneVault will have them covered. Stay tuned for the launch of OneVault and experience the future of finance with the ultimate money superApp.</p>",
        //     href:'',
        //     id: 'onevault',
        //     image:'onevault-logo.svg',
        //     title:'OneVault'
        // },
        // {
        //     heading:"Body Loop",
        //     abstract:"It is our flagship product in India that has been revolutionary in many respects. It is our flagship product in India that has been revolutionary in many respects. It is our flagship product in India",
        //     description: "<p class='lh-190'></p>",
        //     href:'',
        //     id: 'bodyloop',
        //     image:'bodyloop-logo.svg',
        //     title:'Body Loop'
        // },
        // {
        //     heading:"Blockmaze",
        //     abstract:"It is our flagship product in India that has been revolutionary in many respects. It is our flagship product in India that has been revolutionary in many respects. It is our flagship product in India",
        //     description: "<p class='lh-190'></p>",
        //     href:'',
        //     id: 'blockmaze',
        //     image:'blockmaze-logo.svg',
        //     title:'Blockmaze'
        // },
        
        // {
        //     heading:"Stackflow",
        //     abstract:"It is our flagship product in India that has been revolutionary in many respects. It is our flagship product in India that has been revolutionary in many respects. It is our flagship product in India",
        //     description: "<p class='lh-190'></p>",
        //     href:'https://stackflow.org/',
        //     id: 'stackflow',
        //     image:'stackflow-logo.svg',
        //     title:'Stackflow'
        // },
        
        // {
        //     heading:"NeoBank",
        //     abstract:"It is our flagship product in India that has been revolutionary in many respects. It is our flagship product in India that has been revolutionary in many respects. It is our flagship product in India",
        //     description: "<p class='lh-190'></p>",
        //     href:'',
        //     id: 'risebank',
        //     image:'RiseBank.svg',
        //     title:'NeoBank'
        // },
    ],
    home_page: {
        banner_title:"<span class='mb-4 d-inline-block headline'>Re-Engineering</span><br>Traditional Businesses",
        description:"Finvasia is a global enterprise that uses engineering and technology to innovate across various sectors such as financial services, technology, healthcare, blockchain, and real estate. We have served over 5 million clients in more than 190 countries that have transacted trillions of USD worth in value through its 10+ brands combined.",
        sub_title1:"Our <span class='theme-color d-inline-block headline position-relative'>Brands</span>",
        sub_title1_desc: "It is our flagship product in India that has been revolutionary in many respects.",
        sub_title2: "Mission,<br>Vision <span class='theme-color d-inline-block headline position-relative'>& Value</span>",
        sub_title2_desc: "Our goal is to inspire a paradigm shift across finance, healthcare, and technology sectors by challenging the status quo and deploying our engineering and technology expertise to deliver transformative solutions that benefit stakeholders and society at large.",
        sub_title2_image:'announce.svg',
        sub_title2_image_desc:"Our Core Goal is to provide every business simple and fast way to boost the profit "
    },
    news_page:[
            {
                heading:"Fxview voted ‘Best Broker – MEA’ at UF AWARDS MEA 2024",
                description:`<p><a class='links-theme' href='https://fxview.com/global' target='_blank'>Fxview</a>– a global multi-asset brokerage firm part of financial conglomerate Finvasia Group – announced in a press release that it was awarded the ‘Best Broker – MEA’ distinction at the prestigious UF AWARDS MEA 2024 ceremony held on 17 January, during iFX EXPO Dubai.</p>`,
                published_date:'February 12,2024',
                platform:'Fxview',
                link:'https://gulfnews.com/business/corporate-news/fxview-voted-best-broker--mea-at-uf-awards-mea-2024-1.1707475435373',
                image:`fxview_pr3.jpg`
            },
            {
                heading:"ZuluTrade Sets the Stage for 2024 as Premier Sponsor of iFX EXPO Dubai",
                description:`<p><a class='links-theme' href='https://www.dailyforex.com/dr/pr/zulutrade' target='_blank'>ZuluTrade</a>, the largest and first ever social trading platform powered by Finvasia, is gearing up for an exciting expo season commencing in January 2024. Anticipation is growing as the iFX EXPO Dubai draws near, scheduled between January 16-18.</p>`,
                published_date:'January 15,2024',
                platform:'ZuluTrade',
                link:'https://www.dailyforex.com/forex-figures/2024/01/zulutrade-premier-2024-ifx-expo-dubai-sponsor/206075',
                image:`zulu_pr1.jpeg`
            },
            {
                heading:"The Role of Regulation in Forex Trading",
                description:`<p>Foreign exchange (Forex) is the largest and most liquid market in the world. With a daily trading volume of over $7 trillion, according to the latest survey by BIS, it attracts millions of traders globally, from central banks to individuals. Its massive size, global reach and speculative nature make it susceptible to high risks and fraud. This is where regulation comes in.</p>`,
                published_date:'January 10,2024',
                platform:'AAAFx',
                link:'https://www.fintechnews.org/the-role-of-regulation-in-forex-trading/',
                image:`aaa_pr2.jpg`
            },
            {
                heading:"Fxview trailblazes FX Innovation in 2024: A recap of 2023 and a promising future",
                description:`<p>As we draw a line and look at the crowning achievements of the FX industry players in 2023, few can measure themselves up to <a class='links-theme' href='https://fxview.com/' target='_blank'>Fxview</a>, Finvasia’s global Forex and CFD brand and liquidity provider.</p>`,
                published_date:'January 08,2024',
                platform:'Fxview',
                link:'https://www.khaleejtimes.com/kt-network/fxview-trailblazes-fx-innovation-in-2024-a-recap-of-2023-and-a-promising-future#google_vignette',
                image:`fx_pr3.jpeg`
            },
            {
                heading:"AAAFx Year in Review: A Testament to Success in 2023, Exciting Prospects for 2024",
                description:`<p><strong>AAAFx achieved a significant milestone by surpassing one million website users.</strong></p><p>Leading Forex and CFD broker <a class='links-theme' href='https://aaafx.com/' target='_blank'>AAAFx</a> has had a remarkable year featuring prestigious awards, significant milestones and successful participation in key industry events. From being recognised as the ‘Broker of the Year’ to surpassing one million website users, AAAFx further strengthened its position as a trusted and industry-leading company. </p>`,
                published_date:'January 02,2024',
                platform:'AAAFx',
                link:'https://www.financemagnates.com/thought-leadership/aaafx-year-in-review-a-testament-to-success-in-2023-exciting-prospects-for-2024/',
                image:`aaafx_pr3.jpg`
            },
            {
                heading:"Fxview spearheads innovation in South Africa",
                description:`<p><a class='links-theme' href='https://fxview.com/' target='_blank'>Fxview</a>, a leading Forex and CFD industry player operating under the Finvasia Group financial conglomerate, shakes up the financial scene in South Africa with its innovative product suite.</p><p>In a strategic initiative to expand its outreach in the African region, the multi-asset brokerage continues its pursuit of excellence in South Africa’s ever-evolving financial landscape.</p>`,
                published_date:'December 07,2023',
                platform:'Fxview',
                link:'https://www.msn.com/en-za/news/other/fxview-spearheads-innovation-in-south-africa/ar-AA1l99N1',
                image:`fxv_pr1.jpeg`
            },
            {
                heading:"Forex Trading: The Transformational Journey of the Last 20+ Years",
                description:`<p>Dive into the recent history of currency trading from the Gold Standard to digital assets with AAAFx.</p><p>Forex trading or foreign exchange is the most dynamic and liquid market where currencies are bought and sold globally. Over the past two decades, Forex trading has undergone a spectacular transformation, shifting from a traditional and centralised system to a digital and decentralised one. This article looks closely at the evolution of Forex trading within the past 20+ years, charting its path to the digital era.</p>`,
                published_date:'November 22,2023',
                platform:'AAAFx',
                link:'https://www.techinasia.com/forex-trading-transformational-journey-20-years',
                image:`aaa_pr1.jpg`
            },
            {
                heading:"Fxview: Setting Trends In Tier-A Liquidity And Technology Solutions",
                description:`<p>Fxview offers seamless access to Tier-A liquidity with its extensive licenses and credentials, reshaping the industry.</p><p>In a strategic initiative that is reshaping the financial services landscape, <a class='links-theme' href='https://fxview.com/' target='_blank'>Fxview</a> operating under Finvasia's investment banking license (License No. IK21000018),  has significantly expanded its liquidity pools across a range of diverse asset classes by offering an access to outstanding Tier 1 liquidity. This expansion reflects their commitment to offering top-tier liquidity, flawless execution, and state-of-the-art prime broker solutions.</p>`,
                published_date:'November 08,2023',
                platform:'Fxview',
                link:'https://www.benzinga.com/markets/forex/23/11/35672759/fxview-setting-trends-in-tier-a-liquidity-and-technology-solutions',
                image:`fxview_new_pr1.webp`
            },
            {
                heading:"Find a proper fit: A comparison of popular trading platforms with Fxview",
                description:`<p>Learn more about trading global market assets with the world-class trading platforms offered by Fxview – MT4, MT5 and ActTrader. </p><p>In today’s fast-paced and ever-changing world of trading, having access to a powerful and innovative trading platform is essential. Award-winning, technology driven financial services company Fxview offers a range of advanced trading platforms to cater to different trading styles and preferences. </p>`,
                published_date:'November 01,2023',
                platform:'Fxview',
                link:'https://www.thesouthafrican.com/technology/cryptocurrencies/finding-the-proper-fit-a-comparison-of-popular-trading-platforms-with-fxview-october-2023/',
                image:`fxview_new_pr_two.webp`
            },
            {
                heading:"The Core Values Driving the Success of Global Broker AAAFx",
                description:`<p>AAAFx excels by inspiring confidence and empowering traders globally with integrity and expertise.</p><p>In the world of global trading, where ethical practices are essential, AAAFx, a leading Forex and CFD provider with a legacy spanning more than 16 years, continues to uphold its strong commitment to its clients with reliable and transparent service. Its operations are underpinned by its core values – Excellence, Integrity, Quality, Transparency, and Honesty – forming the foundation of its trustworthy environment.</p>`,
                published_date:'October 31,2023',
                platform:'AAAFx',
                link:'https://www.economywatch.com/news/the-core-values-driving-the-success-of-global-broker-aaafx',
                image:`aaafx_new_pr_one.jpg`
            },
            {
                heading:"Fxview Continues to Provide Its Support to the Cyprus National Karate Team",
                description:`<p>Fxview will stand by the athletes at the 26th World Senior Karate Championships in Budapest.</p>`,
                published_date:'October 23,2023',
                platform:'Fxview',
                link:'https://www.fxempire.com/news/article/fxview-continues-to-provide-its-support-to-the-cyprus-national-karate-team-1382979',
                image:`fx_new_pr_1.jpeg`
            },
            {
                heading:"Copy Trading for Beginners - A Guide by ZuluTrade",
                description:`<p>In recent years, copy trading has gained significant popularity. Many investors are turning to platforms that allow them to copy the trades or strategies of experienced leaders without having extensive knowledge of financial market dynamics.</p>`,
                published_date:'October 16,2023',
                platform:'ZuluTrade',
                link:'https://www.malaysiakini.com/announcement/682956',
                image:`zulunewprup1.jpg`
            },
            {
                heading:"EXPERIENCE ROBUST TRADING WITH AAAFX: YOUR TRUSTED PARTNER",
                description:`<p><a href='https://aaafx.com/global/' class='links-theme' target='_blank'>AAAFx</a> unwavering commitment to transparency and exceptional service has earned them the title of “Most Transparent Broker” at the UF AWARDS 2022 and “Best CFD Broker – MEA” at the UF AWARDS MEA 2023. With over 16 years of excellence, the broker operates in 176 countries, offering 500+ instruments, including 70 Forex pairs and CFDs on stocks, indices, commodities, and cryptocurrencies. AAAFx stands firm in its dedication to top-tier technological performance, trustworthiness, transparency, and superior service quality.</p>`,
                published_date:'October 11,2023',
                platform:'AAAFx',
                link:'https://financefeeds.com/experience-robust-trading-with-aaafx-your-trusted-partner/',
                image:`aaanewprup1.jpg`
            },
            {
                heading:"ZuluTrade Wins Best Social Trading Solution Award At UF AWARDS Global 2023",
                description:`<p>One of the largest and long-established social trading platforms, ZuluTrade, has won a prestigious award at the UF AWARDS Global 2023.</p><p><a href='https://www.zulutrade.com/?utm_source=news&utm_medium=pr&utm_campaign=zt_cyprus_award_23_benz' class='links-theme' target='_blank'>ZuluTrade</a>, the leading social and copy trading platform, has won the prestigious Best Social Trading Solution Award at UF AWARDS Global 2023.</p>`,
                published_date:'September 28,2023',
                platform:'ZuluTrade',
                link:'https://www.benzinga.com/pressreleases/23/09/34976214/zulutrade-wins-best-social-trading-solution-award-at-uf-awards-global-2023',
                image:`zuluprup1.webp`
            },
            {
                heading:`Fxview Triumphs As "Best Global Broker" At UF AWARDS Global 2023`,
                description:`<p><a href='https://fxview.com/?utm_source=article&utm_medium=referral&utm_campaign=awardUF' class='links-theme' target='_blank'>Fxview</a> has been recognised as the "Best Global Broker" by UF AWARDS Global. This prestigious award recognises Fxview's outstanding contributions to online trading and fintech, and it was bestowed by the event's organisers, Ultimate Fintech, after a reliable vote. The fact that Fxview has won this award shows how dedicated the company is to quality and innovation.</p>`,
                published_date:'September 27,2023',
                platform:'Fxview',
                link:'https://www.benzinga.com/fintech/23/09/34947883/fxview-triumphs-as-best-global-broker-at-uf-awards-global-2023',
                image:`fxvnewpr12up.webp`
            },
            {
                heading:`Capital Wallet Earns Prestigious "Best Crypto Solution for Payments" Accolade At UF AWARDS Global 2023`,
                description:`<p>Benzinga - Capital Wallet's triumph in securing the esteemed "BEST CRYPTO SOLUTION FOR PAYMENTS" award at UF AWARDS Global 2023 resonates powerfully with company’s unwavering commitment to revolutionising the way people engage with cryptocurrencies in the global payments landscape.</p>`,
                published_date:'September 27,2023',
                platform:'CapitalWallet',
                link:'https://uk.investing.com/news/cryptocurrency-news/capital-wallet-earns-prestigious-best-crypto-solution-for-payments-accolade-at-uf-awards-global-2023-3169850',
                image:`cwprup1.jpg`
            },
            {
                heading:"Fxview Triumphs as “Best Global Broker” at UF AWARDS Global 2023",
                description:`<p><a href='https://fxview.com/?utm_source=article&utm_medium=referral&utm_campaign=awardUF' class='links-theme' target='_blank'>Fxview</a> has been recognised as the "Best Global Broker" by UF AWARDS Global. This prestigious award recognises Fxview's outstanding contributions to online trading and fintech, and it was bestowed by the event's organisers, Ultimate Fintech, after a reliable vote. The fact that Fxview has won this award shows how dedicated the company is to quality and innovation.</p>`,
                published_date:'September 26,2023',
                platform:'Fxview',
                link:'https://www.financemagnates.com/thought-leadership/fxview-triumphs-as-best-global-broker-at-uf-awards-global-2023/',
                image:`fxvprup2.jpg`
            },
            {
                heading:"AAAFX WINS ‘BROKER OF THE YEAR’ AT UF AWARDS GLOBAL 2023",
                description:`<p>Multi-asset brokerage AAAFx has won ‘Broker of the Year’ at the UF AWARDS Global 2023 ceremony. The prestigious award was the latest recognition for the brokerage in what has been a productive year in terms of both operations and industry honours.</p>`,
                published_date:'September 25,2023',
                platform:'AAAFx',
                link:'https://financialit.net/news/infrastructure/aaafx-wins-broker-year-uf-awards-global-2023',
                image:`aaaprup1.jpg`
            },
            {
                heading:"ZuluTrade and YaMarkets Join Hands for an Enhanced User Experience",
                description:`<p><a href='https://www.zulutrade.com/select-broker?utm_source=news&utm_medium=pr&utm_campaign=yamarkets_fm_gl' class='links-theme' target='_blank'>ZuluTrade</a>, Finvasia group’s leading social trading platform, has teamed up with the well-regarded Forex and CFD broker, YaMarkets. This collaboration aims to set new standards of trading by tapping into the collective expertise of these two market intelligences.</p>`,
                published_date:'September 18,2023',
                platform:'ZuluTrade',
                link:'https://www.financemagnates.com/thought-leadership/zulutrade-and-yamarkets-join-hands-for-an-enhanced-user-experience/',
                image:`zuluprup2.png`
            },
            {
                heading:"Fxview Takes the Stage at IFX EXPO International as Elite Sponsor",
                description:`<p><a href='https://fxview.com/' class='links-theme' target='_blank'>Fxview</a>, a Forex and CFD industry leader that has made the headlines with its exclusive service offering and commitment to client service, announces its participation in the iFX EXPO International 2023. The broker is also the Elite Sponsor of the prestigious fintech event, securing a central position in front of the industry elite.</p>`,
                published_date:'September 14,2023',
                platform:'Fxview',
                link:'https://financialit.net/news/trading-systems/fxview-takes-stage-ifx-expo-international-elite-sponsor',
                image:`fxvnewprimg1.jpg`
            },
            {
                heading:"Socialise with ZuluTrade – Official Night Party Sponsor of iFX EXPO International 2023",
                description:`<p>Leading social copy trading platform, Finvasia Group’s <a href='https://www.zulutrade.com/?utm_source=news&utm_medium=pr&utm_campaign=zt_ifx_cyprus23_cyp_mail' class='links-theme' target='_blank'>ZuluTrade</a>, will be the Official Night Party Sponsor of the largest B2B fintech expo of the year, iFX EXPO International 2023. A relaxed gathering that brings busy professionals together after a day of networking, the iFX EXPO Night Party is known for its captivating entertainment and sociable atmosphere. What better time and place to get to know ZuluTrade better?</p>`,
                published_date:'AUGUST 30,2023',
                platform:'ZuluTrade',
                link:'https://cyprus-mail.com/2023/08/30/socialise-with-zulutrade-official-night-party-sponsor-of-ifx-expo-international-2023/',
                image:`zuluupdatednewpr2.jpg`
            },
            {
                heading:"FXVIEW POWERS TRADERS AND MONEY MANAGERS WITH MAM/PAMM ACCOUNTS",
                description:`<p>Driving venture administrations supplier and a piece of Finvasia Gathering, Fxview offers Multi-Record Supervisor (MAM) and Rate Portion The board Module (PAMM) projects to empower educated speculation and the administration regarding numerous records. The two sorts of records offer natural, dependable, and adaptable ways to improve the administration of speculation accounts, making them more effective.</p>`,
                published_date:'AUGUST 30,2023',
                platform:'Fxview',
                link:'https://financefeeds.com/fxview-powers-traders-and-money-managers-with-mam-pamm-accounts/',
                image:`fxvnewupdatedpr2.jpg`
            },
            {
                heading:"ZuluTrade Connects Traders with 1000+ Leading Brokers Worldwide",
                description:`<p>In today’s rapidly evolving trading landscape, traders seek out platforms that provide exposure to global markets, innovative tools and tailored trading experiences. <a href='https://www.zulutrade.com/pricing?utm_source=news&utm_medium=pr&utm_campaign=zt_connectraders_lap_latam' class='links-theme' target='_blank'>ZuluTrade</a>, the social trading platform part of Finvasia Group, has carved a unique path for itself, redefining the way traders access opportunities.</p>`,
                published_date:'AUGUST 23,2023',
                platform:'ZuluTrade',
                link:'https://latinamericanpost.com/45129-zulutrade-connects-traders-with-1000-leading-brokers-worldwide',
                image:`zuluupdatednewpr1.jpg`
            },
            {
                heading:"BREAKING: AAAFx Surpasses 1 Million Website Users",
                description:`<p>Forex and CFD brokerage <a href='https://aaafx.com/' class='links-theme' target='_blank'>AAAFx</a> has just announced it exceeded 1 million users in website traffic. This sets a new record for the multi-award-winning financial firm, considering that this number reflects the website traffic of only 1 month.</p>`,
                published_date:'AUGUST 22,2023',
                platform:'AAAFx',
                link:'https://www.financemagnates.com/thought-leadership/breaking-aaafx-surpasses-1-million-website-users/',
                image:`aaafxupdatednewpr1.jpg`
            },
            {
                heading:"Optimise Your Trading Strategy with ZuluTrade’s Powerful Trading Calculators",
                description:`<p>In today’s dynamic and ever-evolving financial markets, successful trading rests on a comprehensive understanding of risk management and well-informed decision-making. <a href='https://www.zulutrade.com/trading-tools?utm_source=news&utm_medium=pr&utm_campaign=trading_tools' class='links-theme' target='_blank'>ZuluTrade</a>, Finvasia’s innovative social trading platform, recognises the importance of equipping traders with the right tools to navigate these challenges.</p>`,
                published_date:'AUGUST 16,2023',
                platform:'ZuluTrade',
                link:'https://www.fxempire.com/news/article/optimise-your-trading-strategy-with-zulutrades-powerful-trading-calculators-1368429',
                image:`zuluupdatedprnew1.jpg`
            },
            {
                heading:"FXVIEW: REDEFINING CUSTOMER SERVICE EXCELLENCE IN ONLINE TRADING",
                description:`<p>In the brisk and competitive domain of Forex and CFD trading, identifying a broker genuinely focused on clients can be likened to finding a diamond in the rough. Amidst this environment, Fxview emerges as a beacon of excellence in customer service and satisfaction.</p>`,
                published_date:'AUGUST 16,2023',
                platform:'Fxview',
                link:'https://financefeeds.com/fxview-redefining-customer-service-excellence-in-online-trading/',
                image:`fxvupdatedpr1.jpg`
            },
            {
                heading:"AAAFx Introducing Broker Program: Growth Opportunities at Your Fingertips",
                description:`<p><a href='https://aaafx.com/' class='links-theme' target='_blank'>AAAFx</a>, a Forex and CFD industry leader reputed for its competitive trading conditions for EU and global traders, unveils a rich offering for Introducing Brokers under its global entity. Extremely flexible, the program is open to both seasoned traders and marketers with a solid network, allowing partners to capitalise on their connections and generate new income streams just by spreading the word about AAAFx’ services and cutting-edge platforms.</p>`,
                published_date:'AUGUST 10,2023',
                platform:'AAAFx',
                link:'https://www.fintechnews.org/aaafx-introducing-broker-program-growth-opportunities-at-your-fingertips/',
                image:`aaafxupdatedpr2.jpg`
            },
            {
                heading:"CREATING A SOCIAL TRADING PORTFOLIO WITH ZULUTRADE: A TECH-DRIVEN APPROACH",
                description:`<p>In the dynamic realm of social trading, <a href='https://www.zulutrade.com/?utm_source=news&utm_medium=pr&utm_campaign=tradingportfolio' class='links-theme' target='_blank'>ZuluTrade</a> introduces an innovative approach to constructing a well-rounded investment portfolio powered by technology.</p>`,
                published_date:'AUGUST 7,2023',
                platform:'ZuluTrade',
                link:'https://financefeeds.com/creating-a-social-trading-portfolio-with-zulutrade-a-tech-driven-approach/',
                image:`zulunewupdatedpr2.png`
            },
            {
                heading:"AAAFx: The advantages of trading in an ECN environment",
                description:`<p>Award-winning brokerage <a href='https://aaafx.com/' class='links-theme' target='_blank'>AAAFx</a> has made a name for itself thanks to its ECN (Electronic Communication Network) trading conditions. Offering a choice of ECN account types, AAAFx has built a trading environment that provides direct access to liquidity so that traders can benefit from the best bid and ask prices in the market. But how does ECN trading work?</p>`,
                published_date:'AUGUST 3,2023',
                platform:'AAAFx',
                link:'https://www.thesouthafrican.com/news/business-news-and-finance/forex-trading-the-advantages-of-trading-in-an-ecn-environment-zooming-in-on-aaafx-august-2023/?fbclid=IwAR0tWNimAKrUMVbw1iOn5q9NE0NmOWKCFUenqrvpEa65_IZ1YlNIeLn2EDs',
                image:`aaafxupdatedpr1.png`
            },
            {
                heading:"An Expert’s Guide to Trading: Market Movements and Identifying Trends with Fxview",
                description:`<p>Technical analysis is one of the most powerful weapons in a trader’s arsenal to grasp market opportunities. It allows you to predict price moves of the asset of your choice by studying past price patterns and current market conditions.</p>`,
                published_date:'August 01,2023',
                platform:'Fxview',
                link:'https://www.forexlive.com/Education/an-experts-guide-to-trading-market-movements-and-identifying-trends-with-fxview-20230801/?utm_source=dlvr.it&utm_medium=twitter',
                image:`fxviewupdatedpr1.png`
            },
            {
                heading:"ZuluTrade: Empowering Brokers with a Platform-agnostic Social Trading Solution",
                description:`<p>ZuluTrade empowers brokers to enhance trading experience for their clients through a powerful platform that hosts one of the largest social/copy trading communities.</p>`,
                published_date:'July 27,2023',
                platform:'ZuluTrade',
                link:'https://www.investing.com/studios/article-382394',
                image:`zulunewupdatedpr1.png`
            },
            {
                heading:"A Beginner’s Guide to Trading with Fxview",
                description:`<p>Fxview, offers comprehensive trader education on a wide range of financial instruments to support beginner traders.</p>`,
                published_date:'July 25,2023',
                platform:'Fxview',
                link:'https://www.forexlive.com/Education/a-beginners-guide-to-trading-with-fxview-20230725/',
                image:`fxviewnewpr4.jpg`
            },
            {
                heading:"DIVERSIFY YOUR MARKET EXPOSURE WITH AAAFX’S INDEX TRADING SERVICES",
                description:`<p>Explore the benefits of trading global indices like S&P 500, Nasdaq, Nikkei, and DAX 30, and why traders prefer AAAFx for its trader-first approach and commitment to protection.</p>`,
                published_date:'July 24,2023',
                platform:'AAAFx',
                link:'https://financefeeds.com/diversify-your-market-exposure-with-aaafxs-index-trading-services/',
                image:`aaafxnewpr5.jpg`
            },
            {
                heading:"Join Social and Copy Trading Leader ZuluTrade as It Continues Its World Tour",
                description:`<p><a class='links-theme' href='https://dailyforex.com/dr/pr_copytrading/zulutrade' target='_blank'>ZuluTrade</a> , the social and copy trading veteran, started 2023 with an exciting product pipeline and an equally exciting agenda packed with international events. <br> With a social and copy trading ecosystem designed to meet the needs of Investors, Leaders, Brokers and Affiliates in equal measure, the company has earned industry-wide recognition for creating a comprehensive and robust social trading environment that fosters growth and collaboration within the entire community.</p>`,
                published_date:'July 20,2023',
                platform:'ZuluTrade',
                link:'https://www.dailyforex.com/forex-figures/2023/07/join-leader-zulutrade-20-july-2023/198540',
                image:`zulutradenewpr1.jpeg`
            },
            {
                heading:"Virtual Private Server: What It Is and How to Use It with AAAFx",
                description:`<p>A lot of ink has been spilled on <a class='links-theme' href='https://aaafx.com/vps-hosting' target='_blank'>VPS hosting</a> and how to harness its potential. In trading, where every millisecond counts, efficiency and automation are critical to being successful. But how do traders incorporate efficiency and automation into their activity? It’s simple: with the right technology suite. When it comes to technology and VPS hosting, AAAFx ticks all the boxes.</p>`,
                published_date:'July 13,2023',
                platform:'AAAFx',
                link:'https://www.malaysiakini.com/announcement/671770',
                image:`aaafxnewpr4.jpg`
            },
            {
                heading:"A Sneak Peek Behind the Scenes of Fxview",
                description:`<p>Entering the financial services arena in 2017, <a class='links-theme' href='https://fxview.com/' target='_blank'>Fxview</a> is a leading Forex and CFD brokerage centred on innovation and building a unique trading experience through technology enablement. Part of the financial and technology conglomerate Finvasia Group, Fxview has witnessed spectacular growth in recent years. But growth never happens overnight, and who would have been better suited to shed light on the company’s success and future than Janis Anastassiou, Fxview’s Managing Director?  Prying her away from her hectic routine, today we sat down with Janis who revealed some of Fxview’s growth secrets and what lies ahead for the ambitious brokerage firm.</p>`,
                published_date:'July 11,2023',
                platform:'Fxview',
                link:'https://www.malaysiakini.com/announcement/671603',
                image:`fxviewnewpr3.jpg`
            },
            {
                heading:"ZuluTrade's Subscription Plan Page Now Live",
                description:`<p>Finvasia’s well-known social and copy trading leader ZuluTrade recently introduced a new <a class='links-theme' href='https://www.zulutrade.com/pricing?utm_source=news&utm_medium=pr&utm_campaign=subscriptionplan' target='_blank'>Subscription Plan</a> for copy traders. Free of transaction-based platform fees, it offers a transparent and more lucrative solution for users who can copy multiple Leader Strategies, depending on the number of slots included in their selected subscription package. Now, the dedicated Subscription Plan page is live on ZuluTrade’s website, providing users with all the necessary information.</p>`,
                published_date:'July 10,2023',
                platform:'ZuluTrade',
                link:'https://www.investing.com/studios/article-382386',
                image:`zuluprjuly10.jpg`
            },
            {
                heading:"Fxview Gears Up for Affiliate World Europe",
                description:`<p><a class='links-theme' href='https://dailyforex.com/dr/PR/fxview' target='_blank'>Fxview</a>, one of the pioneering Forex and CFD financial brands owned by Finvasia Group, is determined to bring more opportunities for affiliates worldwide. <br> From July 12 to 13, 2023, the Fxview team will make its presence noticed at the Affiliate World Europe conference at booth C21, Fira de Barcelona - the ultimate destination for affiliate marketers, ecommerce entrepreneurs and digital agencies. Schedule a meeting with Fxview <a class='links-theme' href='https://calendly.com/eventsfxview/affiliate-world-conferences-spain?month=2023-06' target='_blank'>here</a>.</p>`,
                published_date:'July 05,2023',
                platform:'Fxview',
                link:'https://www.dailyforex.com/forex-figures/2023/07/fxview-gears-up-5-july-2023/197833',
                image:`fxviewnewpr2.jpeg`
            },
            {
                heading:"AAAFx Rises Above Competition in Asia as the ‘Best CFD Broker - APAC’",
                description:`<p><a class='links-theme' href='https://aaafx.com/' target='_blank'>AAAFx</a>, an EU and South Africa-regulated Forex and CFD broker, has been crowned ‘Best CFD Broker - APAC’ at the UF AWARDS APAC 2023 ceremony. The company emerged victorious among the multitude of industry-leading participants in the ‘Broker Awards’ category.</p>`,
                published_date:'July 04,2023',
                platform:'AAAFx',
                link:'https://www.malaysiakini.com/announcement/670740',
                image:`aaafxnewpr3.jpg`
            },
            {
                heading:"ZuluTrade Strikes Partnership with Hextra Prime",
                description:`<p>As it continues to enjoy the spotlight, Finvasia’s leading social trading platform <a class='links-theme' href='https://www.zulutrade.com/?utm_source=news&utm_medium=pr&utm_campaign=zt_hextraprime' target='_blank'>ZuluTrade</a> makes waves in the media with its recent partnership announcement. The company is excited to announce its cooperation with industry-reputed Forex and CFD broker, Hextra Prime.</p>`,
                published_date:'July 03,2023',
                platform:'ZuluTrade',
                link:'https://www.fxempire.com/news/article/zulutrade-strikes-partnership-with-hextra-prime-1358693',
                image:`zuluprjuly3.jpg`
            },
            {
                heading:"ZuluTrade Crowned ‘Best Social Trading Solution - APAC’",
                description:`<p>A name that has held the front page for years with its innovative social trading platform, <a class='links-theme' href='https://www.zulutrade.com/?utm_source=news&utm_medium=pr&utm_campaign=ifx_apac_asia' target='_blank'>ZuluTrade</a> , is on an award-winning streak. The ‘Best Social Trading Solution - APAC’ from the UF AWARDS marks yet another important milestone for the company, which provides an all-inclusive financial ecosystem encompassing manual, social and copy trading, placing it in a stronger position across Asia-Pacific.</p>`,
                published_date:'June 26,2023',
                platform:'ZuluTrade',
                link:'https://www.malaysiakini.com/announcement/669992',
                image:`zuluTradenewpr4.jpg=s800`
            },
            {
                heading:"ZuluTrade Participates in iFX EXPO Asia 2023 as Diamond Sponsor",
                description:`<p><a class='links-theme' href='https://www.zulutrade.com/' target='_blank'>ZuluTrade</a> , a Finvasia Group company and provider of the world-leading social trading and wealth management platform, has announced its participation in iFX EXPO Asia as a Diamond Sponsor. iFX EXPO is the largest financial B2B expo in the world, with annual events organised across different regions. After winning the hearts and minds of industry leaders in the UAE, South Africa and Brazil, the ZuluTrade team is once again ready to impress attendees with its recently revamped Social Trading platform.</p>`,
                published_date:'June 19,2023',
                platform:'ZuluTrade',
                link:'https://www.malaysiakini.com/announcement/669277',
                image:`zuluTradenewpr3.jpg`
            },
            {
                heading:"Psicología comercial: Superar los sesgos emocionales para obtener mejores resultados al operar con Fxview",
                description:`<p>En el vertiginoso mundo comercial, los sesgos emocionales a menudo pueden impedir que los operadores alcancen su máximo potencial. El miedo, la codicia y el exceso de confianza son solo algunas de las emociones que pueden nublar el juicio y conducir a resultados desfavorables. Reconociendo la importancia de abordar estos sesgos, <a class='links-theme' href='https://fxview.com/' target='_blank'>Fxview</a> equipa a los comerciantes con las herramientas y los recursos necesarios para superar los obstáculos emocionales. Como corredor comprometido con la construcción de un ecosistema financiero global más seguro, Fxview tiene como objetivo empoderar a los comerciantes y fomentar el éxito a largo plazo al proporcionar materiales educativos integrales y características comerciales innovadoras.</p>`,
                published_date:'June 15,2023',
                platform:'Fxview',
                link:'https://tynmagazine.com/psicologia-comercial-superar-los-sesgos-emocionales-para-obtener-mejores-resultados-al-operar-con-fxview/',
                image:`fxviewnewpr1.jpg`
            },
            {
                heading:"ZuluTrade to Showcase Updated Affiliate Program at SiGMA Americas",
                description:`<p>ZuluTrade, a world-leading social trading and wealth management platform provider part of Finvasia Group, has announced its participation in SiGMA Americas, Brazil. The company will showcase its updated <a class='links-theme' href='https://www.zulutrade.com/become-an-affiliate/' target='_blank'>Affiliate Program</a> at the São Paulo edition of the iGaming conference. ZuluTrade’s representatives will be available at the Transamerica Expo Centre from June 15 to 17, 2023, at Stand Number C150.</p>`,
                published_date:'June 12,2023',
                platform:'ZuluTrade',
                link:'https://www.fxempire.com/news/article/zulutrade-to-showcase-updated-affiliate-program-at-sigma-americas-1353658',
                image:`zuluTradenewpr2.jpg`
            },
            {
                heading:"AAAFx Lays the Groundwork for Growth in Asia",
                description:`<p>With a legacy rooting back to 2007, <a class='links-theme' href='https://aaafx.com/'>AAAFx</a> creates a more prominent profile in Asia. Following a successful half-year tour including some of the most prestigious fintech events around the world, such as iFX EXPO Dubai in early January and more recently the Finance Magnates Africa Summit (FMAS:23), the financial firm is looking forward to the next big destination – iFX EXPO Asia 2023 in Thailand.</p>`,
                published_date:'June 12,2023',
                platform:'AAAFx',
                link:'https://www.fintechnews.org/aaafx-lays-the-groundwork-for-growth-in-asia/',
                image:`AAAFxnewpr2.jpg`
            },
            {
                heading:"AAAFx Sheds Light on the Role of Leverage in Forex and CFD Trading",
                description:`<p><a class='links-theme' href='https://www.aaafx.com/global'>AAAFx</a>, remains committed to empowering traders through education and creating a transparent trading environment that allows them to navigate financial opportunities at their own pace.</p>`,
                published_date:'June 08,2023',
                platform:'AAAFx',
                link:'https://www.malaysiakini.com/announcement/668012',
                image:`AAAFxnewpr1.jpg`
            },
            {
                heading:"ZULUTRADE FACILITATES STRATEGY DEVELOPERS IN OPENING NEW WEALTH AVENUES",
                description:`<p>Strategy Developers can enjoy a smoother user experience with <a class='links-theme' href='https://www.zulutrade.com/?utm_source=news&utm_medium=PR&utm_campaign=StrategyD' target='_blank'>ZuluTrade</a>’s reinforced social trading infrastructure. Thanks to its versatile interface design, the platform allows strategy developers to easily create, backtest, edit and deploy strategies, equipping them with everything they need to succeed.</p>`,
                published_date:'June 07,2023',
                platform:'ZuluTrade',
                link:'https://financialit.net/news/trading-systems/zulutrade-facilitates-strategy-developers-opening-new-wealth-avenues',
                image:`zuluTradenewpr1.jpg`
            },
            {
                heading:"Bulls Staring at 2023 to Uplift the Yellow Metal",
                description:`<p>Analysis by <a class='links-theme' href='https://fxview.com/' target='_blank'>Fxview</a>, a leading Forex and CFD brokerage part of Finvasia Group. Licensed by CySEC, the FSC and the FSCA, Fxview offers exposure to Forex and more than 200 CFDs on stock shares, indices, commodities and cryptocurrencies across its MT4, MT5 and ActTrader platforms. For more information about Fxview and its extensive offering, visit the <a class='links-theme' href='https://fxview.com/commodities' target='_blank'>website</a></p>`,
                published_date:'June 02,2023',
                platform:'Fxview',
                link:'https://www.malaysiakini.com/announcement/667311',
                image:`fxviewnewprimg2.jpg`
            },
            {
                heading:"CFDs Demystified: Understanding Contracts for Difference with Fxview",
                description:`<p>Multi-award winner ECN broker, <a class='links-theme' href='https://fxview.com/' target='_blank'>Fxview</a>, provides an overview of Contracts for Difference (CFDs) to enable traders to leverage their full potential.</p>`,
                published_date:'June 01,2023',
                platform:'Fxview',
                link:'https://www.investing.com/studios/article-382366',
                image:`fxviewnewprimg1.jpg`
            },
            {
                heading:"ZuluTrade’s Social Feed: Enhancing Users’ experience as Community Contributors",
                description:`<p>Despite its touted simplicity, social trading can pose a real challenge when it comes to following experienced and knowledgeable traders , especially for those who are just starting out. Finding the right information, keeping up with the latest market trends and price action can get overwhelming. That’s where the <a class='links-theme' href='https://www.zulutrade.com/?utm_source=news&utm_medium=PR&utm_campaign=SocialFeed'>ZuluTrade</a> Social Feed comes in.</p>`,
                published_date:'May 22,2023',
                platform:'ZuluTrade',
                link:'https://www.fxempire.com/news/article/zulutrades-social-feed-enhancing-users-experience-as-community-contributors-1348492',
                image:`zulunewpr2.jpg`
            },
            {
                heading:"Shoonya bets on Artificial Intelligence for trading",
                description:`<p><strong>NEW DELHI:</strong> Shoonya by Finvasia, the leading zero-brokerage trading platform on Wednesday announced that it is India’s first trading platform to offer AI-based predictions and signals for individual stocks. It has partnered with I Know First (IKF), a financial technology firm specializing in providing AI-powered stock market predictions, for the same.</p>`,
                published_date:'May 17, 2023',
                platform:'Shoonya',
                link:'https://timesofindia.indiatimes.com/business/startups/companies/shoonya-bets-on-artificial-intelligence-for-trading/articleshow/100298613.cms?from=mdr',
                image:`shoonyanewpr1.webp`
            },
            {
                heading:"Fxview Strengthens Its Footprint in South Africa",
                description:`<p>Finvasia Group’s innovative brokerage Fxview ramps up its offering in Africa. <br />Fxview gains more traction on Africa’s fintech scene by securing its license from the Financial Sector Conduct Authority (FSCA) of South Africa (Reg. No. 2018/303451/07- License No. 50410) earlier this year, allowing the broker to ramp up its efforts in the area.</p>`,
                published_date:'May 17, 2023',
                platform:'Fxview',
                link:'https://www.thesouthafrican.com/news/business-news-and-finance/trading-fxview-strengthens-its-footprint-in-south-africa-may-2023/',
                image:`fxvnewpr1.webp`
            },
            {
                heading:"AAAFX WINS ‘BEST FOREX SPREADS AFRICA’ AWARD",
                description:`<p>Building on its successful legacy, <a class='links-theme' href='https://aaafx.com/'>AAAFx</a> hogged the limelight at the Finance Magnates Africa Summit (FMAS:23) in Johannesburg, South Africa. </p>`,
                published_date:'May 17,2023',
                platform:'AAAFx',
                link:'https://financefeeds.com/aaafx-wins-best-forex-spreads-africa-award/',
                image:`AAAFxnewpr1.jpg`
            },
            // {
            //     heading:"ZuluTrade Receives ‘Best Wealth Management Platform Africa’ Award",
            //     description:`<p><a class='links-theme' href='https://www.zulutrade.com/?utm_source=news&utm_medium=PR&utm_campaign=FMAS23awards'>ZuluTrade</a> has been awarded the ‘Best Wealth Management Platform Africa’ by the Financial Achievements in Markets Excellency Awards.</p>`,
            //     published_date:'May 16, 2023',
            //     platform:'Finvasia',
            //     link:'https://www.forexlive.com/Education/zulutrade-receives-best-wealth-management-platform-africa-award-20230516/',
            //     image:`https://www.forexlive.com/Education/zulutrade-receives-best-wealth-management-platform-africa-award-20230516/`
            // },
            {
                heading:"<a class='links-theme' href='https://www.zulutrade.com/?utm_source=news&utm_medium=PR&utm_campaign=FMAS23awards'>ZuluTrade</a> Receives ‘Best Wealth Management Platform Africa’ Award",
                description:`<p><a class='links-theme' href='https://www.zulutrade.com/?utm_source=news&utm_medium=PR&utm_campaign=FMAS23awards'>ZuluTrade</a> has been awarded the ‘Best Wealth Management Platform Africa’ by the Financial Achievements in Markets Excellency Awards.</p>`,
                published_date:'May 16,2023',
                platform:'ZuluTrade',
                link:'https://www.forexlive.com/Education/zulutrade-receives-best-wealth-management-platform-africa-award-20230516/',
                image:`zulunewpr1.jpg`
            },
            {
                heading:"ZuluTrade Sponsors the Finance Magnates Africa Summit",
                description:`<p><a class='links-theme' href='https://www.zulutrade.com/?utm_source=news&utm_medium=mktnews&utm_campaign=2.0Launch'>ZuluTrade</a>, Finvasia Group’s pioneering social wealth management service provider, announced its participation and sponsorship of the Finance Magnates Africa Summit (FMAS:23) in Johannesburg, South Africa.The ZuluTrade team plans to showcase an array of innovative features added to its recently launched revamped platform at the summit.</p>`,
                published_date:'May 02,2023',
                platform:'ZuluTrade',
                link:'https://www.financemagnates.com/thought-leadership/zulutrade-sponsors-the-finance-magnates-africa-summit/',
                image:`zulunewsimg13.jpeg`
            },
            {
                heading:"Shoonya pays compensation totalling Rs 3 crore to traders after tech glitch",
                description:`From our last analysis as of 27 April 2023, we have received just below 700 disputes about the said incident, and 12% of these were duplicates.`,
                published_date:'May 01,2023',
                platform:'Shoonya',
                link:'https://economictimes.indiatimes.com/markets/expert-view/shoonya-pays-compensation-totalling-rs-3-crore-to-traders-after-tech-glitch/articleshow/99902817.cms?from=mdr',
                image:`shoonyanewsimg2.png`
            },
            // {
            //     heading:"ZULUTRADE REVAMPS PLATFORM FOR BETTER USER EXPERIENCE",
            //     description:`<p><a class='links-theme' href='https://www.zulutrade.com/?utm_source=news&utm_medium=mktnews&utm_campaign=2.0Launch'>ZuluTrade</a>, the social trading and wealth management platform owned by Finvasia Group, has released its enhanced Social Intelligence Platform. This comes as an upgrade of its existing infrastructure and brings a set of improvements, new features and functionalities aimed at simplifying the investing and copy trading experience.</p>`,
            //     published_date:'May 01,2023',
            //     platform:'ZuluTrade',
            //     link:'https://financefeeds.com/zulutrade-revamps-platform-for-better-user-experience/',
            //     image:`zulunewsimg14.jpg`
            // },
            {
                heading:"ZuluTrade Revs Up Its Platform for Enhanced User Experience",
                description:`<p>Finvasia Group’s social trading and investing firm <a href='https://www.zulutrade.com/?utm_source=news&utm_medium=mktnews&utm_campaign=2.0Launch' class='links-theme' target='_blank'>ZuluYTrade</a> has recently launched its enhanced Social Intelligence Platform. The innovation comes as an overhaul of its existing social trading and wealth management platform.</p>`,
                published_date:'Apr 27,2023',
                platform:'ZuluTrade',
                link:'https://www.financemagnates.com/thought-leadership/zulutrade-revs-up-its-platform-for-enhanced-user-experience/',
                image:`zulunewprimage13.jpg`
            },
            {
                heading:"AAAFx Is Off to the Finance Magnates Africa Summit",
                description:`<p>After enjoying the spotlight at iFX EXPO Dubai at the beginning of the year, AAAFx takes off to Johannesburg and the Finance Magnates Africa Summit (FMAS:23). From 8 to 10 May, the AAAFx team will be at Sandton Convention Centre, Ballroom 1, <strong>booth 58</strong>.</p>`,
                published_date:'Apr 26, 2023',
                platform:'AAAFx',
                link:'https://www.financemagnates.com/thought-leadership/aaafx-is-off-to-the-finance-magnates-africa-summit/',
                image:`aaafxnewsimg9.jpg`
            },
            {
                heading:"Fxview Sponsors the Finance Magnates Africa Summit",
                description:`<p>Fxview, one of Finvasia Group’s innovative brokerage brands, will be present at the Finance Magnates Africa Summit (FMAS:23) in Johannesburg, between 8 and 10 May. As the Registration Sponsor of the grand fintech event, Fxview will make its official entry on the South African market. Prospective and existing partners attending FMAS:23 will be able to meet the Fxview team at <strong>booth 5</strong>, Sandton Convention Centre, Ballroom 1.</p>`,
                published_date:'Apr 25, 2023',
                platform:'Fxview',
                link:'https://www.dailyforex.com/forex-figures/2023/04/fxview-sponsors-the-finance-magnates-africa-summit-25-april-2023/194601',
                image:`fxvnewimg12.jpeg`
            },
            {
                heading:"Finvasia Group Heads Off to the Finance Magnates Africa Summit",
                description:`<p>Finvasia Group announced its attendance at the Finance Magnates Africa Summit (FMAS:23) in Johannesburg. From 8 to 10 May, the global fintech giant and its brands will be showcasing their best products and services at FMAS:23, in the luxurious setting of Sandton Convention Centre, Ballroom 1.</p>`,
                published_date:'Apr 24, 2023',
                platform:'Finvasia',
                link:'https://www.forexlive.com/Education/finvasia-group-heads-off-to-the-finance-magnates-africa-summit-20230424/',
                image:`finvasianewimg6.jpg`
            },
            {
                heading:"Fxview: Protecting Traders All the Way",
                description:`<p>Fxview, a multi-regulated broker with a strong foothold in over 180 countries, upholds the strictest regulation and compliance protocols to ensure its clients can trade safely and confidently, regardless of their geographical location or level of experience.</p>`,
                published_date:'Apr 19, 2023',
                platform:'Fxview',
                link:'https://www.forexlive.com/Education/fxview-protecting-traders-all-the-way-20230419/',
                image:`fxviewnewsimg10.jpg`
            },
            {
                heading:"AAAFx Empowers Traders with Full-blown ECN Offering",
                description:`<p><a class='links-theme' href='https://aaafx.com/'>AAAFx</a>, a multi-asset regulated Forex and CFD broker with an active presence in over 176 countries, provides access to a comprehensive array of ECN accounts designed to satisfy any trader’s risk appetite and preferences.</p>`,
                published_date:'Apr 20, 2023',
                platform:'AAAFx',
                link:'https://www.forexlive.com/Education/aaafx-empowers-traders-with-full-blown-ecn-offering-20230420/',
                image:`aaafxnewsimg8.jpg`
            },
            {
                heading:"Finvasia-owned Shoonya broker issues clarification on tech glitch",
                description:`<p>After traders complained of bogus orders and massive losses following a tech glitch, Finvasia-run zero-broking platform <a class='links-theme' href='https://economictimes.indiatimes.com/topic/shoonya'>Shoonya</a> has clarified that the issue was only with the front end and not at the back end.</p>`,
                published_date:'Apr 14, 2023',
                platform:'Shoonya',
                link:'https://economictimes.indiatimes.com/markets/stocks/news/finvasia-owned-shoonya-broker-issues-clarification-on-tech-glitch/articleshow/99491865.cms?from=mdr',
                image:`shoonya-logo.svg`
            },
            {
                heading:"Zero-broking platform Finvasia hit by tech glitch, traders suffer losses",
                description:`<p>NEW DELHI: Traders using zero-broking platform <a class='links-theme' href='https://economictimes.indiatimes.com/topic/finvasia'>Finvasia</a> suffered losses today as a technical glitch barred them from squaring off existing positions.</p>`,
                published_date:'Apr 13, 2023',
                platform:'Shoonya',
                link:'https://economictimes.indiatimes.com/markets/stocks/news/zero-broking-platform-finvasia-hit-by-tech-glitch-traders-suffer-losses/articleshow/99462402.cms',
                image:`shoonya-logo.svg`
            },
            {
                heading:"Match-Prime and Fxview Forge Partnership to Enhance Trading Offerings",
                description:`<p>Match-Prime, <a class='links-theme' href=''>multi-assets</a> liquidity provider, has announced a strategic partnership with Fxview, a regulated Forex and CFD brokerage firm that is part of Finvasia Group. This collaboration aims to enhance the trading environment for retail traders while expanding Fxview's liquidity pool and strengthening Match-Prime's network of financial service providers.</p>`,
                published_date:'April 13, 2023',
                platform:'Fxview',
                link:'https://www.financemagnates.com/forex/match-prime-and-fxview-forge-partnership-to-enhance-trading-offerings/',
                image:`fxviewnewsimg9.jpg`
            },
            {
                heading:"ZuluGuard: A Better Way to Manage Risk in Social Trading",
                description:`<p>ZuluTrade, the novel social trading and social wealth management platform owned by Finvasia Group, reveals its “secret formula” for risk management in social trading. ZuluTrade’s advanced risk management tool, <a class='links-theme' href='https://www.zulutrade.com/autoprotect-your-account?utm_source=news&utm_medium=mktnews&utm_campaign=ZGuard'>ZuluGuard</a>, gives investors the flexibility to control their risk exposure by setting a few parameters that trigger an automated action.</p>`,
                published_date:'April 06, 2023',
                platform:'ZuluTrade',
                link:'https://www.financemagnates.com/thought-leadership/zuluguard-a-better-way-to-manage-risk-in-social-trading/',
                image:`zulunewsimg13.jpg`
            },
            {
                heading:"EXCLUSIVE: ZuluTrade’s Revamped Affiliate Program Is Now Live",
                description:`<p>ZuluTrade’s Affiliate Program offers new opportunities for website and blog owners, influencers, trading academies and professional traders</p>`,
                published_date:'MARCH 29, 2023',
                platform:'ZuluTrade',
                link:'https://www.forexlive.com/Education/exclusive-zulutrades-revamped-affiliate-program-is-now-live-20230329/',
                image:`zulunewprimage12.jpg`
            },
            {
                heading:"Fxview Puts Technology Enablement into Perspective",
                description:`<p>Fxview’s low latency technology facilitates lightning-fast trade execution.<br/>Fxview, a pioneering multi-asset broker owned by fintech conglomerate Finvasia Group, is transforming the Forex trading landscape from an engineering perspective.<br/>Fxview is on a mission to reshape the global financial ecosystem, by addressing the challenges facing traders today, such as wide spreads, high latency, slippage, and delays in order execution, deposits and withdrawals. The broker provides unique and tailored trading solutions to suit traders of all levels, focusing on technology and innovation. Fxview’s high-end, low-latency technology facilitates order execution in milliseconds, enabling its clients to benefit from the best possible trading conditions.</p>`,
                published_date:'MARCH 28, 2023',
                platform:'Fxview',
                link:'https://www.fxstreet.com/brokers/press-releases/press-release---20230327---fxview-puts-technology-enablement-into-perspective',
                image:`fxviewnewsimg8.jpg`
            },
            {
                heading:"ZuluTrade Launches Operations in Mauritius",
                description:`<p>Social trading leader <a class='links-theme' href='https://www.zulutrade.com/'>ZuluTrade</a> expands its offering in the market with the addition of wealth management services. As part of the Finvasia Group of companies, ZuluTrade receives the approval of the Financial Services Commission (FSC) of Mauritius (Licence No. IK21000018) to provide wealth management services.</p>`,
                published_date:'MARCH 23, 2023',
                platform:'ZuluTrade',
                link:'https://www.financemagnates.com/thought-leadership/zulutrade-launches-operations-in-mauritius/',
                image:`zulunewsimg12.jpg`
            },
            {
                heading:"BREAKING: Fxview Sponsors the Cyprus National Karate Team",
                description:`<p>Fxview announced that it will sponsor the National Karate Team of Cyprus at the European Karate Championships for Seniors held in Guadalajara, Spain between 22 and 26 March 2023. With this sponsorship, Fxview aims to raise awareness about the importance of cultivating self-discipline, leadership and, above all, confidence in the young generation. It is the first time that the global financial industry leader steps outside the realm of trading and investing, in an effort to support the development of the young community of Cyprus, where the company is headquartered.</p>`,
                published_date:'MARCH 21, 2023',
                platform:'Fxview',
                link:'https://theindustryspread.com/breaking-fxview-sponsors-the-cyprus-national-karate-team/',
                image:`news-fxview.png`
            },
            {
                heading:"ZuluTrade Pushes the Boundaries of Social Trading",
                description:`<p>Considering its accessibility and the smooth investing experience it offers, the social trading sector has witnessed an exponential rise in the past decade and a half. According to a recent report by market research platform ResearchAndMarkets.com, “Social Trading Platform Market Forecast to 2028 - COVID Impact and Global Analysis By Platform, End User and Asset Class”, the social trading market is expected to hit $3,774.17 million by 2028.</p>`,
                published_date:'MARCH 15, 2023',
                platform:'ZuluTrade',
                link:'https://www.forexlive.com/Education/zulutrade-pushes-the-boundaries-of-social-trading-20230315/',
                image:`zulunewsimg11.jpg`
            },
            {
                heading:"ZuluTrade Teams Up with X-Open Hub Brokerage FXCentrum",
                description:`<p><a href='https://www.zulutrade.com/?utm_source=News&utm_medium=online&utm_campaign=FXCentrum&utm_id=ZT' class='links-theme' target='_blank'>ZuluTrade</a>, the award-winning Finvasia Group social wealth management and social trading platform, has announced a new partnership with FxCentrum, a Seychelles based innovative brokerage firm.</p>`,
                published_date:'MARCH 09, 2023',
                platform:'ZuluTrade',
                link:'https://www.financemagnates.com/thought-leadership/zulutrade-teams-up-with-x-open-hub-brokerage-fxcentrum/',
                image:`zulunewprimage11.jpg`
            },
            {
                heading:"FXVIEW SECURES FSCA LICENCE",
                description:`<p>Fxview, a global Forex and CFD brokerage part of Finvasia Group’s financial ecosystem, is now fully licensed by the Financial Sector Conduct Authority (FSCA) in South Africa.</p>`,
                published_date:'MARCH 06, 2023',
                platform:'Fxview',
                link:'https://financefeeds.com/fxview-secures-fsca-licence/',
                image:`fxviewnewsimg7.jpg`
            },
            {
                heading:"What Makes AAAFx a Trusted Broker?",
                description:`<p>AAAFx, a trusted Forex and CFD brokerage part of Finvasia Group, hogs the media spotlight yet again. Entering the financial arena in 2008, AAAFx has demonstrated that being traditional means far more than gingerly tinkering around the edges of the past but rather being true to a solid set of values and mandates that most financial service providers today aim for.</p>`,
                published_date:'FEBRUARY 28, 2023',
                platform:'AAAFx',
                link:'https://www.forexlive.com/Education/what-makes-aaafx-a-trusted-broker-20230228/',
                image:`aaafxnewsimg7.jpg`
            },
            {
                heading:"Paving the Way for Cost-effective Trading",
                description:`<p>Fxview, a popular FX and CFD brand of Finvasia Group, takes a stand on cost-effective trading. Committed to delivering the best experience to all traders, no matter their investing potential or knowledge of the financial markets, Fxview is one of the industry pioneers that pave the way for equitable and transparent trading.</p>`,
                published_date:'FEBRUARY 22, 2023',
                platform:'Fxview',
                link:'https://www.fxempire.com/news/article/paving-the-way-for-cost-effective-trading-1291310',
                image:`fxviewnewsimg6.jpg`
            },
            {
                heading:"Putting Traders in the Driver’s Seat",
                description:`<p>Following its remarkable success as a financial service provider of the last few years, AAAFx becomes more prominent across the industry, thanks to the unparalleled trading conditions it offers to its clients. Apart from the competitive pricing and tight spreads, the Forex and CFD leader distinguishes itself by providing the highest standards of client fund security.</p>`,
                published_date:'FEBRUARY 21, 2023',
                platform:'AAAFx',
                link:'https://theindustryspread.com/putting-traders-in-the-drivers-seat/',
                image:`aaafx-logo.png`
            },
            {
                heading:"EXCLUSIVE: ZULUTRADE INTEGRATES METATRADER5 AND ACTTRADER",
                description:`<p>In an effort to create a comprehensive trading and investing environment for individuals and businesses seeking to explore the financial markets, the world’s leading social trading platform, ZuluTrade, announces integration with popular trading solutions MT5 and ActTrader.</p>`,
                published_date:'FEBRUARY 16, 2023',
                platform:'ZuluTrade',
                link:'https://financefeeds.com/pr/exclusive-zulutrade-integrates-metatrader5-and-acttrader/',
                image:`zulunewprimage2.jpg`
            },
            {
                heading:"Exclusive: ZuluTrade Expands in Japan",
                description:`<p><a class='links-theme' target='_blank' href='https://www.zulutrade.com/'>ZuluTrade</a>, the social trading platform that not so long ago rocked the stage in Dubai, winning the “Best Social Trading Solution - MEA” at the UF AWARDS MEA 2023, enjoys the spotlight of the media again with its announcement to expand its operations in Japan.</p>`,
                published_date:'FEBRUARY 16, 2023',
                platform:'ZuluTrade',
                link:'https://www.financemagnates.com/thought-leadership/exclusive-zulutrade-expands-in-japan/',
                image:`zulunewprimage1.jpg`
            },
            {
                heading:"AAAFx: Driving Success Through a Holistic Approach to Trading",
                description:`<p>With an unpalatable array of technology products, client service, and an impressive asset range, the innovative financial service provider counts among the industry disruptors, not only thanks to its modern value proposition but also its holistic approach to the provision of financial services, by constantly tailoring its offering to the target market.<br />Established in 2008, AAAFx has managed to create a unique identity in the industry by creating a safe and transparent environment for traders of all levels to test and build their trading strategies effectively.</p>`,
                published_date:'FEBRUARY 09, 2023',
                platform:'AAAFx',
                link:'https://www.forexlive.com/Education/aaafx-driving-success-through-a-holistic-approach-to-trading-20230209/',
                image:`aaafxnewsimg6.jpg`
            },
            {
                heading:"Fxview Partner Program: A Perfect Place to Grow",
                description:`<p>Fxview affiliates can take advantage of a hybrid remuneration scheme, allowing them to earn both CPA fees and generate up to 70% in commission based on the trading activity of their referrals.</p>`,
                published_date:'FEBRUARY 06, 2023',
                platform:'Fxview',
                link:'https://www.financemagnates.com/thought-leadership/fxview-partner-program-a-perfect-place-to-grow/',
                image:`fxviewnewsimg5.jpg`
            },
            {
                heading:"ZULUTRADE ADDS ONE MORE AWARD TO ITS COLLECTION: “BEST SOCIAL TRADING SOLUTION – MEA” 2023",
                description:`<p>The award received at the UF AWARDS MEA 2023 Ceremony marks yet another milestone for the company, which celebrated 15 years of excellence last year.</p>`,
                published_date:'FEBRUARY 06, 2023',
                platform:'ZuluTrade',
                link:'https://financefeeds.com/pr/zulutrade-adds-one-more-award-to-its-collection-best-social-trading-solution-mea-2023/',
                image:`zulunewsimg9.jpg`
            },
            {
                heading:"Fxview Crowned “Best ECN/STP Broker” at UF AWARDS MEA 2023",
                description:`<p>Fxview, a leading multi-asset global brokerage headquartered in Cyprus, just won the “Best ECN/STP Broker” award this year at the UF AWARDS MEA 2023! One of the most coveted awards in the finance and fintech industry, the UF Awards followed iFX EXPO Dubai, on January 18, 2023, at the Orange Feels Bar of Hotel Indigo Dubai Downtown. The esteemed audience applauded the best B2B and B2C brands in the Middle East and Africa region.</p>`,
                published_date:'January 31, 2023',
                platform:'Fxview',
                link:'https://www.fxempire.com/news/article/fxview-crowned-best-ecn-stp-broker-at-uf-awards-mea-2023-1269911',
                image:`fxnewsimg3.png`
            },
            {
                heading:"Capital Wallet Wins “Most Outstanding Innovator in Crypto Payments” at UF AWARDS MEA 2023",
                description:`<p>Leading crypto platform, <a class='links-theme' href='https://www.financemagnates.com/thought-leadership/capital-wallet-wins-most-outstanding-innovator-in-crypto-payments-at-uf-awards-mea-2023/'>Capital Wallet</a> won the award for the Most Outstanding Innovator in Crypto Payments in the MEA (Middle East and Africa) region, at the UF Awards ceremony following iFX EXPO Dubai 2023.</p>`,
                published_date:'January 30, 2023',
                platform:'CapitalWallet',
                link:'https://www.financemagnates.com/thought-leadership/capital-wallet-wins-most-outstanding-innovator-in-crypto-payments-at-uf-awards-mea-2023/',
                image:`cwnewsimg3.jpg`
            },
            {
                heading:"ZuluTrade Strengthens Reach in the Middle East",
                description:`<p>The leading social and copy trading platform, <a class='links-theme' href=''>ZuluTrade</a>, has successfully broadened its reach in the Middle East, with an increase in the average session duration on its website of almost 40% in the MENA region. The firm has established its presence in the area by setting up an office in Dubai's free trade zone.</p>`,
                published_date:'January 09, 2023',
                platform:'ZuluTrade',
                link:'https://www.financemagnates.com/thought-leadership/zulutrade-strengthens-reach-in-the-middle-east/',
                image:`zulunewsimg8.jpg`
            },
            {
                heading:"Fxview: Leading the Forex Industry with Lowest Cost Trading",
                description:`<p>Fxview ensures the lowest cost trading for every type of trader through ultra-low commissions, the lowest spreads, zero-cost funding, and more.<br />Leading online multi-asset brokerage and part of global fintech conglomerate Finvasia, Fxview, takes pride in ensuring the lowest cost trading for all its clients. The company is on a mission to revolutionise the forex industry through ethical, transparent and low-cost products and services. To achieve this, the multi-regulated broker offers a plethora of options, such as unbeatable ultra-low commissions, as low as $1/$100,000, which remain constant across all product types.</p>`,
                published_date:'DECEMBER 19, 2022',
                platform:'Fxview',
                link:'https://theindustryspread.com/fxview-leading-the-forex-industry-with-lowest-cost-trading/',
                image:`news-fxview.png`
            },
            {
                heading:"Why Clients Should Always Be Prioritised: Fxview's Take on It",
                description:`<p>Global, multi-asset broker, Fxview, believes that a client-first approach is indispensable for brokers to attract and retain clients, and future-proof brokerages.</p>`,
                published_date:'DECEMBER 12, 2022',
                platform:'Fxview',
                link:'https://www.financemagnates.com/thought-leadership/why-clients-should-always-be-prioritised-fxviews-take-on-it/',
                image:`fxviewnewsimg4.jpg`
            },
            {
                heading:"The Finvasia Group launches the All-New Shoonya",
                description:`<p>Mohali (Punjab) [India], December 9 (ANI/BusinessWire India): The Finvasia group today announced the transition of Shoonya–their Zero Commission trading platform, to its own domain. As a result, all of Shoonya’s services will migrate from the existing domain (Finvasia.com) to Shoonya’s new home on the web–<a class='links-theme' href='https://www.shoonya.com/'>Shoonya.com</a> on 9th December 2022. It is also introducing an all-new Shoonya App with exciting trading features like never before.</p>`,
                published_date:'DECEMBER 09, 2022',
                platform:'Shoonya',
                link:'https://theprint.in/ani-press-releases/the-finvasia-group-launches-the-all-new-shoonya/1257683/',
                image:`shoonya-logo.svg`
            },

            {
                heading:"Capital Wallet Wins Best Crypto Solution for Payments Award at FMLS22",
                description:`<p>Global cryptocurrency payments solutions provider, <a class='links-theme' href='https://www.financemagnates.com/thought-leadership/capital-wallet-wins-best-crypto-solution-for-payments-award-at-fmls22/'>Capital Wallet</a>, bagged the “Best Crypto Solution for Payments” award at the Finance Magnates London Summit (FMLS), held at Old Billingsgate, London, on November 21-23, 2022.</p>`,
                published_date:'DECEMBER 01, 2022',
                platform:'CapitalWallet',
                link:'https://www.financemagnates.com/thought-leadership/capital-wallet-wins-best-crypto-solution-for-payments-award-at-fmls22/',
                image:`cwnewsimg2.jpg`
            },
            {
                heading:"AAAFX ATTENDS FINANCE MAGNATES LONDON SUMMIT 2022",
                description:`<p>Leading global multi-asset broker, AAAFx, participated at the Finance Magnates London Summit 2022 as the Sweet Spot Sponsor, Water Sponsor and Exhibitor</p>`,
                published_date:'DECEMBER 01, 2022',
                platform:'AAAFx',
                link:'https://financefeeds.com/pr/aaafx-attends-finance-magnates-london-summit-2022/',
                image:`aaafxnewsimg5.jpg`
            },
            {
                heading:"AAAFx’s Journey to Recognition in the Forex Industry",
                description:`<p>Starting its journey in 2007, AAAFx has gone from strength to strength. Thanks to its unmatched service transparency, the broker has received some of the highest-ranking awards multiple times, securing an industry-leading position.</p>`,
                published_date:'NOVEMBER 28, 2022',
                platform:'AAAFx',
                link:'https://www.financemagnates.com/thought-leadership/aaafxs-journey-to-recognition-in-the-forex-industry/',
                image:`aaafxnewsimg4.jpg`
            },
            {
                heading:"ZULUTRADE ANNOUNCES IT WILL DROP PROFIT-SHARING FEES FROM INVESTORS’ ACCOUNTS",
                description:`<p>Undertaking a major change in its leaders’ compensation model, ZuluTrade has announced that it will drop the profit-sharing fees from the followers’ accounts.</p>`,
                published_date:'NOVEMBER 24, 2022',
                platform:'ZuluTrade',
                link:'https://financefeeds.com/pr/zulutrade-announces-it-will-drop-profit-sharing-fees-from-investors-accounts/',
                image:`zulunewsimg7.jpg`
            },
            {
                heading:"Finvasia Group opens a new office in India's Financial Capital, Mumbai",
                description:`<p><a class='links-theme' href='https://www.aninews.in/topic/mumbai'>Mumbai</a> (Maharashtra) [India], November 22 (ANI/BusinessWire India): <a class='links-theme' href='/topic/finvasia-group'>Finvasia Group</a> today announced the opening of its <a class='links-theme' href='https://www.aninews.in/topic/new-office'>new office</a> in <a class='links-theme' href='https://www.aninews.in/topic/indias-financial-capital'>India's Financial Capital</a>, <a class='links-theme' href='https://www.aninews.in/topic/mumbai'>Mumbai</a>. Finvasia currently has its Head Office in Sector 74, Mohali, Punjab. The new Corporate office has been inaugurated and is already operational at One BKC, G BLOCK, Bandra Kurla Complex, <a class='links-theme' href='https://www.aninews.in/topic/mumbai'>Mumbai</a>, India.</p>`,
                published_date:'NOVEMBER 22, 2022',
                platform:'Finvasia',
                link:'https://www.aninews.in/news/business/business/finvasia-group-opens-a-new-office-in-indias-financial-capital-mumbai20221122104050/',
                image:`finvasianewimg1.jpg`
            },
            {
                heading:"ZuluTrade Launches Integrated Broker Partnerships",
                description:`<p><a class='links-theme' href='https://www.zulutrade.com/'>Zulutrade</a>, the oldest social wealth management platform, has launched Integrated Broker Partnerships. The added feature is the first of its kind in the social trading space. The landmark integration will simplify the trading journey for investors and contribute towards a broker agnostic ecosystem that enhances the overall user experience.’</p>`,
                published_date:'NOVEMBER 14, 2022',
                platform:'ZuluTrade',
                link:'https://www.financemagnates.com/thought-leadership/zulutrade-launches-integrated-broker-partnerships/',
                image:`zulunewsimg6.jpg`
            },
            {
                heading:"Completing 15 Successful Years in the Industry - AAAFx's Take on the Future",
                description:`<p>Launched in Dec 2007, AAAFx – the pioneering brokerage company, is completing 15 years of providing reliable and excellent services to financial market participants.<br /> AAAFx was the first CFD broker to get a regulation certification from HCMC and it has been instrumental in empowering its clients over the years to make well-informed decisions by establishing full transparency in the trading environment. The company has stood the test of time and is proud to be one of the most trusted brokers in the forex market, serving retail as well as institutional clients for over 15 years now.</p>`,
                published_date:'NOVEMBER 8, 2022',
                platform:'AAAFx',
                link:'https://financefeeds.com/pr/completing-15-successful-years-in-the-industry-aaafxs-take-on-the-future/',
                image:`aaafxnewsimg3.jpg`
            },
            {
                heading:"Fxview strengthens its offerrings, adds more cryptocurrencies to its deposit and withdrawal methods for global clients",
                description:`<p>As a latest addition, Fxview added Ethereum (ETH), USDC and USDT to the already offered BTC as a payment option. Fxview‘s clients will now be able to take advantage of transaction speed, ease of payment and blockchain security. “We’ve seen an increase in the demand for crypto deposit and withdrawal options in the past few months, which motivated us to increase the options. When compared to the other payment methods, cryptocurrency is an effective payment option that also offers transactional freedom and is unbounded by geographic limits.” said Janis Anastassiou.</p>`,
                published_date:'NOVEMBER 7, 2022',
                platform:'Fxview',
                link:'https://financefeeds.com/pr/fxview-strengthens-its-offerings-adds-more-cryptocurrencies-to-its-deposit-and-withdrawal-methods-for-global-clients/',
                image:`fxviewnewsimg3.jpg`
            },
            {
                heading:"ZULUTRADE GROWTH PLAN DOES NOT INCLUDE UNLICENSED BROKERS",
                description:`<p>ZuluTrade has announced that it will continue its growth in the B2B space with licensed brokers only, terminating agreements that fail to pass its compliance curation process as a part of its new transition to ZuluTrade 2.0.</p>`,
                published_date:'NOVEMBER 4, 2022',
                platform:'ZuluTrade',
                link:'https://financefeeds.com/pr/zulutrade-growth-plan-does-not-include-unlicensed-brokers/',
                image:`zulunewsimg5.jpg`
            },
            {
                heading:"FXVIEW CELEBRATES “BEST GLOBAL CFD BROKER” AWARD",
                description:`<p>Fxview, a Finvasia group company, has won the award for “Best Global CFD Broker” at the Forex Expo Dubai, held on 19-20 October, 2022. This recognition marks the third award for the broker within a year, following the win as “The Most Trusted Broker” at the Ultimate Fintech Awards in June 2022 and “The Best Value Broker” at Forex Expo Dubai in 2021.</p>`,
                published_date:'NOVEMBER 2, 2022',
                platform:'Fxview',
                link:'https://financefeeds.com/pr/fxview-celebrates-best-global-cfd-broker-award/',
                image:`fxviewnewsimg1.jpg`
            },
            {
                heading:"ZuluTrade wins ‘Best Social Wealth Management Platform’ award at the Forex Expo Dubai",
                description:`<p>Athens, Greece: ZuluTrade, a Finvasia Group company, has won the ‘Best Social Wealth Management Platform’ award at the Forex Expo Dubai.<br />ZuluTrade was one of the handful booths to be visited by the Chief Guest, His Excellency Mr Buti Saeed Al Ghandi (Director, Commercial Bank of Dubai & Vice-Chairman, DWTC).<br />The award marks yet another milestone for ZuluTrade who has been championing the Social Trading and Investments industry for over 15 years. It is the 2nd consecutive award for the company in a span of less than six months after it was awarded ‘Best Social Trading Solution’ in Cyprus.</p>`,
                published_date:'November 2, 2022',
                platform:'ZuluTrade',
                link:'https://www.zawya.com/en/press-release/companies-news/zulutrade-wins-best-social-wealth-management-platform-award-at-the-forex-expo-dubai-flfeodam',
                image:`zulunewsimg2.webp`
            },
            {
                heading:"AAAFx Wins “Best FX Service Provider” at Forex Expo Dubai 2022",
                description:`<p>AAAFx, a leading brokerage in CFD trading and related services, has been recognised as the Best FX Service Provider at the world’s largest Forex trading event, Forex Expo Dubai, held on October 19-20, 2022. The win is a prestigious moment for AAAFx, given that the pioneering brokerage is celebrating its 15 illustrious years in the forex industry.</p>`,
                published_date:'November 02, 2022',
                platform:'AAAFx',
                link:'https://www.financemagnates.com/thought-leadership/aaafx-wins-best-fx-service-provider-at-forex-expo-dubai-2022/',
                image:`aaafxnewsimg2.jpg`
            },
            {
                heading:"Capital Wallet Wins Best Crypto Payment Solutions Provider Award at Forex Expo Dubai",
                description:`<p>Leading provider of cryptocurrency payment solutions for merchants, traders and transactions, <a class='links-theme' href='https://capitalwallet.com/'>Capital Wallet</a>, was recognised as the “Best Crypto Payment Solutions Provider” at the Forex Expo, held on October 19-20, 2022, at the World Trade Centre, Dubai. This is a huge win for the company, since the awards are based on votes by industry peers.</p>`,
                published_date:'October 31, 2022',
                platform:'CapitalWallet',
                link:'https://www.financemagnates.com/thought-leadership/capital-wallet-wins-best-crypto-payment-solutions-provider-award-at-forex-expo-dubai/',
                image:`cwnewsimg1.jpg`
            },
            // {
            //     heading:"ZuluTrade Promotes Efstathios Xenos as Vice President of Growth",
            //     description:`<p>ZuluTrade, a popular copy <a class='links-theme' href='https://www.financemagnates.com/terms/t/trading-platform/'>trading platform</a> owned by the Finvasia Group, has promoted Efstathios Xenos as the Vice President of Growth. He was previously the company’s Head of the Institutional Department since his joining in January 2022.</p>`,
            //     published_date:'September 21,2022',
            //     platform:'ZuluTrade',
            //     link:'https://www.financemagnates.com/executives/moves/zulutrade-promotes-efstathios-xenos-as-vice-president-of-growth/',
            //     image:`zulunewsimg4.jpg`
            // },
            // {
            //     heading:"ZuluTrade Appoints 3 Executives to Drive its Global Growth Plan",
            //     description:`<p>ZuluTrade, a Finvasia Group company, has promoted a selected trio into its ranks as part of a commitment to scale its global operations and human capital growth.</p>`,
            //     published_date:'September 15,2022',
            //     platform:'ZuluTrade',
            //     link:'https://amp.fxempire.com/en/zulutrade-appoints-3-executives-to-drive-its-global-growth-plan/1126499',
            //     image:`zulunewsimg3.jpg`
            // },
            {
                heading:"ZuluTrade Named as the Official Global Partner at iFX EXPO ASIA 2022 in Bangkok",
                description:`<p>ZuluTrade, part of the Finvasia Group, will be at iFX EXPO Asia 2022 as the Official Global Partner for the event. The company aspires to become the world’s largest social trading community, offering customer-focused investment solutions and an open environment, where investors on a global level can connect to any trading platform and share their knowledge.                </p>`,
                published_date:'September 12,2022',
                platform:'ZuluTrade',
                link:'https://www.financemagnates.com/thought-leadership/zulutrade-named-as-the-official-global-partner-at-ifx-expo-asia-2022-in-bangkok/',
                image:`zulunewsimg2.jpg`
            },
            {
                heading:"Fxview Named ‘Most Trusted Broker’ at the Ultimate Fintech Awards",
                description:`<p>Leading Cyprus-based trading company, Fxview, turned heads at this year’s Ultimate Fintech Awards, topping the Most Trusted Broker category. As part of the fintech conglomerate, FINVASIA Group, Fxview has been making waves in the forex industry by creating low spreads and commission levels compared to the rest of the industry.</p>`,
                published_date:'June 27,2022',
                platform:'Fxview',
                link:'https://www.financemagnates.com/thought-leadership/fxview-named-most-trusted-broker-at-the-ultimate-fintech-awards/',
                image:`fxviewnewsimg2.jpg`
            },
            {
                heading:"CySEC sounds regulation alarm on clones of Fxview",
                description:`<p>The Cyprus Securities and Exchange Commission has once again stepped up its fight against unauthorized brokers.<br />The Cypriot regulator today published a warning against a slew of unlicensed providers, whose domains were just blacklisted for facilitating trading in FX and cryptocurrencies without being authorized to do so in the country.</p>`,
                published_date:'June 17,2022',
                platform:'Fxview',
                link:'https://financefeeds.com/cysec-sounds-regulation-alarm-on-fxview-clone-of-octafx/',
                image:`news-fxview.png`
            },
            {
                heading:"AAAFx Set to Unveil Zero Commission ECN Plus Account at the iFX EXPO International 2022",
                description:`<p>The Pioneering Greek brokerage, AAAFx looks set to revamp its product offerings as part of their mission to continue delivering quality services to their expanding clientele. The international broker will also be offering lower commissions fees for indices, commodities, and stocks. This will be made possible thanks to the new ECN Plus account system that AAAFx will be offering.</p>`,
                published_date:'June 08,2022',
                platform:'AAAFx',
                link:'https://www.financemagnates.com/thought-leadership/aaafx-set-to-unveil-zero-commission-ecn-plus-account-at-the-ifx-expo-international-2022/',
                image:`aaafxnewsimg1.jpg`
            },
            {
                heading:"Capital Wallet Announces Two Significant Leadership Changes",
                description:`<p>Leading crypto solution provider, <a class='links-theme' href='https://capitalwallet.com/'>Capital Wallet</a>, has announced two new executive appointments. The Estonia-based company, which is a FINVASIA Group alliance, welcomes fintech specialist Fotis Fotinias as the new Capital Wallet CEO and technology scaling expert Abha Garg as Executive Director.</p>`,
                published_date:'June 08,2022',
                platform:'CapitalWallet',
                link:'https://capitalwallet.com/',
                image:`cwnewpr1.jpg`
            },
            {
                heading:"Finvasia’s Expansion into Europe",
                description:`<p>As Finvasia continues to grow, they are constantly looking for opportunities to become licensed and regulated in every market they enter.</p>`,
                published_date:'June 07,2022',
                platform:'Finvasia',
                link:'https://www.forexlive.com/Education/finvasias-expansion-into-europe-20220607/',
                image:`finvasianewimg5.jpg`
            },
            {
                heading:"Finvasia Group Appoints Tajinder Virk as Interim CEO of ZuluTrade",
                description:`<p>ATHENS, Greece--(<a class='links-theme' href='https://www.businesswire.com/'>BUSINESS WIRE</a>)--Finvasia today announced that it has appointed Tajinder Virk as the interim Chief Executive Officer for ZuluTrade. He will replace the previous CEO from the company, effective immediately.</p>`,
                published_date:'April 18, 2022 ',
                platform:'ZuluTrade',
                link:'https://www.businesswire.com/news/home/20220418005251/en/Finvasia-Group-Appoints-Tajinder-Virk-as-Interim-CEO-of-ZuluTrade',
                image:`zulutrade-logo.svg`
            },
            {
                heading:"ZuluTrade Eyes Expansion in 2022, Seeks More Regulatory Licenses",
                description:`<p>ZuluTrade, which was <a class='links-theme' href='https://www.financemagnates.com/forex/brokers/finvasia-acquires-social-trading-platform-zulutrade/'>acquired by Finvasia</a> last month, revealed on Friday that it is planning for expansion this year and adding new capabilities to its existing social trading platform.</p>`,
                published_date:'January 21,2022',
                platform:'ZuluTrade',
                link:'https://www.financemagnates.com/forex/products/zulutrade-eyes-expansion-in-2022-seeks-more-regulatory-licenses/',
                image:`zulunewsimg1.jpg`
            },
            {
                heading:"Punjab Chief Minister Charanjit Singh Channi inaugurates virtual hospital",
                description:`<p>Commending Sarvjeet Singh Virk, founder of Finvasia, and Gurjot Singh Narwal, CEO of Gini Health, for their innovative customised healthcare solutions, the Chief Minister said the technology had made remote monitoring of health parameters possible to bring about lasting lifestyle changes to prevent and minimise the risk for patient through timely identification of disease patterns.</p>`,
                published_date:'November 15, 2021',
                platform:'GiniHealth',
                link:'https://www.tribuneindia.com/news/chandigarh/punjab-chief-minister-charanjit-singh-channi-inaugurates-virtual-hospital-338239',
                image:`gininewsimg1.jpg`
            },
            {
                heading:"Fxview Crowned as the Best Value Broker at the Forex Expo Dubai, 2021",
                description:`<p>Fxview, a leading financial services company owned by Finvasia Group, won the award for "Best Value Broker" at the Forex Expo Dubai 2021. This award acknowledges the company's efforts to provide retail clients with the trading conditions that only institutional clients had access to.</p>`,
                published_date:'November 02, 2021',
                platform:'Fxview',
                link:'https://www.benzinga.com/pressreleases/21/11/b23814426/fxview-crowned-as-the-best-value-broker-at-the-forex-expo-dubai-2021',
                image:`news-fxview.png`
            },
            {
                heading:"FINVASIA Acquires Gini Health, Expands Into Healthcare Services",
                description:`<p><strong>FINVASIA</strong>, announced its investment in Gini Health, a Canada based healthcare technology company that pioneered DNA and lifestyle data based personalized health to prevent diseases. This acquisition furthers FINVASIA’s strategic business objectives of investing in traditional business and transforming them. The partnership supports FINVASIA’s commitment to health care to accelerate technology-led innovation in preventive and personalized healthcare services.</p>`,
                published_date:'November 01, 2021',
                platform:'Finvasia',
                link:'https://aithority.com/medical-apps/healthcare-management/finvasia-acquires-gini-health-expands-into-healthcare-services/',
                image:`logo.svg`
            },
            {
                heading:"FINVASIA Acquires Gini Health, Expands Into Healthcare Services",
                description:`<p>CHANDIGARH, India--(<a class='links-theme' href='https://www.businesswire.com/' target='_blank'>BUSINESS WIRE</a>)--FINVASIA, today, announced its investment in Gini Health, a Canada based healthcare technology company that pioneered DNA and lifestyle data based personalized health to prevent diseases. </p>`,
                published_date:'October 28, 2021',
                platform:'GiniHealth',
                link:'https://www.businesswire.com/news/home/20211028005976/en/FINVASIA-Acquires-Gini-Health-Expands-Into-Healthcare-Services',
                image:`news36.jpg`
            },
            {
                heading:"Gini Health Debuts Into Chronic Health Care Management, Launches Diabetes Reversal Program",
                description:`<p>TORONTO--(<a class='links-theme' href='https://www.businesswire.com/' target='_blank'>BUSINESS WIRE</a>)--Gini Health, one of North America's fastest growing health-tech companies, has teamed up with world-class doctors to launch a type 2 diabetes reversal program. Riding on the success of its pilot 12-week diabetes reversal program, conducted with participants from South Asian diaspora, Gini Health today announced that it has debuted into chronic health care management.</p>`,
                published_date:'October 01, 2021',
                platform:'GiniHealth',
                link:'https://www.businesswire.com/news/home/20211001005294/en/Gini-Health-Debuts-Into-Chronic-Health-Care-Management-Launches-Diabetes-Reversal-Program',
                image:`gininewprimg2.jpg`
            },
            {
                heading:"FINVASIA Group Announces Acquisition of ActTrader",
                description:`<p>FINVASIA Group announced today that it has acquired ActTrader Technologies (formerly ActForex) , a pioneer in building financial technology products for over 20 years.</p>`,
                published_date:'July 19, 2021',
                platform:'Finvasia',
                link:'https://www.bloomberg.com/press-releases/2021-07-19/finvasia-group-announces-acquisition-of-acttrader',
                image:`logo.svg`
            },
            {
                heading:"Finvasia Group Acquires Fxview, a Leader in FX and CFDs",
                description:`<p>Finvasia Group, which operates multiple brands in the FinTech industry today announced that it acquired Cyprus-based financial services company, Fxview. FINVASIA Group has acquired 100% stake in the company for an undisclosed sum.</p>`,
                published_date:'June 15, 2021',
                platform:'Finvasia',
                link:'https://www.bloomberg.com/press-releases/2021-06-15/finvasia-group-acquires-fxview-a-leader-in-fx-and-cfds',
                image:`logo.svg`
            },
            {
                heading:"Finvasia Stock Broker has released their new trading platform.",
                description:`<p>Finvasia is a stock broker in India with a zero brokerage model for day trading and investing across all instruments.<br />There are no hidden conditions attached to their zero cost day trading facility, you can trade brokerage free across all segments and instruments.</p>`,
                published_date:'November 27, 2020',
                platform:'Shoonya',
                link:'https://stocksonfire.in/trading-tools-and-reviews/finvasia-stock-broker-released-new-trading-platform/',
                image:`shoonya-logo.svg`
            },
            {
                heading:"Gini Health Announces First Dynamic Multivitamin Powered by Health Tracker Data Including New Integrations with MyFitnessPal and Others",
                description:`<p><a class='links-theme' href='https://ginihealth.com/' target='_blank'>Gini Health</a>, a Techstars startup with a health and wellness tracking app that supports continuously hyper-customized smart vitamins, today announced it has integrated with MyFitnessPal—among many other health trackers—to create vitamins that fill in dietary gaps. Gini Health is on a mission to motivate consumers to get most of their vitamins from real food and prevent overnutrition. And, since these vitamins are Canadian made, they’re regulated by the federal government, unlike the U.S.</p>`,
                published_date:'October 15, 2020',
                platform:'GiniHealth',
                link:'https://www.prweb.com/releases/gini_health_announces_first_dynamic_multivitamin_powered_by_health_tracker_data_including_new_integrations_with_myfitnesspal_and_others/prweb17470028.htm',
                image:`gininewprimg3.jpg`
            },
            {
                heading:"Finvasia Ventures into real estate, launches its first project",
                description:`<p>The project is a 8-storey commercial building spanning across 100,000 sq. ft. in Sector 74, Mohali, Punjab and is available for leasing to IT and ITES companies.</p>`,
                published_date:'Jun 04, 2020',
                platform:'FinvasiaEstate',
                link:'https://www.hindustantimes.com/brand-post/finvasia-ventures-into-real-estate-launches-its-first-project/story-SlUqsl4Pt17hrqB0ObEasI.html',
                image:`finvasiaestnewsimg1.webp`
            },
            {
                heading:"Finvasia Exits Its Proprietary Trading Service",
                description:`<p>(Eds: Disclaimer: The following press release comes to you under an arrangement with Business Wire India. PTI takes no editorial responsibility for the same.)
                Chandigarh, India: Business Wire India</p>`,
                published_date:'April 03, 2020',
                platform:'Finvasia',
                link:'https://www.theweek.in/wire-updates/business/2019/12/03/pwr28-finvasia.html',
                image:`logo.svg`
            },
            {
                heading:"Time to shift to renewable energy sources: CII chairman",
                description:`<p>It is time for gradual transition from traditional to renewable energy sources, said Confederation of Indian Industry (CII) chairman, Sarvjeet Singh Virk. He was addressing the CII’s power and renewable energy dialogue 2020 on Tuesday.</p>`,
                published_date:'Jan 15, 2020 ',
                platform:'Finvasia',
                link:'https://www.hindustantimes.com/chandigarh/time-to-shift-to-renewable-energy-sources-cii-chairman/story-QtlspIQzotNk4kTrgkUVZP.html',
                image:`finvasianewsimg6.webp`
            },
            {
                heading:"Exploring the ABCD for Starting Up",
                description:`<p>The Vice Chairman of CII Chandigarh Council, Sarvjeet Singh Virk, shares how start-ups can add value to their valuation & why validating the idea before going for funding has become a necessity today.</p>`,
                published_date:'JUL 30, 2019',
                platform:'Finvasia',
                link:'https://www.entrepreneur.com/en-in/growth-strategies/exploring-the-abcd-for-starting-up/337366',
                image:`finvasianewsimg5.webp`
            },
            {
                heading:"Finvasia expects to open 10 million accounts by 2020: MD and co-founder Sarvjeet Singh Virk",
                description:`<p>While zero brokerage has already caught on with equity and mutual funds transactions, there are companies like Finvasia Securities that have just commenced their journey here. This Chandigarh-based company provides complementary back-office services, including demat accounts access and support. It is now in the process of creating its own NBFC business, which would be another logical service extension for its retail client base. The company’s co-founder and MD, Sarvjeet Singh Virk, talks to Pankaj Joshi about the company.</p>`,
                published_date:'May 30, 2019',
                platform:'Shoonya',
                link:'https://www.freepressjournal.in/business/finvasia-expects-to-open-10-million-accounts-by-2020-md-and-co-founder-sarvjeet-singh-virk',
                image:`finvasianewsimage3.png`
            },
            {
                heading:"How Discount Brokers are Disrupting the Stockbroking Industry",
                description:`<p>Last month, when Zerodha replaced long-time market leader ICICI Securities as the country's largest stockbroker with 8.47 lakh clients, it surprised many in the industry, who felt the need buckle up against the growing number of stockbroking startups and their disruptive business models.</p>`,
                published_date:'MAR 5, 2019',
                platform:'Shoonya',
                link:'https://www.entrepreneur.com/en-in/finance/the-new-age-of-stockbroking-is-here-all-that-you-need-to/329570',
                image:`shoonyanewsimg1.webp`
            },
            {
                heading:"VIRK ELECTED CHAIRMAN OF CII",
                description:`<p>Sarvjeet Singh Virk and Dr SPS Grewal were respectively elected as the chairman and vicechairman of the Confederation of Indian Industry (CII), Chandigarh, for 2019-20 on Monday</p>`,
                published_date:'February 27,2019',
                platform:'Finvasia',
                link:'https://www.htsyndication.com/ht-chandigarh/article/virk-elected-chairman-of-cii/33149729',
                image:`logo.svg`
            },
            {
                heading:"Finvasia Approved to Transfer Commodity Membership in Capital Market",
                description:`<p>(Eds: Disclaimer: The following press release comes to you under an arrangement with Business Wire India. PTI takes no editorial responsibility for the same.) NSE, BSE, MCX, NCDEX to Go Live on 5 Nov 2018 Chandigarh, India Business Wire India Adding yet another milestone to its ever-expanding ecosystem, Finvasia received an approval to transfer its MCX membership to Capital Market Membership. The approval is a testament to the fact that the company is committed to provide its clients with products and services that meet and even exceed their expectations.</p>`,
                published_date:'Octuber 22, 2018',
                platform:'Finvasia',
                link:'https://www.businesstoday.in/pti-feed/story/finvasia-approved-to-transfer-commodity-membership-in-capital-market-150925-2018-10-22',
                image:`finvasianewimg4.jpg`
            },
            {
                heading:"Finvasia – How a Leading Fintech in India Killed the Brokerage Industry",
                description:`<p>Finvasia’s zero brokerage strategy has helped it achieve early success in India, while fortifying its growth prospects</p>`,
                published_date:'July 26,2018',
                platform:'Finvasia',
                link:'https://www.financemagnates.com/thought-leadership/finvasia-how-a-leading-fintech-in-india-killed-the-brokerage-industry/',
                image:`finvasianewimg3.jpg`
            },
            {
                heading:"Finvasia nominated for Benzinga Global Fintech Awards, NYC (USA)",
                description:`<p>The finalists for 2017 Benzinga Global Fintec Awards have beeb announced and Finvasia has been nominated as a finalist for this premier award event.</p>`,
                published_date:'March 09, 2018',
                platform:'Finvasia',
                link:'https://www.thehindubusinessline.com/business-wire/finvasia-nominated-for-benzinga-global-fintech-awards-nyc-usa/article22003021.ece',
                image:`logo.svg`
            },
            {
                heading:"Finvasia with Symphony launches Blitz; next generation algorithmic trading platform",
                description:`<p>New Delhi [India], Jan 18 (ANI-BusinessWireIndia): Finvasia is delighted to announce the launch of Blitz Trader, a groundbreaking algorithmic trading and execution platform designed by Symphony Technologies. Built on Symphony's pedigree technology, Blitz Trader allows proprietary traders, Hedge fund, Institutional investors, HNIs and professional traders to develop, test and deploy simple to complex trading strategies using an easy to use, cost-effective solution. "The addition of Blitz Trader to Finvasia's growing suite of trading platforms underscores our commitment to provide clients technologically driven trading products," said Tajinder Singh (Natty Virk), CEO Finvasia. "We are excited to be able to offer our clients an industry leading product, giving them access to automate their high frequency quantitative trading strategies without needing them to understand the underneath technology. The clients will have access to a dedicated simulation market to test the performance of their strategies before hitting the reality," said Tajinder Singh. BlitzTrader allows traders to monitor and manage their algorithmic trading performance from managing market data feed, risk management, order management system to order routing, delivering an exceptional trading experience. Build on the top is BlitzTrader API, an open framework that enables quant developer to quickly develop, test and integrate proprietary trading strategies, exchange adapters, trading tools to create the next level of automation. "With BlitzTrader we have redefined the algorithmic market by lowering the complexities of algorithmic solutions. BlitzTrader enables clients to operate their own algorithmic trading system without the need to tackle complex system integration issues," said CEO Symphony Technologies, Praveen K Gupta. (ANI-BusinessWireIndia)</p>`,
                published_date:'January 18, 2017',
                platform:'Shoonya',
                link:'https://www.aninews.in/news/business/finvasia-with-symphony-launches-blitz-next-generation-algorithmic-trading-platform/',
                image:`shoonya-logo.svg`
            },
            {
                heading:"Finvasia gets licence to operate as NBFC",
                description:`<p>CHANDIGARH: Chandigarh-based Finvasia, a fintech company offering zero brokerage, has received the Certificate of Registration (CoR) from RBI to operate as a non-banking financial corporation (NBFC).</p>`,
                published_date:'December 19, 2017',
                platform:'Finvasia',
                link:'https://www.tribuneindia.com/news/archive/business/finvasia-gets-licence-to-operate-as-nbfc-515809',
                image:`logo.svg`
            },
            {
                heading:"These Brothers are using AI to Kill the Brokerage Model in Capital Market",
                description:`<p>Capital market is a tricky place, where many have burnt their fingers and lost their hard earned money. The reasons are many and range from lack of knowledge, poor technology to brokers' vested interests.</p>`,
                published_date:'September 15, 2017',
                platform:'Finvasia',
                link:'https://www.entrepreneur.com/en-in/technology/these-brothers-are-trying-to-kill-the-capital-market-with/300410',
                image:`logo.svg`
            },
            {
                heading:"TWO BROTHERS FROM CHANDIGARH ARE MAKING FINVASIA A ONE-STOP SHOP FOR VARIOUS FUNDS",
                description:`<p>Finvasia provides brokerage services in commodities, equities & forex, research advisory, structured investments, asset management, currency hedging, corporate funding, real estate, MF, financing, etc.`,
                published_date:'August 21, 2017',
                platform:'Finvasia',
                link:'https://yourstory.com/2017/08/two-brothers-chandigarh-making-finvasia-one-stop-shop-various-funds',
                image:`logo.svg`
            },
            {
                heading:"Finvasia wins 2017 Benzinga Global Fintech Award",
                description:`<p>Finvasia, India's first broker that offers commission free trading, has won the award for 'Best Trading Execution or Brokerage Platform' at the 2017 Benzinga Global Fintech Awards in New York, USA.</p>`,
                published_date:'May 19, 2017 ',
                platform:'Finvasia',
                link:'https://www.business-standard.com/article/news-ani/finvasia-wins-2017-benzinga-global-fintech-award-117051900339_1.html',
                image:`finvasianewimg2.jpg`
            },
            {
                heading:"Creating a commission free financial ecosystem in India",
                description:`<p>Finvasia closed a funding deal for an undisclosed amount against a valuation of INR 1.5 Billion from some of the industry's notable Venture Capitalists, where majority FDI stake is from Intrinsic Investment Limited.</p>`,
                published_date:'September 08, 2016',
                platform:'Finvasia',
                link:'https://www.business-standard.com/article/news-ani/creating-a-commission-free-financial-ecosystem-in-india-116090801114_1.html',
                image:`finvasianewimg2.jpg`
            },
            {
                heading:"Finvasia & 94.3 MYFM present – My Money Guru",
                description:`<p><strong>CHANDIGARH, India - Feb. 6, 2014 -</strong> <a class='links-theme' target='_blank' href='https://www.prlog.org/'>PRLog</a> -- Finvasia in its continuing endeavor to promote financial literacy and improve investor awareness has joined hands with 94.3 MY FM, a pioneering brand in radio space, to present India's first radio talk show on investment related issues – My Money Guru. The show will air every Sunday on 94.3 MYFM from 5 to 6 pm PAN Punjab begining February 9th, 2014.</p>`,
                published_date:'February 06, 2014',
                platform:'Finvasia',
                link:'https://www.prlog.org/12278786-finvasia-943-myfm-present-my-money-guru.html',
                image:`logo.svg`
            },
            {
                heading:"FINVASIA – A Truly Deep Discount Brokerage Company on the Block, Focusing on High Quality Client Service",
                description:`<p><strong>Sarvjeet Singh Virk, Managing Director at Finvasia, aims to strengthen Finvasia’s presence in the Indian retail brokerage market.</strong><br /> Chandigarh, Punjab, India, April 11, 2013 - (<strong>PressReleasePoint</strong>) - Sarvjeet Singh Virk, Managing Director at Finvasia, aims to strengthen Finvasia’s presence in the Indian retail brokerage market.</p>`,
                published_date:'April 11, 2013',
                platform:'Finvasia',
                link:'https://www.pressreleasepoint.com/finvasia-%E2%80%93-truly-deep-discount-brokerage-company-block-focusing-high-quality-client-service',
                image:`logo.svg`
            },
    ],
    contsct_us_page:[
        {
            place:'',
            address:'',
            image:''
        }
    ],
    uppManage : [
        // {
        //     "id": 1,
        //     "name": "Janis Anastassiou",
        //     "description": "MD - Financial Intermediation <br>Finvasia",
        //     "img": "/assets/images/ourleadershipimg/JanisAnasassiou.png",
        //     "isCyprus": "false",
        // },
        {
            "id": 2,
            "name": "Rahul Bansal",
            "description": "Director - Financial Intermediation <br>Finvasia",
            "img": "/assets/images/ourleadershipimg/Rahul-Bansal.png",
            "isCyprus": "false",
        },
        {
            "id": 3,
            "name": "Dimitrios Lathouras",
            "description": "Director - General Management <br />ZuluTrade",
            "img": "/assets/images/ourleadershipimg/DimitriosLathouras.png",
            "isCyprus": "false",
        },
        // {
        //     "id": 4,
        //     "name": "Fotis Fotinias",
        //     "description": "Chief Executive Officer <br>Capital Wallet",
        //     "img": "/assets/images/ourleadershipimg/FotisFotinias.png",
        //     "isCyprus": "true",
        // },
        {
            "id": 5,
            "name": "IIya sorokin",
            "description": "Advisor to the Board <br>ActTrader",
            "img": "/assets/images/ourleadershipimg/IIyasorokin.png",
            "isCyprus": "false",
        },
        {
            "id": 6,
            "name": "Alex Bromberg",
            "description": "Chief Product Officer <br>ActTrader",
            "img": "/assets/images/ourleadershipimg/AlexBromberg.png",
            "isCyprus": "false",
        },
        {
            "id": 7,
            "name": "Jagpal Singh",
            "description": "VP IT <br>Finvasia",
            "img": "/assets/images/ourleadershipimg/JagpalSingh.png",
            "isCyprus": "false",
        },
        // {
        //     "id": 8,
        //     "name": "Abha Garg",
        //     "description": "Executive Director <br>Capital Wallet",
        //     "img": "/assets/images/ourleadershipimg/AbhaGarg.png",
        //     "isCyprus": "true",
        // },
        {
            "id": 9,
            "name": "Jatin Kumar",
            "description": "VP Engineering<br>Finvasia",
            "img": "/assets/images/ourleadershipimg/JatinKumar.png",
            "isCyprus": "false",
        },
        {
            "id": 10,
            "name": "Dr. Anil Bhansali",
            "description": "Chief Physician, <br>Gini Health",
            "img": "/assets/images/ourleadershipimg/DrAnilBhansali.png",
            "isCyprus": "false",
        },
        {
            "id": 11,
            "name": "Pawan Kumar",
            "description": "Operation & Compliance",
            "img": "/assets/images/ourleadershipimg/Pawan-Kumar.png",
            "isCyprus": "false",
        },
        {
            "id": 12,
            "name": "Constantinos Tsissios",
            "description": "Director<br /> Payments",
            "img": "/assets/images/ourleadershipimg/Constantinos-Tsissios.png",
            "isCyprus": "false",
        },
        {
            "id": 13,
            "name": "Dheeraj Deewan",
            "description": "Director, <br>Finvasia Estates",
            "img": "/assets/images/ourleadershipimg/Dheeraj-Dewan.png",
            "isCyprus": "false",
        },
    
    ],
}