<template>
    <section class="brands-main">
        <div class="container cstm">
            <div class="page-heading text-center pb-5 pt-4 mt-3 border-bottom">
                <h1 class="fs70 lh-150 mb-4 fw-bold">Our <span class="theme-color">Brands</span></h1>
            </div>
            <!-- brand row -->
            <div 
                v-for="(list,key) in getBrandsList(static_vars.our_brands).slice(0, items)" 
                :index="index" 
                :key="key" 
                v-bind:id="list.id"
                class="brand-row d-flex align-items-start justify-content-between py-5 border-bottom" 
            >
                <div class="logo-wrap flex-shrink-0">
                    <!-- <img class="img-fluid" width="240" height="84" :alt="list.title" :title="list.title" :src='"assets/images/our-brands/"+list.image'> -->
                    <img class="img-fluid" :alt="list.title" :title="list.title" :src='"assets/images/our-brands/"+list.image'>
                </div>
                <div class="text-sec flex-fill pe-5 me-3">
                    <h3 class="fs26 fw-semibold lh-150 mb-2 pb-1">{{list.heading}}</h3>
                    <div v-html="list.description"></div>
                </div>
                <a class="outlinebtn btn fs16 rounded-pill h51 flex-shrink-0 mt-1" v-if="list.href != ''" target="_blank" :href='list.href'>Visit Website</a>
                <a class="outlinebtn btn fs16 rounded-pill h51 flex-shrink-0 mt-1 pointer-none disabledBtn" v-else target="_blank" href="javascript:void(0)">Coming soon</a>
            </div>
            <!-- <button class="themeBtn btn fs18 rounded-pill h60 px-5 d-block mx-auto loadmorebtn border-0 my-5">Load More</button> -->
        </div>
    </section>
</template>
 
<script>
import {useMainStore} from "../../store/index.ts"
export default {
     name: 'Our Brands',
     setup(){
        const store= useMainStore();
        return {store};
     },
    data() {
        return {
            taskTab: 'aaafx',
            items:20,
            brand_list:[],
        }
    }, 
    created(){
        this.store.getcountrycode().then(res=> {
            if(res==true){
                this.getBrandsList(this.static_vars.our_brands)
            }
        })
    },   
    methods:{
        getBrandsList(content){
            let not_eu_content= ['Capital Wallet','AAAFX']
            if(this.store.not_cyprus_brand == true){
                content = content.filter((obj) => {
                return !not_eu_content.includes(obj.title.trim(' '))
                })
                return content;
            }else{
                return content;
            }
        }
    },
}
</script>