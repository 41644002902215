<template>
    <section class="news-main">
        <div class="container cstm">
            <div class="page-heading text-center pb-4 mb-2 pt-4 mt-3">
                <h1 class="fs70 theme-color lh-150 mb-1 fw-bold">News</h1>
                <p class="lh-190 fw-medium mb-1">All our news in one place. From Finvasia news, articles and Press Releases. Read about it here.</p>

                <div class="tabs-sec d-flex d-md-none justify-content-end pt-3">
                    <div class="dropdown">
                        <button class="btn btn-primary" type="button"  id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">{{mobilelisttab}}
                        <span class="caret"></span></button>
                        <ul class="dropdown-menu news-droph-mobile" aria-labelledby="dropdownMenuButton1">
                            <li><a href="#" @click="getfilterlist('all')">ALL</a></li>
                            <li><a href="#" @click="getfilterlist('Finvasia')">Finvasia</a></li>
                            <li><a href="#" @click="getfilterlist('Shoonya')">Shoonya</a></li>
                            <li><a href="#" @click="getfilterlist('Fxview')">Fxview</a> </li>
                            <li><a href="#" @click="getfilterlist('ZuluTrade')">ZuluTrade</a></li>
                            <li><a href="#" @click="getfilterlist('ActTrader')">ActTrader</a></li>
                            <li><a href="#" v-if="!non_cyprus_content" @click="getfilterlist('CapitalWallet')">Capital Wallet</a></li>
                            <li><a href="#" @click="getfilterlist('AAAFx')">AAAFx</a></li>
                            <li><a href="#" @click="getfilterlist('GiniHealth')">GiniHealth</a></li>
                            <li><a href="#" @click="getfilterlist('Portfolios')">Portfolios</a></li>
                            <li><a href="#" @click="getfilterlist('Stackflow')">Stackflow</a></li>
                            <li><a href="#" @click="getfilterlist('Bodyloop')">Bodyloop</a></li>
                            <li><a href="#" @click="getfilterlist('FinvasiaEstate')">Finvasia Estates</a></li>
                        </ul>
                    </div>
                </div>
                <div class="tabs-sec d-none d-md-block pt-3">
                    <ul class="h-tabs list-unstyled d-block d-md-flex justify-content-center pt-4 mb-0">
                        <li class="link-item d-flex align-items-center justify-content-between fs16 fw-medium rounded-pill" :class="listtab == 'all'?'active':''" @click="getfilterlist('all')">ALL</li>
                        <li class="link-item d-flex align-items-center justify-content-between fs16 fw-medium rounded-pill" :class="listtab == 'Finvasia'?'active':''" @click="getfilterlist('Finvasia')">Finvasia</li>
                        <li class="link-item d-flex align-items-center justify-content-between fs16 fw-medium rounded-pill" :class="listtab == 'Shoonya'?'active':''" @click="getfilterlist('Shoonya')">Shoonya</li>
                        <li class="link-item d-flex align-items-center justify-content-between fs16 fw-medium rounded-pill" :class="listtab == 'Fxview'?'active':''" @click="getfilterlist('Fxview')">Fxview </li>
                        <li class="link-item d-flex align-items-center justify-content-between fs16 fw-medium rounded-pill" :class="listtab == 'ZuluTrade'?'active':''" @click="getfilterlist('ZuluTrade')">ZuluTrade</li>
                        <div class="dropdown">
                            <button class="btn btn-primary" type="button"  id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">{{sublisttab}}
                            <span class="caret"></span></button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a href="#" @click="getfilterlist('ActTrader')">ActTrader</a></li>
                                <li><a href="#" v-if="!non_cyprus_content" @click="getfilterlist('CapitalWallet')">Capital Wallet</a></li>
                                <li><a href="#" @click="getfilterlist('AAAFx')">AAAFx</a></li>
                                <li><a href="#" @click="getfilterlist('GiniHealth')">GiniHealth</a></li>
                                <li><a href="#" @click="getfilterlist('Portfolios')">Portfolios</a></li>
                                <li><a href="#" @click="getfilterlist('Stackflow')">Stackflow</a></li>
                                <li><a href="#" @click="getfilterlist('Bodyloop')">Bodyloop</a></li>
                                <li><a href="#" @click="getfilterlist('FinvasiaEstate')">Finvasia Estates</a></li>
                            </ul>
                        </div>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="right-sec pt-3">
                        <div class="row g-3 g-md-4">
                            <!--  -->
                            <div class="col-md-12 col-lg-12" v-if="norecord">
                               <div class="text-center mt-5">
                                <img src="assets/images/no-news.png" width="65" height="65" class="mt-2 mb-3">
                                <p><b>No record found</b></p>
                               </div>
                            </div>
                            <div class="col-md-6 col-lg-4" v-for="(list,key,index) in newslist.slice(0, this.items)" :index="index" :key="key"  v-else >
                                <div class="news-box grayBg br40 h-100" >
                                    <div class="time-row d-flex align-items-center justify-content-between mb-sm-4 mb-md-3 mb-lg-4 mb-2">
                                      <p class="lh-150 mb-1">{{list.published_date}}</p>
                                      <p class="lh-150 mb-1 theme-color fw-semibold">{{list.platform}}</p>
                                    </div>
                                    <div class="img-wrap" v-if="list.image && list.image != ''">
                                        <img class="img-fluid" :src='"assets/images/finvasia_news_images/"+list.image' alt="">
                                    </div>
                                    <h3 class="lh-150 fs20 fw-medium pb-2 news-title" v-html="list.heading"></h3>
                                    <p class="lh-190 mb-lg-4 mb-3" v-html="list.description" v-if="list.description && list.description.length < 300"></p>
                                    <p class="lh-190 mb-lg-4 mb-3" v-html="list.description.substring(0,330)+'...'" v-else></p>
                                    <a :href="list.link" class="outlinebtn btn fs16 rounded-pill h51 mt-1" target="_blank">Read More</a>
                                </div>
                            </div>
                        </div>
                        <!--  v-if="showLoadmore()" -->
                        <!-- <p v-if="newslist">{{newslist.length}}</p> -->
                        <button class="themeBtn btn fs18 rounded-pill h60 d-block mx-auto loadmorebtn border-0" @click="loadmore()" v-if="showLoadmore()">Load More News</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template> 
<script>
import 'vue3-carousel/dist/carousel.css';
import {useMainStore} from "../../store/index.ts"
    export default {
        name: 'News',
        setup(){
            const store = useMainStore();
            return {store};
        },
        data() {
            return {      
                listtabarray:[
                    {"key":'GiniHealth',"value":'Gini Health'},
                    {"key":'ActTrader',"value":'Act Trader'},
                    {"key":'CapitalWallet',"value":'Capital Wallet'},
                    {"key":'FinvasiaEstate',"value":'Finvasia Estate'},
                    {"key":'AAAFx',"value":'AAAFx'},
                    {"key":'Stackflow',"value":'Stackflow'},
                    {"key":'Bodyloop',"value":'Bodyloop'},
                    {"key":'Portfolios',"value":'Portfolios'}
                ],
                mobilelisttabarray:[                    
                    {"key":'Shoonya',"value":'Shoonya'},
                    {"key":'Finvasia',"value":'Finvasia'},
                    {"key":'Fxview',"value":'Fxview'},
                    {"key":'ZuluTrade',"value":'ZuluTrade'},
                    {"key":'GiniHealth',"value":'Gini Health'},
                    {"key":'ActTrader',"value":'Act Trader'},
                    {"key":'CapitalWallet',"value":'Capital Wallet'},
                    {"key":'FinvasiaEstate',"value":'Finvasia Estate'},
                    {"key":'AAAFx',"value":'AAAFx'},
                    {"key":'Stackflow',"value":'Stackflow'},
                    {"key":'Bodyloop',"value":'Bodyloop'},
                    {"key":'Portfolios',"value":'Portfolios'}
                ],
                sublisttab:'More',
                mobilelisttab:'All',
                norecord:false,
                listtab:'all',
                newslist:{},
                newsTab:'News', 
                activeUl: false,
                dropDown:false,
                items:12,
                blogsData: [],
                non_cyprus_content: false,
             }
        },
        created(){
            this.store.getcountrycode().then(res=>{
                if(res== true){
                    if(this.store.not_cyprus_brand){
                        this.non_cyprus_content = true
                    }
                }     
            }) 
            this.newslistfun()
        },
        computed: {
            getData() {
                if (this.blogsData.length) {
                    if (this.showMore) {
                        return this.blogsData;
                    } else {
                        // return this.blogsData.slice(0, this.items);
                        return this.blogsData;
                    }
                } else {
                    return [];
                }
            },
        },
        methods:{
            getRecentNewsList(){
                let data = this.static_vars.BlogsArray.filter((i) => i.slug == this.listtab);
                if (data.length != 0) {
                    this.pageItems = this.items
                    this.store.getNewsList({}, true, data[0].id).then((response) => {
                        if (response.length) {
                            this.blogsData = response;
                        } else {
                            this.blogsData = [];
                        }
                    }).catch((error) => {
                        if(error){
                            this.norecord = true
                        }else{
                            this.norecord = true
                        }                        
                    });
                }else{
                    this.norecord = true
                }
            },
            getfilterlist(filter){
                if(filter == 'GiniHealth' || filter == 'Portfolios' || filter == 'ActTrader' || filter == 'CapitalWallet' || filter == 'AAAFx' || filter == 'Stackflow' || filter == 'Bodyloop' || filter == 'FinvasiaEstate'){
                    var result = this.listtabarray.find(item => item.key === filter);
                    this.sublisttab=result.value 
                    this.mobilelisttab=result.value 
                }else if(filter == 'Finvasia' || filter == 'Shoonya' || filter == 'Fxview' || filter == 'ZuluTrade'){
                    var mobileresult = this.mobilelisttabarray.find(item => item.key === filter);
                    this.mobilelisttab= mobileresult.value 
                }else{
                    this.sublisttab='More' 
                    this.mobilelisttab='All' 
                }
                this.listtab = ''+filter
                this.newslistfun()
                // this.getRecentNewsList()
            },
            loadmore(){
                this.items = this.items+12
                    this.newslistfun()
                // this.getRecentNewsList()
            },
            showLoadmore(){
                if(this.newslist && this.newslist !=''){
                    let list= this.newslist
                    if(this.listtab == 'all'){
                        return list.length/this.items > 1
                    }else{
                        let result= list.filter(list => list.platform == this.listtab)
                        return result.length/this.items > 1
                    }  
                }
            },
            newslistfun(){                
                let list= this.static_vars.news_page
                if(this.listtab == 'all'){
                    if(list.length > 0){  
                        this.norecord = false 
                        // this.newslist = list.slice(0, this.items)
                        this.newslist = list
                        if(this.store.not_cyprus_brand){
                            this.newslist = this.newslist.filter((obj) => {
                                return obj.platform != 'CapitalWallet';
                            })
                            
                            return this.newslist;
                        }else{
                            return this.newslist;
                        }
                    }else{
                        this.norecord = true  
                        return; 
                    }                    
                }else{
                    let result= list.filter(list => list.platform == this.listtab)
                    if(result.length > 0){
                        this.norecord = false  
                    }else{
                        this.norecord = true  
                        return;
                    }
                    this.newslist = result
                    // this.newslist = result.slice(0, this.items)
                }
            }
        },
        components: {},
    }  
</script>
 