<template>
  <Header />
  <div id="main-wrapper">
    <router-view />
  </div>
  <!-- <Footer v-if="$route.path != '/economic-overview' && $route.path != '/economic-overview'"/> -->
  <Footer />
</template>

<script>
import Header from "@/components/shared/header";
import Footer from "@/components/shared/footer";

export default {
  data() {
    return {
    };
  },
  components: { Header, Footer },
};
</script>

