<template>
    <div class="about-page">
        <!-- About us section -->
        <section class="about-sec sec-padding pt-0">
            <div class="container cstm">
                <div class="page-heading text-center pb-5 pt-4 mt-3">
                    <h1 class="fs70 lh-150 mb-0 fw-bold">About <span class="theme-color"> Finvasia Group</span></h1>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-6 col-xl-5">
                        <div class="img-wrap">
                            <img class="img-fluid mb-4" width="626" height="440" src="assets/images/finvasia-img.png" title="Finvasia" alt="Finvasia">
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-7">
                        <div class="text-sec ps-xl-4 ms-xl-4">
                            <p class="lh-170 mb-4 fs18 fw-medium">Finvasia is a global innovator leveraging engineering and technology to operate across various sectors, such as finance, healthcare, technology, blockchain, and real estate. We are dedicated to challenging traditional norms of business and creating a cost-effective, ethical and integrated ecosystem of products and services that inter-operates across various industry segments.</p>
                            <p class="lh-190 mb-4">Our wide range of portfolio includes: India's first and only commission-free financial ecosystems, a global leader of community driven social trading platform, a fintech trailblazers who have been the backbone of various public and private financial services organisations across the world, a multi-jurisdictional financial intermediation group that is licensed and registered with over 30 regulatory bodies across the world, Investment Banking License and a neo-bank that strive to challenge traditional banking industry, a knowledge sharing community for financial literacy, one-of-a-kind diabetes reversal facility that aims to put diabetes in medical reemission, state-of-the-art bio-medical devices startup, a regulated blockchain based digital payment processing solutions, and real estate management firms.</p>
                            <p class="lh-190 mb-0">Over the history of Finvasia and all its brands and subsidiaries, we have cumulatively served a few million clients in over 150 countries that have transacted over 2.5 trillion in value.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Gols section -->
        <section class="gols-sec sec-padding mission-sec">
            <div class="container cstm">
                <div class="row">
                    <div class="col-lg-5">
                        <div class="left-sec pe-xl-4">
                            <h2 class="fs60 fw-bold text-uppercase theme-color mb-4"><span class="headline position-relative pb-2">Finvasia</span></h2>
                            <!-- <p class="fs30 fw-medium pt-3 mb-4 mb-lg-0 lh-190">Finvasia is amongst India’s fastest growing FinTech company, offering financial services ranging from Zero brokerage, Zero clearing, Zero account opening, Zero AMC to name a few.</p> -->
                            <p class="fs30 fw-medium pt-3 mb-4 mb-lg-0 lh-150">Finvasia is a global enterprise that uses engineering and technology to innovate across various sectors such as financial services, technology, healthcare, blockchain, and real estate. We have served over 5 million clients in more than 190 countries and have transacted trillions of USD worth in value through our 10+ brands combined.</p>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <!-- <div class="right-sec ps-xxl-5 ms-lg-5">
                            <div class="goal-item pb-4 pb-5 mb-xxl-5 ps-xl-5">
                                <h3 class="fs30 theme-color fw-bold mb-3">01</h3>
                                <h4 class="mb-3 mb-xxl-4 display-5 fw-bold pb-1">Mission</h4>
                                <p class="lh-190 mb-1">We challenge the orthodox conventions of doing business by creating a “No Middle-man” environment  where businesses have no conflict of interest with their clients.  </p>
                            </div>
                            <div class="goal-item pb-4 pb-5 mb-xxl-5 ps-xl-5">
                                <h3 class="fs30 theme-color fw-bold mb-3">02</h3>
                                <h4 class="mb-3 mb-xxl-4 display-5 fw-bold pb-1">Vision</h4>
                                <p class="lh-190 mb-1">To be a leader in technology and engineering domains where our products solve 
                                    real-life problems of an average individual-ethically, without any conflict of
                                     interest. </p>
                            </div>
                            <div class="goal-item ps-xl-5">
                                <h3 class="fs30 theme-color fw-bold mb-3">03</h3>
                                <h4 class="mb-3 mb-xxl-4 display-5 fw-bold pb-1">Goal</h4>
                                <p class="lh-190 mb-1">Our goal is to build an ecosystem of choice, where we will facilitate third party integrations in our products so users, even our competitors, are not restricted to use our ecosystem.</p>
                            </div>
                        </div> -->
                        <div class="finvasia_goals_wrap">
                        <div class="right-sec">
                        <!-- item -->
                        <!-- <div class="mission-item">
                            <div class="d-flex align-items-center mb-3">
                                <div class="icon-wrap flex-shrink-0 me-1"><img class="img-fluid" width="48" height="48" alt="Vision Icon" title="vision icon" src="assets/images/vision.svg"></div>
                                <h3 class="fs30 lh-150 text-uppercase fw-bold ps-3 mb-0">Mission</h3>
                            </div>
                            <p class="fs16 lh-190 pt-1 mb-0">We challenge the orthodox conventions of doing business by creating a “No Middle-man” environment  where businesses have no conflict of interest with their clients.</p>
                        </div>
                        
                        <div class="mission-item">
                        <div class="d-flex align-items-center mb-3">
                            <div class="icon-wrap flex-shrink-0 me-1"><img class="img-fluid" width="49" height="49" alt="Mission Icon" title="mission icon" src="assets/images/mission.svg"></div>
                            <h3 class="fs30 lh-150 text-uppercase fw-bold ps-3 mb-0">Vision</h3>
                        </div>
                        <p class="fs16 lh-190 pt-1 mb-0">To be a leader in technology and engineering domains where our products solve real-life problems of an average individual-ethically, without any conflict of
                                     interest.</p>
                        </div>           
                        
                        <div class="mission-item">
                        <div class="d-flex align-items-center mb-3">
                            <div class="icon-wrap flex-shrink-0 me-1"><img class="img-fluid" width="48" height="48" alt="Value Icon" title="value icon" src="assets/images/value.svg"></div>
                            <h3 class="fs30 lh-150 text-uppercase fw-bold ps-3 mb-0">Goal</h3>
                        </div>
                        <p class="fs16 lh-190 pt-1 mb-0">Our goal is to build an ecosystem of choice, where we will facilitate third party integrations in our products so users, even our competitors, are not restricted to use our ecosystem.</p>
                        </div> -->
                        <!-- item -->
                        <div class="mission-item">
                            <div class="d-flex align-items-center mb-3">
                                <div class="icon-wrap flex-shrink-0 me-1"><img class="img-fluid" width="48" height="48" alt="Vision Icon" title="vision icon" src="assets/images/vision.svg"></div>
                                <h3 class="fs30 lh-150 text-uppercase fw-bold ps-3 mb-0">Mission</h3>
                            </div>
                            <p class="fs16 lh-190 pt-1 mb-0">We're committed to developing a simplified, affordable, and integrated ecosystem across finance, healthcare, and technology sectors. Our mission is to create ethical, sustainable products that benefit stakeholders, leveraging economies of scale and scope to drive positive change in every industry we engage with.</p>
                        </div>
                        <!-- item -->
                        <div class="mission-item">
                        <div class="d-flex align-items-center mb-3">
                            <div class="icon-wrap flex-shrink-0 me-1"><img class="img-fluid" width="49" height="49" alt="Mission Icon" title="mission icon" src="assets/images/mission.svg"></div>
                            <h3 class="fs30 lh-150 text-uppercase fw-bold ps-3 mb-0">Vision</h3>
                        </div>
                        <p class="fs16 lh-190 pt-1 mb-0">We aim to leverage engineering and technology to redefine the status quo, and create solutions that solve real-life problems in all the industries we operate in, changing the way businesses have traditionally operated.</p>
                        </div>           
                        <!-- item -->
                        <div class="mission-item">
                        <div class="d-flex align-items-center mb-3">
                            <div class="icon-wrap flex-shrink-0 me-1"><img class="img-fluid" width="48" height="48" alt="Value Icon" title="value icon" src="assets/images/value.svg"></div>
                            <h3 class="fs30 lh-150 text-uppercase fw-bold ps-3 mb-0">Value</h3>
                        </div>
                        <p class="fs16 lh-190 pt-1 mb-0">We invest in cutting-edge research and development, harnessing the power of engineering and technology to build solutions that drive progress, disrupt industries, and set new benchmarks for excellence.</p>
                        </div>
                        <!-- item -->
                        <div class="mission-item">
                            <div class="d-flex align-items-center mb-3">
                                <div class="icon-wrap flex-shrink-0 me-1"><img class="img-fluid" width="48" height="48" alt="Value Icon" title="value icon" src="assets/images/goal.svg"></div>
                                <h3 class="fs30 lh-150 text-uppercase fw-bold ps-3 mb-0">Goal</h3>
                            </div>
                            <p class="fs16 lh-190 pt-1 mb-0">Our goal is to inspire a paradigm shift across finance, healthcare, and technology sectors by challenging the status quo and deploying our engineering and technology expertise to deliver transformative solutions that benefit stakeholders and society at large.</p>
                            </div>
                    </div>
                </div>
            </div>
                </div>
            </div>
        </section>

        <!-- Growing section  -->
        <section class="growing-sec sec-padding">
            <div class="container cstm">
                <div class="head-sec mb-5 pb-lg-5">
                    <div class="row">
                        <div class="col-md-5 col-xl-5 d-flex flex-column justify-content-center">
                            <h2 class="fw-semibold lh-sm fs60 mb-5">A team of rebels <br/> and <span class="theme-color headline position-relative pb-2 pb-md-3">achievers</span></h2>
                            <p class="fw-medium mt-md-0 mt-4 lh-190 mb-0">Our team comprises over <span class="theme-color fw-bold">450 employees</span> that work
                                in our physical offices across UK, Greece, Cyprus, India, South Africa, Mauritius, Dubai, Japan and Australia</p>
                        </div>
                        <div class="col-md-6 col-xl-7">
                            <div class="right-sec ps-xl-4 ms-xl-4">
                                <div class="map-wrap text-center mb-5">
                                    <img class="img-fluid" width="750" height="419" alt="Finvasia Offices" title="Finvasia Offices" src="assets/images/map-img.svg">
                                </div>
                                <!-- <p class="fw-medium mt-md-0 mt-4 h3 lh-190 mb-0">Our team comprises over <span class="theme-color fw-bold">450 employees</span> that work
                                    in our physical offices across UK, Greece, Cyprus, India, South Africa, Mauritius, Dubai, Japan and Australia</p> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <!-- <div class="col-xl-12">
                        <div class="map-wrap text-center mb-5">
                            <img class="img-fluid" width="643" height="358" alt="Finvasia map image" title="finvasia map image" src="assets/images/map-img.svg">
                        </div>
                    </div> -->
                    <div class="col-xl-12">
                  <div class="finvasia_services-wrap">
                    <div class="right-sec">
                            <div class="row g-4">
                                <div class="col-md-4">
                                    <div class="about-box h-100"> 
                                        <h2 class="theme-color lh-1 mb-3 fs60 fw-semibold"><span class="fs60">450</span></h2>
                                        <h3 class="fs30 fw-semibold lh-150 mb-2">Employees</h3>
                                        <p class="mb-0 lh-190">Working across 12 physical offices across the globe</p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="about-box h-100"> 
                                        <h2 class="theme-color lh-1 mb-3 fs60 fw-semibold"><span class="fs60">10</span></h2>
                                        <h3 class="fs30 fw-semibold lh-150 mb-2">Brands</h3>
                                        <p class="mb-0 lh-190">Disrupting various industry verticals and challenging the status quo </p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="about-box h-100"> 
                                        <h2 class="theme-color lh-1 mb-3 fs60 fw-semibold"><span class="fs60">4</span></h2>
                                        <h3 class="fs30 fw-semibold lh-150 mb-2">Industry verticals</h3>
                                        <p class="mb-0 lh-190">Finvasia’s current suite of products spans across financial, healthcare, technology and real estate sectors</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                  </div>
                    </div>
                </div>
            </div>
        </section>
 
        <!-- Our Evolution section -->
        <section class="evolution-sec sec-padding">
            <div class="container cstm"> 
                <div class="row justify-content-center">
                    <div class="col-lg-12 col-xl-12">
                        <h2 class="fw-bold lh-sm fs60 text-center mb-16">Our <span class="theme-color">Evolution</span></h2>
                       <div class="row justify-content-center">
                        <div class="col-md-8 col-12">
                            <p class="fw-medium lh-190 text-center">A seed of humble beginnings, now a tree spanning horizons</p>
                        </div>
                       </div>
                    
                            <div class="main-timeline">
                                <ul class="mb-0 pZero">
                                    <span class="default-line"></span>
                                    <span class="draw-line"></span>
                                    <li class="timeline">
                                        <article>
                                            <div class="icon"><span class="lineAdded"></span></div>
                                            <div class="timeline-content right d-flex align-items-center">
                                                <div class="stroyImg darkCard">
                                                   <img class="d-block lineBorder" src="assets/images/timelineImages/timelineIcon4.svg" alt="2023" />
                                                </div>
                                                <div class="content darkCard">
                                                    <h5 class="title">2023</h5>
                                                    <p class="description">Received Financial Sector Conduct Authority (FSCA), South Africa License</p>
                                                    <p class="description">Secures Payments Provider Service Licence from DMCC</p>
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                    <li class="timeline">
                                        <article>
                                            <div class="icon"><span class="lineAdded"></span></div>
                                            <div class="timeline-content right d-flex align-items-center">
                                                <div class="stroyImg darkCard">
                                                   <img class="d-block lineBorder" src="assets/images/timelineImages/timelineIcon2.svg" alt="2022" />
                                                </div>
                                                <div class="content darkCard">
                                                    <h5 class="title">2022</h5>
                                                    <p class="mb-0"><strong>FSC Mauritius Licence</strong></p>
                                                    <p class="description mb-2">Received an investment banking licence from FSC Mauritius</p>
                                                    <!-- <p class="mb-0"><strong>Acquired AAAFx</strong></p>
                                                    <p class="description mb-2">AAAFx, regulated by the Hellenic Capital Market Commission, is a leading provider of online foreign exchange trading, stocks and related services</p> -->
                                                    <p class="mb-0"><strong>Acquired ZuluTrade</strong></p>
                                                    <p class="description">ZuluTrade is the world’s most recognizable social network for trading, investing and wealth management. Its supremacy as the global leader has remained unchallenged as it is the most transparent platform that is available to every individual.</p>
                                                    
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                    <li class="timeline">
                                        <article>
                                            <div class="icon"><span class="lineAdded"></span></div>
                                            <div class="timeline-content right d-flex align-items-center">
                                                <div class="stroyImg darkCard">
                                                   <img class="d-block lineBorder" src="assets/images/timelineImages/health.png" alt="2019" />
                                                </div>
                                                <div class="content darkCard">
                                                    <h5 class="title">2021</h5>
                                                    <p class="mb-0"><strong>Invested in Gini Health</strong></p>
                                                    <p class="description">Gini Health,a Canada based healthcare tech company that pioneered DNA and lifestyle data based personalised health to prevent diseases. We are on a mission to provide healthcare to people at a time when health is top-of-mind for all of us.</p>
                                                </div>
                                            </div>
                                        </article>
                                    </li>

                                    <li class="timeline">
                                        <article>
                                            <div class="icon"><span class="lineAdded"></span></div>
                                            <div class="timeline-content right d-flex align-items-center">
                                                <div class="stroyImg darkCard">
                                                   <img class="d-block lineBorder" src="assets/images/timelineImages/timelineIcon1.svg" alt="2020" />
                                                </div>
                                                <div class="content darkCard">
                                                    <h5 class="title">2020</h5>
                                                    <p class="mb-0"><strong>Acquired Fxview</strong></p>
                                                    <p class="description mb-2">Acquired Fxview, a leading CySec regulated broker, on a mission to revolutionise forex industry by creating ethical products that will cater to the needs of new age investors and help them grow their wealth</p>
                                                    <p class="mb-0"><strong>Acquired Act Trader</strong></p>
                                                    <p class="description mb-2">ActTrader is among the world's first "Fintech" companies, founded in 2000 in the United States of America . It has a strong heritage as a Fintech solution provider, drawing on 20+ years of technological excellence.</p>
                                                    <p class="mb-0"><strong>Launched Finvasia Estates</strong></p>
                                                    <p class="description">Finvasia ventured into Real Estate. The company has an extraordinary portfolio of residential and commercial real estate assets.</p>
                                                </div>
                                            </div>
                                        </article>
                                    </li>

                                    <li class="timeline">
                                        <article>
                                            <div class="icon"><span class="lineAdded"></span></div>
                                            <div class="timeline-content right d-flex align-items-center">
                                                <div class="stroyImg darkCard">
                                                   <img class="d-block lineBorder" src="assets/images/timelineImages/timelineIcon3.svg" alt="2019" />
                                                </div>
                                                <div class="content darkCard">
                                                    <h5 class="title">2019</h5>
                                                    <p class="mb-0"><strong>Received AUSTRAC registration</strong></p>
                                                    <p class="description mb-2">Capital Wallet received approval to act as a digital currency exchange.  Capital Wallet is working towards building an ecosystem of blockchain backed tools to transform the use of digital assets around the world.</p>
                                                    
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                    <li class="timeline">
                                        <article>
                                            <div class="icon"><span class="lineAdded"></span></div>
                                            <div class="timeline-content right d-flex align-items-center">
                                                <div class="stroyImg darkCard">
                                                   <img class="d-block lineBorder" src="assets/images/timelineImages/timelineIconbank.svg" alt="2017" />
                                                </div>
                                                <div class="content darkCard">
                                                    <h5 class="title">2017</h5>
                                                    <p class="mb-0"><strong>Became Non Banking Financial Corporation</strong></p>
                                                    <p class="description">Received Reserve Bank of India (RBI) approved NBFC license</p>
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                    <li class="timeline">
                                        <article>
                                            <div class="icon"><span class="lineAdded"></span></div>
                                            <div class="timeline-content right d-flex align-items-center">
                                                <div class="stroyImg darkCard">
                                                   <img class="d-block lineBorder" src="assets/images/timelineImages/timelineIcon5.svg" alt="2015" />
                                                </div>
                                                <div class="content darkCard">
                                                    <h5 class="title">2015</h5>
                                                    <p class="mb-0"><strong>Became Trading Member of BSE and AMFI</strong></p>
                                                    <p class="description">Became Trading Member of Bombay Stock Exchange Ltd (BSE) and The Association of Mutual Funds in India (AMFI)</p>
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                    <li class="timeline">
                                        <article>
                                            <div class="icon"><span class="lineAdded"></span></div>
                                            <div class="timeline-content right d-flex align-items-center">
                                                <div class="stroyImg darkCard">
                                                   <img class="d-block lineBorder" src="assets/images/timelineImages/timelineIcon6.svg" alt="2013" />
                                                </div>
                                                <div class="content darkCard">
                                                    <h5 class="title">2013</h5>
                                                    <p class="mb-0"><strong>Became Trading Members with NSE and MCX</strong></p>
                                                    <p class="description">Became a Trading-cum-Clearing Member of National Stock Exchange of India Limited (NSE) and a Trading Member of Multi-Commodity Exchange of India (MCX)</p>
                                                </div>
                                            </div>
                                        </article>
                                    </li>

                                    <li class="timeline">
                                        <article>
                                            <div class="icon"><span class="lineAdded"></span></div>
                                            <div class="timeline-content right d-flex align-items-center">
                                                <div class="stroyImg darkCard">
                                                   <img class="d-block lineBorder" src="assets/images/timelineImages/timelineIcon7.svg" alt="2011" />
                                                </div>
                                                <div class="content darkCard">
                                                    <h5 class="title">2011</h5>
                                                    <p class="mb-0"><strong>Registered with SEBI</strong></p>
                                                    <p class="description">Registered as a Foreign Institutional Investor (FII, now FPI) with Securities and Exchange Board of India (SEBI)</p>
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                    <li class="timeline">
                                        <article>
                                            <div class="icon"><span class="lineAdded"></span></div>
                                            <div class="timeline-content right d-flex align-items-center">
                                                <div class="stroyImg darkCard">
                                                   <img class="d-block lineBorder" src="assets/images/timelineImages/timelineIcon8.svg" alt="2010" />
                                                </div>
                                                <div class="content darkCard">
                                                    <h5 class="title">2010</h5>
                                                    <p class="mb-0"><strong>SEC( USA)</strong></p>
                                                    <p class="description">Registered as an Investment Advisor Securities and Exchange Commission, USA</p>
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                    <li class="timeline">
                                        <article>
                                            <div class="icon"><span class="lineAdded"></span></div>
                                            <div class="timeline-content right d-flex align-items-center">
                                                <div class="stroyImg darkCard">
                                                   <img class="d-block lineBorder" src="assets/images/timelineImages/timelineIcon10.svg" alt="2009" />
                                                </div>
                                                <div class="content darkCard">
                                                    <h5 class="title">2009</h5>
                                                    <p class="mb-0"><strong>Our Beginning</strong></p>
                                                    <p class="description">Finvasia Group established in Canada & India, with a vision to make financial markets easily accessible to the masses around the globe.</p>
                                                </div>
                                            </div>
                                        </article>
                                    </li>


                                    
                                </ul>
                            </div>
                        
                    </div>
                </div>
                
            </div>
        </section>

        <!-- Group Licenses section -->
        <section class="licenses-sec sec-padding">
            <div class="container cstm">
                <div class="row justify-content-center">
                    <div class="col-lg-10 col-xl-8">
                        <h2 class="fw-bold lh-sm fs60 text-center mb-16">Group <span class="theme-color"> Licenses & Regulation</span></h2>
                        <p class="fw-medium lh-190 text-center mb-5 pb-4">Finvasia, along with its subsidiaries, is regulated or registered with over 30 regulators worldwide. A list of our licences is given below</p>
                    </div>
                </div>
                <div class="licenses-row d-flex justify-content-center flex-wrap">
                    
    
                        <!-- item box -->
                        <div class="license-item-wrap">
                        <div class="license-item d-flex align-items-center">
                            <div class="flag-wrap">
                                <img src="assets/images/country_images/India.svg">
                            </div>
                            <div class="text-sec">
                                <h4 class="small theme-color itmtitle">NSE</h4>
                                <h5 class="regno">Member M51912</h5>
                                <p class="country">India</p>
                            </div>
                        </div>
                    </div>
                        <!-- item box -->
                        <div class="license-item-wrap">
                         <div class="license-item d-flex align-items-center">
                            <div class="flag-wrap">
                                <img src="assets/images/country_images/India.svg">
                            </div>
                            <div class="text-sec">
                                <h4 class="small theme-color itmtitle">BSE</h4>
                                <h5 class="regno">Member Code 4043</h5>
                                <p class="country">India</p>
                            </div>
                        </div>
                    </div>
                        <!-- item box -->
                        <div class="license-item-wrap">
                         <div class="license-item d-flex align-items-center">
                            <div class="flag-wrap">
                                <img src="assets/images/country_images/India.svg">
                            </div>
                            <div class="text-sec">
                                <h4 class="small theme-color itmtitle">MCX</h4>
                                <h5 class="regno">Member ID 55135</h5>
                                <p class="country">India</p>
                            </div>
                        </div>
                    </div>             
                        <!-- item box -->
                        <div class="license-item-wrap">
                        <div class="license-item d-flex align-items-center">
                            <div class="flag-wrap">
                                <img src="assets/images/country_images/India.svg">
                            </div>
                            <div class="text-sec">
                                <h4 class="small theme-color itmtitle">AMFI</h4>
                                <h5 class="regno">ARN 103331</h5>
                                <p class="country">India</p>
                            </div>
                        </div>
                    </div>
                        <!-- item box -->
                        <div class="license-item-wrap">
                        <div class="license-item d-flex align-items-center">
                            <div class="flag-wrap">
                                <img src="assets/images/country_images/India.svg">
                            </div>
                            <div class="text-sec">
                                <h4 class="small theme-color itmtitle">NCDEX</h4>
                                <h5 class="regno">Member ID 01259</h5>
                                <p class="country">India</p>
                            </div>
                        </div>
                    </div>
                        <!-- item box -->
                        <div class="license-item-wrap">
                            <div class="license-item d-flex align-items-center">
                                <div class="flag-wrap">
                                    <img src="assets/images/country_images/India.svg">
                                </div>
                                <div class="text-sec">
                                    <h4 class="small theme-color itmtitle">NBFC</h4>
                                    <h5 class="regno">Reg No. N-06.00609</h5>
                                    <p class="country">India</p>
                                </div>
                            </div>
                        </div>
                     
    
                        <div class="license-item-wrap">
                         <div class="license-item d-flex align-items-center">
                            <div class="flag-wrap">
                                <img src="assets/images/country_images/India.svg">
                            </div>
                            <div class="text-sec">
                                <h4 class="small theme-color itmtitle">CDSL</h4>
                                <h5 class="regno">DP ID 120874300, <br/>Reg No. IN-DP-317-2017</h5>
                                <p class="country">India</p>
                            </div>
                        </div>
                    </div>
    
    
                    <div class="license-item-wrap">
                        <div class="license-item d-flex align-items-center">
                            <div class="flag-wrap">
                                <img src="assets/images/country_images/south-africa.svg">
                            </div>
                            <div class="text-sec">
                                <h4 class="small theme-color itmtitle">FSCA</h4>
                                <h5 class="regno">Lic No: 50410</h5>
                                <p class="country">South Africa</p>
                            </div>
                        </div>
                    </div>
                    <div class="license-item-wrap">
                        <div class="license-item d-flex align-items-center">
                            <div class="flag-wrap">
                                <img src="assets/images/country_images/mauritius.svg">
                            </div>
                            <div class="text-sec">
                                <h4 class="small theme-color itmtitle">FSC</h4>
                                <h5 class="regno">Lic No: IK21000018</h5>
                                <p class="country">Mauritius</p>
                            </div>
                        </div>
                    </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                        <div class="license-item d-flex align-items-center">
                            <div class="flag-wrap">
                                <img src="assets/images/country_images/Finland.svg">
                            </div>
                            <div class="text-sec">
                                <h4 class="small theme-color itmtitle">FSA</h4>
                                <h5 class="regno">Reg No. 56650</h5>
                                <p class="country">Finland</p>
                            </div>
                        </div>
                    </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/Spain.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">CNMV</h4>
                            <h5 class="regno">Reg No. 4892</h5>
                            <p class="country">Spain</p>
                        </div>
                    </div>
                </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/Cyprus.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">CySEC</h4>
                            <h5 class="regno">Lic No. 367/18</h5>
                            <p class="country">Cyprus</p>
                        </div>
                    </div>
                </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/India.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">SEBI</h4>
                            <h5 class="regno">Reg No. INZ000176037</h5>
                            <p class="country">India</p>
                        </div>
                    </div>
                </div>
              
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/Hungary.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">MNB</h4>
                            <h5 class="regno">Reg No. K8805342</h5>
                            <p class="country">Hungary</p>
                        </div>
                    </div>
                </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/France.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">ACPR</h4>
                            <h5 class="regno">Reg No. 85051</h5>
                            <p class="country">France</p>
                        </div>
                    </div>
                </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/Germany.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">BaFin</h4>
                            <h5 class="regno">Reg No. 157152</h5>
                            <p class="country">Germany</p>
                        </div>
                    </div>
                </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/UK.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">FCA</h4>
                            <h5 class="regno">Reg No. 850138</h5>
                            <p class="country">United Kingdom</p>
                        </div>
                    </div>
                </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/Italy.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">CONSOB</h4>
                            <h5 class="regno">Reg No. 5151</h5>
                            <p class="country">Italy</p>
                        </div>
                    </div>
                </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/Norway.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">Finanstilsynet</h4>
                            <h5 class="regno">Reg No. FT00118545</h5>
                            <p class="country">Norway</p>
                        </div>
                    </div>
                </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/Sweden.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">FI</h4>
                            <h5 class="regno">Reg No. 56650</h5>
                            <p class="country">Sweden</p>
                        </div>
                    </div>
                </div>
                <!-- item box -->
                <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/australia-icon.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">AUSTRAC</h4>
                            <h5 class="regno">DCE100595470-001</h5>
                            <p class="country">Australia</p>
                        </div>
                    </div>
                </div>
                <!-- item box -->
                <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/dubai.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">DMCC</h4>
                             <h5 class="regno">Lic No. DMCC-880581</h5>
                            <p class="country">Dubai</p>
                        </div>
                    </div>
                </div>




                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/Austria.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">FMA</h4>
                            <!-- <h5 class="regno"></h5>  -->
                            <p class="country">Austria</p>
                        </div>
                    </div>
                </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/Croatia.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">HANFA</h4>
                            <!-- <h5 class="regno"></h5>  -->
                            <p class="country">Croatia</p>
                        </div>
                    </div>
                </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/Denmark.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">DFSA</h4>
                            <!-- <h5 class="regno"></h5> -->
                            <p class="country">Denmark</p>
                        </div>
                    </div>
                </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/Iceland.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">FME</h4>
                            <!-- <h5 class="regno"></h5> -->
                            <p class="country">Iceland</p>
                        </div>
                    </div>
                </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/Latvia.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">FKTK</h4>
                            <!-- <h5 class="regno"></h5> -->
                            <p class="country">Latvia</p>
                        </div>
                    </div>
                </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/Lithuania.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">LB</h4>
                            <!-- <h5 class="regno"></h5> -->
                            <p class="country">Lithuania</p>
                        </div>
                    </div>
                </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/Malta.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">MFSA</h4>
                            <!-- <h5 class="regno"></h5> -->
                            <p class="country">Malta</p>
                        </div>
                    </div>
                </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/Poland.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">KNF</h4>
                            <!-- <h5 class="regno"></h5> -->
                            <p class="country">Poland</p>
                        </div>
                    </div>
                </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/Romania.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">ASF</h4>
                            <!-- <h5 class="regno"></h5> -->
                            <p class="country">Romania</p>
                        </div>
                    </div>
                </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/Slovenia.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">ATVP</h4>
                            <!-- <h5 class="regno"></h5> -->
                            <p class="country">Slovenia</p>
                        </div>
                    </div>
                </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/Bulgaria.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">FSC</h4>
                            <!-- <h5 class="regno"></h5> -->
                            <p class="country">Bulgaria</p>
                        </div>
                    </div>
                </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/CzechRepublic.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">CNB</h4>
                            <!-- <h5 class="regno"></h5> -->
                            <p class="country">Czech Republic</p>
                        </div>
                    </div>
                </div>
                  
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/Greece.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">HCMC</h4>
                            <!-- <h5 class="regno"></h5> -->
                            <p class="country">Greece</p>
                        </div>
                    </div>
                </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/Liechtenstein.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">FMA</h4>
                            <!-- <h5 class="regno"></h5> -->
                            <p class="country">Liechtenstein</p>
                        </div>
                    </div>
                </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/Luxembourg.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">CSSF</h4>
                            <!-- <h5 class="regno"></h5> -->
                            <p class="country">Luxembourg</p>
                        </div>
                    </div>
                </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/Netherlands.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">AFM</h4>
                            <!-- <h5 class="regno"></h5> -->
                            <p class="country">Netherlands</p>
                        </div>
                    </div>
                </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/Portugal.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">CMVM</h4>
                            <!-- <h5 class="regno"></h5> -->
                            <p class="country">Portugal</p>
                        </div>
                    </div>
                </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/Slovakia.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">NBS</h4>
                            <!-- <h5 class="regno"></h5> -->
                            <p class="country">Slovakia</p>
                        </div>
                    </div>
                </div>
                    <!-- item box -->
                    <div class="license-item-wrap">
                    <div class="license-item d-flex align-items-center">
                        <div class="flag-wrap">
                            <img src="assets/images/country_images/Ireland.svg">
                        </div>
                        <div class="text-sec">
                            <h4 class="small theme-color itmtitle">Central Bank of Ireland</h4>
                            <!-- <h5 class="regno"></h5> -->
                            <p class="country">Ireland</p>
                        </div>
                    </div>
                </div>
                 

                </div>
            </div>
        </section>
    </div>
</template> 
<script>
    import jQuery from 'jquery';
    export default {
        name: 'About-Us',
        data() {
            return {

                }
            },
            mounted() {
                // Timeline Scroll Section
        // --------------------------------------------------------------
            var items = jQuery(".main-timeline li"),
            // timelineHeight = jQuery(".main-timeline ul").height(),
            lineToDraw = jQuery('.draw-line'),
            idTimeline = jQuery('#timeline');
            

            // sets the height that the greyLine (.default-line) should be according to `.timeline ul` height
            jQuery('.default-line').height(idTimeline.height())
            // run this function only if draw line exists on the page
            if(lineToDraw.length) {
                jQuery(window).on('scroll', function () {
                    // Need to constantly get '.draw-line' height to compare against '.default-line'
                    let greyLine = jQuery('.default-line'),
                    /* var redLineHeight = lineToDraw.height(),*/
                    greyLineHeight = greyLine.height(),
                    // var redLineHeight = idTimeline.height(),
                    // greyLineHeight = timelineHeight,
                    windowDistance = jQuery(window).scrollTop(),
                    windowHeight = jQuery(window).height() / 2,
                    timelineDistance = jQuery(".main-timeline").offset().top;
                    if(windowDistance >= timelineDistance - windowHeight) {
                        let line = windowDistance - timelineDistance + windowHeight;
                        if(line <= greyLineHeight) {
                            lineToDraw.css({
                            'height' : line + 10 + 'px'
                            });
                        }
                    }
                    // This takes care of adding the class in-view to the li:before items
                    var bottom = lineToDraw.offset().top + lineToDraw.outerHeight(true);
                    items.each(function(/*index*/){
                        var circlePosition = jQuery(this).offset();
                        if(bottom > circlePosition.top) {             
                            jQuery(this).addClass('in-view');
                        }  
                        else {
                            jQuery(this).removeClass('in-view');
                        }
                    }); 
                });
            }
        },
            
        }  
</script>
 