<template>
    <section class="news-main news-details">
        <div class="container-fluid cstm">
            <!-- <div class="page-heading text-center pb-5 mb-2 pt-4 mt-3">
                <h1 class="fs70 theme-color lh-150 mb-1 fw-bold">News</h1>
                <p class="lh-190 fw-medium mb-1">All our news in one place. From Finvasia news, articles, and Press Releases, read about it here.</p>
            </div> -->
            <div class="row justify-content-center">
                <!-- Tabs -->
                <!-- <div class="col-md-4 col-lg-3">
                    <div class="tabs-sec">
                        <h2 class="fs57 lh-150 fw-semibold mb-3" @click="activeUl = !activeUl" :aria-pressed="activeUl ? 'true' : 'false'">All <span class="theme-color">Brands</span></h2>
                        <ul class="v-tabs list-unstyled" :class="{ showUL: activeUl }">
                            <li class="link-item d-flex align-items-center justify-content-between fs18 fw-semibold active">ALL</li>
                            <li class="link-item d-flex align-items-center justify-content-between fs18 fw-semibold">Fxview <span class="fs16 fw-normal lh-190">14</span></li>
                            <li class="link-item d-flex align-items-center justify-content-between fs18 fw-semibold">ZuluTrade <span class="fs16 fw-normal lh-190">30</span></li>
                            <li class="link-item d-flex align-items-center justify-content-between fs18 fw-semibold">AAAFx <span class="fs16 fw-normal lh-190">50</span></li>
                            <li class="link-item d-flex align-items-center justify-content-between fs18 fw-semibold">CapitalWallet <span class="fs16 fw-normal lh-190">20</span></li>
                            <li class="link-item d-flex align-items-center justify-content-between fs18 fw-semibold">ActTrader <span class="fs16 fw-normal lh-190">20</span></li>
                            <li class="link-item d-flex align-items-center justify-content-between fs18 fw-semibold">GiniHealth <span class="fs16 fw-normal lh-190">10</span></li>
                            <li class="link-item d-flex align-items-center justify-content-between fs18 fw-semibold">Portfolios <span class="fs16 fw-normal lh-190">46</span></li>
                            <li class="link-item d-flex align-items-center justify-content-between fs18 fw-semibold">Shoonya <span class="fs16 fw-normal lh-190">35</span></li>
                            <li class="link-item d-flex align-items-center justify-content-between fs18 fw-semibold">Stackflow <span class="fs16 fw-normal lh-190">100</span></li>
                            <li class="link-item d-flex align-items-center justify-content-between fs18 fw-semibold">Bodyloop <span class="fs16 fw-normal lh-190">55</span></li>
                            <li class="link-item d-flex align-items-center justify-content-between fs18 fw-semibold">Finvasia Estates <span class="fs16 fw-normal lh-190">68</span></li>
                        </ul>
                    </div>
                </div> -->

                <!-- tabs Content -->
                <div class="col-lg-9">
                    <div class="right-sec pt-5">
                       <div class="img-wrap d-flex align-items-center justify-content-center">
                            <img class="img-fluid" src="assets/images/news4.png">
                       </div>
                       <div class="date-sec d-flex align-items-center">
                            <p class="d-flex align-items-center mb-0"><svg class="me-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z" stroke="#CA705F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16 2V6" stroke="#CA705F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M8 2V6" stroke="#CA705F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M3 10H21" stroke="#CA705F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                November 2, 2021  
                            </p>
                            <h6 class="brandname theme-color fs16 fw-bold lh-150 mb-0">FXView</h6>
                       </div>

                       <div class="content-box mt-0 mt-lg-5 pt-2">
                        <h5 class="fs20 fw-bold lh-150 mb-2 mb-lg-4 pb-2"><svg class="me-3" width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.43311 5.24902H12.4331C14.0244 5.24902 15.5505 5.88116 16.6757 7.00638C17.801 8.1316 18.4331 9.65772 18.4331 11.249V32.249C18.4331 31.0555 17.959 29.911 17.1151 29.067C16.2712 28.2231 15.1266 27.749 13.9331 27.749H3.43311V5.24902Z" stroke="#CA705F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M33.4331 5.24902H24.4331C22.8418 5.24902 21.3157 5.88116 20.1905 7.00638C19.0652 8.1316 18.4331 9.65772 18.4331 11.249V32.249C18.4331 31.0555 18.9072 29.911 19.7511 29.067C20.595 28.2231 21.7396 27.749 22.9331 27.749H33.4331V5.24902Z" stroke="#CA705F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            FXView crowned as the best value broker at the forex expo dubai 2021 
                        </h5>
                        <p class="lh-190 mb-2 mb-lg-4 pb-3">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words</p>
                        <p class="fw-semibold lh-190 mb-3 pb-1">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>

                        <p class="arrowP mb-lg-4 mb-2 lh-190 pb-2">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words</p>
                        <p class="arrowP lh-190 mb-3 mb-lg-5">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words</p>
                        <p class="lh-190 pb-4 mb-2">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words</p>
                        <router-link class="outlinebtn btn fs16 rounded-pill h51 mt-5 px-5" to="/news">Back</router-link>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template> 
<script>
import 'vue3-carousel/dist/carousel.css';

    export default {
        name: 'News-Details',
        data() {
            return {
                    newsTab:'News', 
                 activeUl: false
             }
        },
        components: { 
            
            
        },
    }  
</script>
 