<template>
    <div class="privacy-page">
        <div class="container cstm">
            <div class="page-heading text-center pb-5 mb-3 pt-4 mt-3">
                <h1 class="fs70 lh-150 mb-1 fw-bold">Terms of <span class="theme-color">Use</span></h1>
                <p class="lh-190 fw-medium mb-1">Last updated on January 23, 2023</p>
            </div>

            <p class="lh-190 mb-4 mb-lg-5">Below are the dummy privacy policy. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>

            <div class="policy-box mb-4 mb-lg-5">
                <h2 class="fs50 fw-semibold lh-150 mb-1 mb-lg-3 pb-1">Licensing <span class="theme-color">Policy</span></h2>
                <p class="lh-190 mb-1 mb-lg-4 pb-3">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words</p>
                <p class="fw-semibold lh-190 mb-2 mb-lg-3 pb-1">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>

                <p class="arrowP mb-2 mb-lg-4 lh-190 pb-2">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words</p>
                <p class="arrowP lh-190 mb-3 mb-lg-5">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words</p>
                <p class="lh-190 mb-1 mb-lg-4">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words</p>
            </div>

            <div class="policy-box mb-4 mb-lg-5">
                <h2 class="fs50 fw-semibold lh-150 mb-1 mb-lg-3 pb-1">Additional <span class="theme-color">Policy</span></h2>
                <p class="lh-190 mb-1 mb-lg-4 pb-3">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words</p>
                <p class="fw-semibold lh-190 mb-2 mb-lg-3 pb-1">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>

                <p class="arrowP mb-2 mb-lg-4 lh-190 pb-2">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words</p>
                <p class="arrowP lh-190 mb-3 mb-lg-5">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words</p>
                <p class="lh-190 mb-1 mb-lg-4">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words</p>
            </div>
            <p class="lh-190 mb-3 pb-1">Below are the dummy privacy policy. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
        </div>
    </div>
</template> 
<script>
    export default {
        name: 'Terms-and-conditions',
        data() {
            return {

                }
            },
        }  
</script>
 